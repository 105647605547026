import { render, staticRenderFns } from "./ChipsBar.vue?vue&type=template&id=2fbf02d9"
import script from "./ChipsBar.vue?vue&type=script&setup=true&lang=ts"
export * from "./ChipsBar.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ChipsBar.vue?vue&type=style&index=0&id=2fbf02d9&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBadge: require('/builds/private/medtochka-frontend/core/components/Base/BaseBadge.vue').default})
