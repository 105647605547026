import { FileType } from '~/core/enums'
import { getImageByFile } from './getImageByFile'

const DEFAULT_MAX_IMAGE_SIZE = 3000
const DEFAULT_CANVAS_TO_BLOB_QUALITY = 0.9

type CompressImageOptions = {
  /**
   * Максимальная ширина и высота
   * */
  maxSize?: number
  /**
   * Качество после сжатия от 0 до 1
   * */
  quality?: number
}

type CompressImage = (file: File, options?: CompressImageOptions) => Promise<File>

/**
 * Позволяет сжимать изображения, переводя их в формат JPEG.
 * */
export const compressImage: CompressImage = async (file, {
  maxSize = DEFAULT_MAX_IMAGE_SIZE,
  quality = DEFAULT_CANVAS_TO_BLOB_QUALITY,
} = {}) => {
  const canvas = document.createElement('canvas')

  const ctx = canvas.getContext('2d')!

  const img = await getImageByFile(file)

  if (img.naturalWidth > maxSize && img.naturalWidth >= img.naturalHeight) {
    const diff = img.naturalHeight / img.naturalWidth
    canvas.width = maxSize
    canvas.height = maxSize * diff
  } else if (img.naturalHeight > maxSize && img.naturalHeight >= img.naturalWidth) {
    const diff = img.naturalWidth / img.naturalHeight
    canvas.height = maxSize
    canvas.width = maxSize * diff
  } else {
    canvas.width = img.naturalWidth
    canvas.height = img.naturalHeight
  }

  ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

  return new Promise<File>((resolve) => {
    canvas?.toBlob((blob) => {
      if (!blob) {
        resolve(file)

        return
      }

      resolve(new File([blob], file.name, { type: FileType.Jpeg }))
    }, FileType.Jpeg, quality)
  })
}
