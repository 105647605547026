export const DOCUMENTS_MUTATIONS = {
  SET_MEDCARD_DOCUMENTS_COUNT: 'medcard/documents/SET_MEDCARD_DOCUMENTS_COUNT',
  SET_MEDCARD_DOCUMENTS: 'medcard/documents/SET_MEDCARD_DOCUMENTS',
  SET_FOLDERS: 'medcard/documents/SET_FOLDERS',
  DELETE_DOCUMENTS_GROUP: 'medcard/documents/DELETE_DOCUMENTS_GROUP',
  SET_TOTAL_DOCUMENTS_COUNT: 'medcard/documents/SET_TOTAL_DOCUMENTS_COUNT',
  SET_EXTERNAL_DOCUMENT_GROUP: 'medcard/documents/SET_EXTERNAL_DOCUMENT_GROUP',
  SET_UNCONFIRMED_DOCUMENTS: 'medcard/documents/SET_UNCONFIRMED_DOCUMENTS',
  READ_DOCUMENT_GROUP: 'medcard/documents/READ_DOCUMENT_GROUP',
}

export const DOCUMENTS_GETTERS = {
  GET_MEDCARD_DOCUMETS_COUNT: 'medcard/documents/GET_MEDCARD_DOCUMETS_COUNT',
  GET_MEDCARD_FLAT_DOCUMENTS: 'medcard/documents/GET_MEDCARD_FLAT_DOCUMENTS',
  GET_MEDCARD_DOCUMENT_GROUPS: 'medcard/documents/GET_MEDCARD_DOCUMENT_GROUPS',
}

export const DOCUMENTS_ACTIONS = {
  FETCH_MEDCARD_STATE: 'medcard/documents/FETCH_MEDCARD_STATE',
  FETCH_MEDCARD_DOCUMENTS_COUNT: 'medcard/documents/FETCH_MEDCARD_DOCUMENTS_COUNT',
  FETCH_MEDCARD_GROUPS: 'medcard/documents/FETCH_MEDCARD_GROUPS',
  FETCH_MEDCARD_FOLDERS: 'medcard/documents/FETCH_MEDCARD_FOLDERS',
  FETCH_UNCONFIRMED_DOCUMENTS: 'medcard/documents/FETCH_UNCONFIRMED_DOCUMENTS',
}
