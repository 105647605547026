export const APPOINTMENT_MUTATIONS = {
  SET_APPOINTMENT: 'appointment/SET_APPOINTMENT',
  SET_PATIENT_FULL_NAME: 'appointment/SET_PATIENT_FULL_NAME',
  SET_PATIENT_BIRTHDATE: 'appointment/SET_PATIENT_BIRTHDATE',
  SET_DOCUMENT_GROUP_ID: 'appointment/SET_DOCUMENT_GROUP_ID',
  CHANGE_FAVOURITE_STATUS: 'appointment/CHANGE_FAVOURITE_STATUS',
  SET_STATUS: 'appointment/SET_STATUS',
  SET_CURRENT_STATUS: 'appointment/SET_CURRENT_STATUS',
  SET_CANCEL_STATUS: 'appointment/SET_CANCEL_STATUS',
  SET_CLAIM_STATUS: 'appointment/SET_CLAIM_STATUS',
  SET_APPOINTMENT_ATTACHMENT: 'appointment/SET_APPOINTMENT_ATTACHMENT',
}

export const APPOINTMENT_GETTERS = {
  PATIENT_FULL_NAME: 'appointment/PATIENT_FULL_NAME',
}

export const APPOINTMENT_ACTIONS = {
  FETCH_FULL_APPOINTMENT: 'appointment/FETCH_FULL_APPOINTMENT',
  FETCH_APPOINTMENT: 'appointment/FETCH_APPOINTMENT',
  FETCH_APPOINTMENT_PROFILE_DATA: 'appointment/FETCH_APPOINTMENT_PROFILE_DATA',
}

