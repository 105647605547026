
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue'
import BottomControlsButtons from '~/core/components/BottomControlsButtons/BottomControlsButtons.vue'
import ResultsPlaceholder from '~/core/components/ResultsPlaceholer/ResultsPlaceholder.vue'
import { useToggleable } from '~/core/composables'
import { defineComponent, toRefs } from '~/bridge'

export default defineComponent({
  name: 'FirstMedcardAdditionPopup',
  components: {
    ResultsPlaceholder,
    BaseFullscreenDialog,
    BottomControlsButtons,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  emits: ['input', 'more', 'share'],
  setup(props, { emit, expose }) {
    const refProps = toRefs(props)

    const { isActive, show, hide } = useToggleable(refProps.value, emit, 'input')

    function emitMore() {
      hide()
      emit('more')
    }

    function emitShare() {
      hide()
      emit('share')
    }

    expose({
      show,
      hide,
    })

    return {
      isActive,
      emitMore,
      emitShare,
    }
  },
})
