import { FullName } from '~/core/types'
import { masterClient } from '~/core/utils/clients/masterClient'
import { infoClient } from '~/core/utils/clients/infoClient'
import { profileClient } from '~/core/utils/clients/profileClient'
import { Gender } from '~/features/Profile/enums'

const RESOURCE = '/profile/family_profiles/'

export type FamilyListFromMasterDto = {
  uuid: string
  dtBirth: DateISO
  isMain: true
  avatarType: string
  ageGroup: string
}

export type FamilyListFromInfoDto = {
  masterFamilyProfileUuid: string
  dtMedformsUpdatedUtc: string | null
  femaleCalendarId: number | null
  hasDentalTreatmentPlan: boolean
}

export type FamilyListFromProfileDto = FullName & {
  masterFamilyProfileUuid: string
  gender: Gender
}

export type FamilyProfileInfoDto = FullName & {
  dtBirth: DateISO
  gender: Gender
  isMain?: true
}

export const familyApi = {
  getFamilyListFromMaster() {
    return masterClient.get<FamilyListFromMasterDto[]>(RESOURCE, { camelize: true })
  },
  getFamilyListFromInfo() {
    return infoClient.get<FamilyListFromInfoDto[]>(RESOURCE, { camelize: true })
  },
  getFamilyListFromProfile() {
    return profileClient.get<FamilyListFromProfileDto[]>(RESOURCE, { camelize: true })
  },
  createFamilyProfile(profile: FamilyProfileInfoDto) {
    return masterClient.post<{ uuid: string }>(`${RESOURCE}create/`, profile, {
      camelize: true,
    })
  },
  updateFamilyProfile(uuid: string, profile: FamilyProfileInfoDto) {
    return masterClient.patch(`${RESOURCE}update/`, profile, {
      camelize: true,
      headers: {
        'Family-Profile-Uuid': uuid,
      },
    })
  },
  deleteFamilyProfile(uuid: string) {
    return masterClient.post(`${RESOURCE}delete/`, {}, {
      camelize: true,
      headers: {
        'Family-Profile-Uuid': uuid,
      },
    })
  },
  mergeFamilyProfile(uuidSaveProfile: string, uuidDeleteProfile: string) {
    return masterClient.patch(`${RESOURCE}${uuidSaveProfile}/merge/${uuidDeleteProfile}/`)
  },
}
