import axiosRetry from 'axios-retry'
import { HttpStatusCode } from '~/core/enums'
import TokenManager from '~/core/utils/tokenManagers/tokenManager'
import { retryCondition, retryDelay } from '~/core/functions/axiosRetry'
import { createDefaultClient } from './createDefaultClient'

export const createClientWithTokenAuth = (options: { baseURL: string, tokenManager: TokenManager }) => {
  const instance = createDefaultClient(options.baseURL)
  const tokenManager = options.tokenManager

  instance.interceptors.request.use(async (config) => {
    try {
      config.headers!.Authorization = `Token ${await tokenManager.getActualToken()}`
    } catch (e) {
      return config
    }

    return config
  })

  axiosRetry(instance, {
    retries: 3,
    retryDelay,
    retryCondition: (error) => {
      if (error.response?.status === HttpStatusCode.Unauthorized) {
        tokenManager.refreshToken()

        return true
      }

      return retryCondition(error)
    },
  })

  return instance
}
