import { RatesHits, RatesHitsItems } from '~/features/Rates/types'
import { RatesHitsDto, RatesHitsItemDto } from '~/features/Rates/api/hits.api'

type ComposeHitsPayload = RatesHitsDto

type ComposeHitsReturn = RatesHits

type ComposeHits = (payload: ComposeHitsPayload) => ComposeHitsReturn

export const composeHits: ComposeHits = ({ total, doctorRatesCount, lpuRatesCount }) => {
  const reduceCallback = (res: RatesHitsItems, item: RatesHitsItemDto) => ({
    ...res,
    [item.id]: item.hits
      ? {
          hits: item.hits,
          newHits: item.newHits,
        }
      : null,
  })

  return {
    total: {
      allHits: total.allHits || null,
      doctorHits: total.doctorHits || null,
      lpuHits: total.lpuHits || null,
      newAllHits: total.newAllHits || null,
      newDoctorHits: total.newDoctorHits || null,
      newLpuHits: total.newLpuHits || null,
    },
    lpuHits: lpuRatesCount.reduce(reduceCallback, { }),
    doctorHits: doctorRatesCount.reduce(reduceCallback, { }),
  }
}
