
import { promiseTimeout } from '@vueuse/core'
import DesktopLayout from '~/core/layouts/Desktop.vue'
import MobileLayout from '~/core/layouts/Mobile.vue'
import { useAppLink, useSmartAppBannerSetup } from '~/core/composables'
import ResultsPlaceholder from '~/core/components/ResultsPlaceholer/ResultsPlaceholder.vue'
import BottomControlsButtons from '~/core/components/BottomControlsButtons/BottomControlsButtons.vue'
import { isDeeplinkOnlyPage } from '~/core/functions'
import EnvironmentBanner from '~/core/components/EnvironmentBanner/EnvironmentBanner.vue'
import { computed, defineComponent, onBeforeMount, onBeforeUnmount, useNuxtApp, useRoute, watch } from '~/bridge'

export default defineComponent({
  name: 'Default',
  components: {
    EnvironmentBanner,
    BottomControlsButtons,
    ResultsPlaceholder,
    DesktopLayout,
    MobileLayout,
  },
  setup() {
    useSmartAppBannerSetup()
    const { $device, $vuetify, $store, $sentry } = useNuxtApp()
    const $route = useRoute()
    const { appLink, deepLink } = useAppLink($device, $route)

    const isDeeplinkOnly = computed(() => isDeeplinkOnlyPage($route, $device, $sentry))
    const deeplinkOnlyPlaceholderTitle = computed(() => {
      if ($route.name?.startsWith('appointments')) {
        return 'Откройте приложение МедТочка, чтобы посмотреть детали записи'
      } else if ($route.name?.startsWith('achievements')) {
        return 'Откройте приложение МедТочка, чтобы посмотреть награды'
      }

      return 'Откройте приложение МедТочка, чтобы посмотреть документ'
    })

    const isDesktopLayoutActive = computed(() => !$device.isMobile && $route.fullPath.includes('authorization'))
    const overlaysCount = computed(() => $store.state.overlaysCount)

    watch(overlaysCount, (val) => {
      document.documentElement.style.overflow = val > 0 ? 'hidden' : ''
      document.body.style.overflow = val > 0 ? 'hidden' : ''

      if ($device.isDesktop) {
        document.documentElement.style.paddingRight = val > 0 ? `${$vuetify.breakpoint.scrollBarWidth}px` : '0'
      }
    })

    onBeforeMount(async () => {
      if ($device.isMobile) {
        document.documentElement.classList.add('is-mobile')
      }

      setRealVH()
      window.addEventListener('resize', setRealVH)

      if (isDeeplinkOnly.value) {
        if (!$device.isIos) {
          location.href = deepLink.value
          await promiseTimeout(1000)
        } else {
          document.addEventListener('visibilitychange', function() {
            if (window.history.length > 1) {
              window.history.go(-1) // go back to previous page
            } else {
              window.close()
            }
          })
        }

        // https://stackoverflow.com/questions/65308050/how-to-detect-if-xiaomis-mi-browser-using-js
        if ($device.userAgent.includes(' Mi ') || $device.userAgent.includes('MiuiBrowser')) {
          return
        }

        location.href = appLink.value
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setRealVH)
    })

    function setRealVH() {
      // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
    }

    return {
      appLink,
      isDesktopLayoutActive,
      isDeeplinkOnly,
      deeplinkOnlyPlaceholderTitle,
    }
  },
})
