var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"container",staticClass:"ui-kit-bg-gray-0 mb-4 elevation-2",style:({
    width: `${_vm.width}px`,
    height: `${_vm.height}px`,
  })},[(_vm.isIntersecting)?_c('canvas',{ref:"canvas",style:({
      width: `${_vm.width}px`,
      height: `${_vm.height}px`,
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }