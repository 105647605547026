var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"dragscroll",rawName:"v-dragscroll",value:(_vm.$device.isDesktop),expression:"$device.isDesktop"}],ref:"container",staticClass:"pdf-viewer",class:{
    'pdf-viewer_desktop-scrollbar': _vm.$device.isDesktop && !_vm.$device.isFirefox,
    'overflow-y-scroll overflow-x-hidden': _vm.$device.isDesktop,
    'overflow-y-auto': !_vm.$device.isDesktop,
  }},[(_vm.pdf)?_c('div',{ref:"viewer"},[_c('div',{staticClass:"mx-auto",style:({
        width: `${_vm.innerWidth}px`,
      })},_vm._l((_vm.pages),function(page){return _c('PDFViewPage',{key:page,attrs:{"pdf":_vm.pdf,"page":page,"zoom-scale":_vm.scale,"width":_vm.innerWidth,"height":_vm.innerHeight}})}),1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }