import { infoClient } from '~/core/utils/clients/infoClient'
import { FullName, Schedule } from '~/core/types'

const RESOURCE = '/appointments'

export type RepeatAppointmentSpeciality = {
  speciality_id: number,
  speciality_name: string
  price: number
  min_age: number | null
  max_age: number | null
}

type RepeatScheduleDto = {
  specialities: RepeatAppointmentSpeciality[]
  schedule: Schedule
}

type RepeatPayload = Omit<FullName, 'patronymic'> & {
  id: number
  patronymic?: string
  dtStart: DateTimeISO
  duration: number
  birthday: DateISO
  price: number
  speciality_id: number
}

export type RepeatAppointmentDto = {
  id: number
  title: string
  avatarPath: string
  specialityName?: string
  familyProfileUuid?: string
  reappointmentIsAvailable?: boolean
  doctorUrlProdoctorov?: string
}

export default {
  get(id: number): Promise<{ data: RepeatScheduleDto }> {
    return infoClient.get(`${RESOURCE}/v2/${id}/repeat/schedule/`, { camelize: false })
  },
  repeat({ id, ...payload }: RepeatPayload): Promise<{ data: { id: number }}> {
    return infoClient.post(`${RESOURCE}/v2/${id}/repeat/`, payload, { camelize: true })
  },
  repeatAvailable(): Promise<{ data: RepeatAppointmentDto[] }> {
    return infoClient.get(`${RESOURCE}/v2/repeat_available/`, { camelize: true })
  },
  repeatSkip(id: number) {
    return infoClient.post(`${RESOURCE}/v2/${id}/repeat/skip/`, { camelize: true })
  },
}
