import axiosRetry from 'axios-retry'
import { createDefaultClient } from '~/core/functions/client/createDefaultClient'
import { retryDelay, retryCondition } from '~/core/functions'

const client = createDefaultClient()

axiosRetry(client, {
  retries: 3,
  retryCondition,
  retryDelay,
})

export { client as masterClient }
