
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'ListItem',
  props: {
    title: { type: String, default: '' },
    itemTitle: { type: String, default: '' },
    itemSubTitle: { type: String, default: '' },
  },
})
