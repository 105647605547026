import townApi from '~/core/components/TownSelect/api'

type CreateConfirmTownPopupPayload = {
  town?: string
  townKey?: string
  selectTownHandler: () => void
  redirectHandler: (v?: string) => void
}

type CreateConfirmTownPopupReturn = {
  title: string
  confirmText: string
  closeText: string
  confirmHandler: () => void
  persistent: boolean
  closeHandler?: () => void
}

type CreateConfirmTownPopup = (payload: CreateConfirmTownPopupPayload) => CreateConfirmTownPopupReturn

export const createConfirmTownPopup: CreateConfirmTownPopup = ({ town, selectTownHandler, redirectHandler }) => {
  if (town) {
    return {
      closeText: 'изменить',
      title: `Ваш город ${town}?`,
      confirmText: 'да, всё верно',
      confirmHandler: async () => {
        try {
          await townApi.patch({ townConfirmed: true })
        } catch {}

        await redirectHandler()
      },
      closeHandler: selectTownHandler,
      persistent: true,
    }
  }

  return {
    title: 'Выберите ваш город',
    confirmText: 'выбрать',
    closeText: 'позже',
    confirmHandler: selectTownHandler,
    persistent: true,
  }
}
