import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/profile'

export type TownSelectItemDto = {
  id: number
  key: string
  name: string
}

export default {
  get(search: string | undefined): Promise<{ data: TownSelectItemDto[] }> {
    return masterClient.get(`${RESOURCE}/towns/`, {
      params: {
        search,
      },
      camelize: true,
    })
  },
  patch(payload: { town?: number, townConfirmed: boolean }): Promise<{ data: TownSelectItemDto[] }> {
    return masterClient.patch(`${RESOURCE}/`, {
      ...payload,
    }, { camelize: true })
  },
}
