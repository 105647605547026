import Cookies from 'js-cookie'
import logoutApi from '~/core/components/AppMenu/api'
import { CookieKey } from '~/core/enums'
import { PROFILE_MUTATIONS, QUESTIONNAIRE_ACTIONS } from '~/core/constants'
import { useNuxtApp, useRouter } from '~/bridge'
import {
  PREPARE_MEDCARD_STORAGE_KEY,
  UNSENT_DOCTOR_QUESTIONS_KEY,
} from '~/features/Appointments/constants/localStorage'

export const useLogout = () => {
  const { $store } = useNuxtApp()
  const router = useRouter()

  async function logout() {
    await logoutApi.post()
    Cookies.remove(CookieKey.MedcardToken)
    Cookies.remove(CookieKey.AnonymousQuestionnaireUid)
    localStorage.removeItem(PREPARE_MEDCARD_STORAGE_KEY)
    localStorage.removeItem(UNSENT_DOCTOR_QUESTIONS_KEY)

    $store.dispatch(QUESTIONNAIRE_ACTIONS.CLEAR_QUESTIONNAIRE)
    $store.commit(PROFILE_MUTATIONS.SET_PHONE, '')

    router.push({ name: 'authorization' })
  }

  return {
    logout,
  }
}
