
import { LANDING_URL, PROFILE_ACTIONS } from '~/core/constants'
import { computed, defineComponent, ref, useNuxtApp } from '~/bridge'
import { AgreementStatus } from '~/features/Profile/enums'

export default defineComponent({
  name: 'RulesAgree',
  setup() {
    const isRulesAgreementCheckboxChecked = ref(true)

    const { $store, $captureException } = useNuxtApp()

    const agreementStatus = computed(() => $store.state.profile.agreementStatus)
    const preventShowingAgreement = computed(() => $store.state.profile.preventShowingAgreement)
    const afterAgreementCallback = computed(() => $store.state.profile.afterAgreementCallback)
    const isLayoutError = computed(() => $store.state.isLayoutError)
    const isReAgreement = computed(() => agreementStatus.value === AgreementStatus.ReAgreement)
    const shouldShowRulesAgreement = computed(() =>
      !preventShowingAgreement.value &&
      !isLayoutError.value &&
      [AgreementStatus.Never, AgreementStatus.ReAgreement].includes(agreementStatus.value),
    )

    async function handleAgreeClick() {
      try {
        if (afterAgreementCallback.value) {
          afterAgreementCallback.value()
        }

        await $store.dispatch(PROFILE_ACTIONS.RULES_AGREE)
      } catch (e) {
        $captureException(e, {
          component: 'RulesAgree',
          method: 'handleAgreeClick',
        })
      }
    }

    return {
      LANDING_URL,
      isRulesAgreementCheckboxChecked,
      isReAgreement,
      shouldShowRulesAgreement,
      handleAgreeClick,
    }
  },
})
