import type { ActionTree, MutationTree } from 'vuex'
import { isNull } from 'lodash-es'
import { removeNamespace } from '~/core/functions'
import { DISCUSS_ACTIONS, DISCUSS_GETTERS, DISCUSS_MUTATIONS } from '~/core/constants'
import { uuidv4 } from '~/core/utils/uuidv4'
import { RateDiscussionDto, RateReviewAttachmentDto } from '~/features/Rates/api/chat.api.types'
import { AttachmentStatus } from '~/features/Rates/api/chat.api.enums'
import { SNACKBAR_MESSAGE as RATES_SNACKBAR } from '~/features/Rates/constants/snackbar'
import { DiscussionApi } from '~/features/Rates/api'
import { CantModifyReasonType } from '~/features/Rates/enums'

interface IState {
  isSupplement: boolean
  discussion: RateDiscussionDto
}

const initialState = (): IState => ({
  isSupplement: false,
  discussion: {
    id: null as unknown as number,
    patientText: '',
    supportText: '',
    attachments: [] as RateReviewAttachmentDto[],
    remindLaterAvailable: true,
  },
})

export const state = initialState
export type TRootState = ReturnType<typeof state>

const GETTERS_TYPES = removeNamespace('rate/discuss/', DISCUSS_GETTERS)

const MUTATIONS_TYPES = removeNamespace('rate/discuss/', DISCUSS_MUTATIONS)

const ACTIONS_TYPES = removeNamespace('rate/discuss/', DISCUSS_ACTIONS)

export const getters: MutationTree<TRootState> = {
  [GETTERS_TYPES.IS_EACH_ATTACHMENT_FULFILLED]: ({ discussion }): boolean => {
    return !discussion.attachments.some(({ status }) => status === AttachmentStatus.Uploading)
  },
  [GETTERS_TYPES.IS_EACH_ATTACHMENT_SUCCESSFUL]: ({ discussion }): boolean => {
    return !discussion.attachments.some(({ status }) => status === AttachmentStatus.Failed)
  },
  [GETTERS_TYPES.GET_ATTACHMENT_BY_KEY]: ({ discussion }) => (key: string): RateReviewAttachmentDto | undefined => {
    return discussion.attachments.find(attachment => attachment.key === key)
  },
}

export const mutations: MutationTree<TRootState> = {
  [MUTATIONS_TYPES.SET_IS_SUPPLEMENT](state, payload: boolean) {
    state.isSupplement = payload
  },
  [MUTATIONS_TYPES.SET_DISCUSSION_ID](state, payload: number) {
    state.discussion = { ...state.discussion, id: payload }
  },
  [MUTATIONS_TYPES.SET_SUPPORT_MESSAGE](state, payload: string) {
    state.discussion = { ...state.discussion, supportText: payload }
  },
  [MUTATIONS_TYPES.SET_USER_MESSAGE](state, payload: string) {
    state.discussion = { ...state.discussion, patientText: payload }
  },
  [MUTATIONS_TYPES.SET_ATTACHMENTS](state, payload: RateReviewAttachmentDto[]) {
    state.discussion = { ...state.discussion, attachments: payload }
  },
  [MUTATIONS_TYPES.SET_REMIND_LATER_AVAILABLE](state, payload: boolean) {
    state.discussion = { ...state.discussion, remindLaterAvailable: payload }
  },
  [MUTATIONS_TYPES.ADD_ATTACHMENT](state, payload: RateReviewAttachmentDto) {
    state.discussion.attachments.push(payload)
  },
  [MUTATIONS_TYPES.CHANGE_ATTACHMENT](state, attachment: RateReviewAttachmentDto) {
    const index = state.discussion.attachments.findIndex(({ key }) => key === attachment.key)

    state.discussion.attachments.splice(index, 1, attachment)
  },
  [MUTATIONS_TYPES.DELETE_ATTACHMENT](state, payload: string) {
    state.discussion.attachments = state.discussion.attachments.filter(({ key }) => payload !== key)
  },
  [MUTATIONS_TYPES.CLEAR](state) {
    Object.assign(state, initialState())
  },
}

export const actions: ActionTree<TRootState, any> = {
  [ACTIONS_TYPES.INIT]({ commit }, payload: IState): void {
    commit(MUTATIONS_TYPES.SET_IS_SUPPLEMENT, payload.isSupplement)
    commit(MUTATIONS_TYPES.SET_DISCUSSION_ID, payload.discussion.id)
    commit(MUTATIONS_TYPES.SET_SUPPORT_MESSAGE, payload.discussion.supportText)
    commit(MUTATIONS_TYPES.SET_USER_MESSAGE, payload.discussion.patientText)
    commit(MUTATIONS_TYPES.SET_ATTACHMENTS, payload.discussion.attachments.map(attachment => ({
      ...attachment,
      file: `${attachment.fileNew}`,
      preview: `${attachment.previewNew}`,
      status: AttachmentStatus.Sent,
      key: uuidv4(),
    })))
    commit(MUTATIONS_TYPES.SET_REMIND_LATER_AVAILABLE, payload.discussion.remindLaterAvailable)
  },
  async [ACTIONS_TYPES.SAVE_MESSAGE]({ commit, state }, message: string) {
    state.isSupplement
      ? await DiscussionApi.saveSupplementMessage(state.discussion.id, message)
      : await DiscussionApi.saveMessage(state.discussion.id, message)

    commit(MUTATIONS_TYPES.SET_USER_MESSAGE, message)
  },
  async [ACTIONS_TYPES.SAVE_ATTACHMENT]({ commit, state }, attachment: RateReviewAttachmentDto) {
    try {
      commit(MUTATIONS_TYPES.ADD_ATTACHMENT, attachment)
      const { data } = state.isSupplement
        ? await DiscussionApi.saveSupplementAttachment(state.discussion.id, attachment)
        : await DiscussionApi.saveAttachment(state.discussion.id, attachment)

      commit(MUTATIONS_TYPES.CHANGE_ATTACHMENT, { ...attachment, status: AttachmentStatus.Sent, id: data.id })
    } catch (error) {
      commit(MUTATIONS_TYPES.CHANGE_ATTACHMENT, { ...attachment, status: AttachmentStatus.Failed })
      throw error
    }
  },
  async [ACTIONS_TYPES.UPDATE_ATTACHMENT]({ commit, state, getters }, key: string) {
    const attachment = getters[GETTERS_TYPES.GET_ATTACHMENT_BY_KEY](key)

    try {
      commit(MUTATIONS_TYPES.CHANGE_ATTACHMENT, { ...attachment, status: AttachmentStatus.Uploading })
      const { data } = state.isSupplement
        ? await DiscussionApi.saveSupplementAttachment(state.discussion.id, attachment)
        : await DiscussionApi.saveAttachment(state.discussion.id, attachment)

      commit(MUTATIONS_TYPES.CHANGE_ATTACHMENT, { ...attachment, status: AttachmentStatus.Sent, id: data.id })
    } catch (error) {
      commit(MUTATIONS_TYPES.CHANGE_ATTACHMENT, { ...attachment, status: AttachmentStatus.Failed })
      throw error
    }
  },
  async [ACTIONS_TYPES.DELETE_ATTACHMENT]({ commit, state, getters, dispatch }, key: string) {
    const { id } = getters[GETTERS_TYPES.GET_ATTACHMENT_BY_KEY](key)

    if (!isNull(id)) {
      state.isSupplement
        ? await DiscussionApi.deleteSupplementAttachment(state.discussion.id, id)
        : await DiscussionApi.deleteAttachment(state.discussion.id, id)
    }

    commit(MUTATIONS_TYPES.DELETE_ATTACHMENT, key)

    if (isNull(id)) {
      dispatch('main-snackbar/handleOpen', { ...RATES_SNACKBAR.FILE_DELETED }, { root: true })
    }
  },
  async [ACTIONS_TYPES.SEND]({ state }, discussionID: number) {
    state.isSupplement
      ? await DiscussionApi.sendSupplementMessage(discussionID)
      : await DiscussionApi.sendMessage(discussionID)
  },
  async [ACTIONS_TYPES.SEND_DISCUSSION_ANSWER]({ state }, answer: CantModifyReasonType) {
    state.isSupplement
      ? await DiscussionApi.sendSupplementDiscussionAnswer(state.discussion.id, answer)
      : await DiscussionApi.sendDiscussionAnswer(state.discussion.id, answer)
  },
}
