import { FileType } from '~/core/enums'

/**
 * Переводит heic и heif файлы в JPEG формат с уменьшением качества
 * */
export const convertHeicToJpeg = async (file: File) => {
  const heic2any = (await import('heic2any')).default

  const jpegBlob = await heic2any({
    blob: file,
    toType: FileType.Jpeg,
    quality: 0.5,
  }) as Blob

  return new File([jpegBlob], file.name, { type: FileType.Jpeg })
}
