import { tryOnBeforeUnmount, tryOnMounted } from '@vueuse/core'
import { Ref, useNuxtApp } from '~/bridge'

type UsePinchZoomPayload = {
  container: Ref<HTMLElement | null>
  content: Ref<HTMLElement | null>
  onPinch: (scale: number) => void
}

type UsePinchZoomReturn = {
  enablePinchZoom: () => void
  disablePinchZoom: () => void
}

type UsePinchZoom = (payload: UsePinchZoomPayload) => UsePinchZoomReturn

export const usePinchZoom: UsePinchZoom = ({ container, content, onPinch }) => {
  const { $device } = useNuxtApp()

  let startX = 0; let startY = 0
  let initialPinchDistance = 0
  let pinchScale = 1
  let originX: number
  let originY: number
  const reset = () => {
    startX = startY = initialPinchDistance = 0
    pinchScale = 1
  }

  const iosTouchMoveListener = (e: TouchEvent) => {
    // @ts-ignore experimental
    if (e.scale !== 1) {
      e.preventDefault()
    }
  }

  const touchStartListener = (e: TouchEvent) => {
    if (e.touches.length > 1) {
      container.value!.style.overflow = 'hidden'
      startX = (e.touches[0].pageX + e.touches[1].pageX) / 2
      startY = (e.touches[0].pageY + e.touches[1].pageY) / 2
      initialPinchDistance = Math.hypot(
        (e.touches[1].pageX - e.touches[0].pageX),
        (e.touches[1].pageY - e.touches[0].pageY),
      )
    } else {
      initialPinchDistance = 0
    }
  }

  const touchMoveListener = (e: TouchEvent) => {
    if (initialPinchDistance <= 0 || e.touches.length < 2 || !content.value) {
      return
    }

    // @ts-ignore experimental
    if (e.scale !== 1) {
      e.preventDefault()
    }

    const pinchDistance = Math.hypot(
      (e.touches[1].pageX - e.touches[0].pageX),
      (e.touches[1].pageY - e.touches[0].pageY),
    )
    originX = startX + container.value!.scrollLeft
    originY = startY + container.value!.scrollTop
    pinchScale = pinchDistance / initialPinchDistance
    content.value.style.transform = `scale(${pinchScale})`
    content.value.style.transformOrigin = `${originX}px ${originY}px`
  }

  const touchEndListener = async () => {
    if (initialPinchDistance <= 0 || !content.value) {
      return
    }

    container.value!.style.overflow = 'scroll'

    content.value.style.transform = 'none'
    content.value.style.transformOrigin = 'unset'
    // Compute the current center point in page coordinates
    const pageCenterX = container.value!.clientWidth / 2 + container.value!.scrollLeft
    const pageCenterY = container.value!.clientHeight / 2 + container.value!.scrollTop

    // Compute the next center point in page coordinates
    const centerX = (pageCenterX - originX) / pinchScale + originX
    const centerY = (pageCenterY - originY) / pinchScale + originY

    // Compute the ratios of the center point to the total scrollWidth/scrollHeight
    const px = centerX / container.value!.scrollWidth
    const py = centerY / container.value!.scrollHeight

    await onPinch(pinchScale)
    container.value!.scrollLeft = container.value!.scrollWidth * px - container.value!.clientWidth / 2
    container.value!.scrollTop = container.value!.scrollHeight * py - container.value!.clientHeight / 2

    reset()
  }

  function isTouchDevice() {
    const win = window as any

    return (
      !!(typeof win !== 'undefined' &&
        ('ontouchstart' in win ||
          (win.DocumentTouch &&
            typeof win?.document !== 'undefined' &&
            win?.document instanceof win.DocumentTouch))) ||
      !!(typeof win?.navigator !== 'undefined' &&
        (win?.navigator.maxTouchPoints || win?.navigator.msMaxTouchPoints))
    )
  }

  function enablePinchZoom() {
    if (!isTouchDevice()) {
      return
    }

    originX = startX + container.value!.scrollLeft
    originY = startY + container.value!.scrollTop

    if ($device.isIos) {
      container.value?.addEventListener('touchmove', iosTouchMoveListener, { passive: false })
    }

    container.value?.addEventListener('touchstart', touchStartListener, { passive: true })
    container.value?.addEventListener('touchmove', touchMoveListener, { passive: false })
    container.value?.addEventListener('touchend', touchEndListener)
  }

  function disablePinchZoom() {
    if ($device.isIos) {
      container.value?.removeEventListener('touchmove', iosTouchMoveListener)
    }

    container.value?.removeEventListener('touchstart', touchStartListener)
    container.value?.removeEventListener('touchmove', touchMoveListener)
    container.value?.removeEventListener('touchend', touchEndListener)
  }

  tryOnMounted(() => {
    enablePinchZoom()
  })

  tryOnBeforeUnmount(() => {
    disablePinchZoom()
  })

  return {
    enablePinchZoom,
    disablePinchZoom,
  }
}
