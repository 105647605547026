export enum AppointmentStatus {
  Upcoming = 'upcoming',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Absence = 'absence',
  Default = 'default'
}

export enum AppointmentCancelStatus {
  Allowed = 'allowed',
  TooLate = 'too_late',
  Disallowed = 'disallowed'
}

export enum AppointmentTransferStatus {
  Allowed = 'allowed',
  TooLate = 'too_late',
  Disallowed = 'disallowed'
}

export enum AppointmentType {
  Service = 'service',
  Doctor = 'doctor',
  Telemed = 'telemed'
}

export enum TelemedState {
  PaymentRequired = 'payment_required',
  GeneratingMedcardSession = 'generating_medcard_session',
  GeneratingConsultationChat = 'generating_consultation_chat',
  Ready = 'ready',
  Finished = 'finished',
  Cancelled = 'cancelled'
}

export enum PaymentStatus {
  Unpaid = 'unpaid',
  Paid = 'paid',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  Failed = 'payment_failed'
}

export enum AppointmentListStatus {
  Archived = 'archived',
  Upcoming = 'upcoming'
}

export enum AppointmentTypeSchedule {
  Mt = 'mt',
  Lpu = 'lpu'
}
