import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import { AxiosError } from 'axios'
import { floor } from 'lodash-es'
import Cookies from 'js-cookie'
import {
  FirstMenstruationAnswerDto,
  PickQuestion,
  PregnancyNumberAnswerDto,
  PregnancyWithBirthAnswerDto,
  ProfileAnswerDto,
  QuestionId,
  QuestionnaireAnswerDto,
  QuestionnaireQuestion,
  SexualPartnerAnswerDto,
} from '~/core/api/questionnaire.api.types'
import { getDecryptedQuestionnaire } from '~/core/components/Questionnaire/functions'
import { QuestionnaireApi } from '~/core/api'
import { CookieKey, HttpStatusCode } from '~/core/enums'
import {
  DentalAndSurgicalCommonQuestionId,
  DentalQuestionId,
  GynecologicalQuestionId,
  QuestionGroup,
  SexualPartnerValue,
  SurgicalQuestionId,
  TherapeuticQuestionId,
} from '~/core/components/Questionnaire/enums'
import { composeFullName, removeNamespace } from '~/core/functions'
import { QUESTIONNAIRE_ACTIONS, QUESTIONNAIRE_GETTERS, QUESTIONNAIRE_MUTATIONS } from '~/core/constants'
import { generateQuestions } from '~/core/components/Questionnaire/functions/generateQuestions'
import {
  DENTAL_QUESTION_ORDERED_QUESTIONS_IDS,
  GYNECOLOGICAL_ORDERED_QUESTIONS_IDS,
  SURGICAL_ORDERED_QUESTIONS_IDS,
  THERAPEUTIC_ORDERED_QUESTIONS_IDS,
} from '~/core/components/Questionnaire/constants'
import { Gender } from '~/features/Profile/enums'

interface IState {
  isLoading: boolean,
  questions: QuestionnaireQuestion[],
  appointmentsQuestionGroups: QuestionGroup[],
  isChangesApplied: boolean,
  isFillingState: boolean
}

const MUTATIONS_TYPES = removeNamespace('medcard/questionnaire/', QUESTIONNAIRE_MUTATIONS)

const ACTIONS_TYPES = removeNamespace('medcard/questionnaire/', QUESTIONNAIRE_ACTIONS)

const GETTERS_TYPES = removeNamespace('medcard/questionnaire/', QUESTIONNAIRE_GETTERS)

const initialState = (): IState => ({
  isLoading: false,
  questions: generateQuestions(),
  appointmentsQuestionGroups: [],
  isChangesApplied: false,
  isFillingState: false,
})

export const state = initialState
export type ModuleState = ReturnType<typeof state>

export const getters: GetterTree<ModuleState, any> = {
  [GETTERS_TYPES.FULL_NAME](state) {
    const profileQuestion = state.questions.find(({ questionId }) => questionId === TherapeuticQuestionId.Profile)

    const answer = profileQuestion?.answer

    if (!answer || !('surname' in answer)) {
      return ''
    }

    return composeFullName(answer)
  },
  [GETTERS_TYPES.IS_EMPTY](state) {
    return !state.questions.some(({ answer }) => Boolean(answer))
  },
  [GETTERS_TYPES.GET_QUESTION_BY_ID]: state => (id: QuestionId) => {
    return state.questions.find(({ questionId }) => questionId === id) ?? null
  },
  [GETTERS_TYPES.COMPOSED_QUESTIONS](state, getters): IState['questions'] {
    const profileAnswer = state.questions[0].answer as ProfileAnswerDto['answer']

    const isGroupAvailable = (group: QuestionGroup) => getters[GETTERS_TYPES.AVAILABLE_QUESTION_GROUPS].includes(group)

    let skippedQuestions: QuestionId[] = []

    if (!isGroupAvailable(QuestionGroup.Dentistry) && !isGroupAvailable(QuestionGroup.Surgery)) {
      skippedQuestions = [
        ...skippedQuestions,
        DentalAndSurgicalCommonQuestionId.Implants,
      ]
    }

    if (!isGroupAvailable(QuestionGroup.Therapy)) {
      skippedQuestions = [
        ...skippedQuestions,
        ...Object.values(TherapeuticQuestionId),
      ]
    }

    if (!isGroupAvailable(QuestionGroup.Gynecology)) {
      skippedQuestions = [
        ...skippedQuestions,
        ...Object.values(GynecologicalQuestionId),
      ]
    }

    if (!isGroupAvailable(QuestionGroup.Surgery)) {
      skippedQuestions = [
        ...skippedQuestions,
        ...Object.values(SurgicalQuestionId),
      ]
    }

    if (!isGroupAvailable(QuestionGroup.Dentistry)) {
      skippedQuestions = [
        ...skippedQuestions,
        ...Object.values(DentalQuestionId),
      ]
    }

    if (profileAnswer?.gender === Gender.Male) {
      skippedQuestions = [
        ...skippedQuestions,
        TherapeuticQuestionId.Pregnancy,
        ...(isGroupAvailable(QuestionGroup.Gynecology) ? Object.values(GynecologicalQuestionId) : []),
      ]
    } else if (profileAnswer?.gender === Gender.Female && isGroupAvailable(QuestionGroup.Gynecology)) {
      const firstMenstruation = state.questions.find(
        ({ questionId }) => questionId === GynecologicalQuestionId.FirstMenstruation,
      ) as PickQuestion<FirstMenstruationAnswerDto>

      const pregnancyCount = state.questions.find(
        ({ questionId }) => questionId === GynecologicalQuestionId.PregnanciesNumber,
      ) as PickQuestion<PregnancyNumberAnswerDto>

      const pregnancyBirthCount = state.questions.find(
        ({ questionId }) => questionId === GynecologicalQuestionId.PregnanciesWithBirth,
      ) as PickQuestion<PregnancyWithBirthAnswerDto>

      const sexualContacts = state.questions.find(
        ({ questionId }) => questionId === GynecologicalQuestionId.SexualPartner,
      ) as PickQuestion<SexualPartnerAnswerDto>

      if (firstMenstruation.completed && firstMenstruation.answer?.age === null) {
        skippedQuestions = [
          ...skippedQuestions,
          GynecologicalQuestionId.LastMenstruation,
          GynecologicalQuestionId.PregnanciesNumber,
          GynecologicalQuestionId.PregnanciesWithBirth,
          GynecologicalQuestionId.Childbirth,
        ]
      } else if (pregnancyCount.completed && [0, null].includes(pregnancyCount.answer?.number as 0 | null)) {
        skippedQuestions = [
          ...skippedQuestions,
          GynecologicalQuestionId.PregnanciesWithBirth,
          GynecologicalQuestionId.Childbirth,
        ]
      } else if (pregnancyBirthCount.completed && [0, null].includes(pregnancyBirthCount.answer?.number as 0 | null)) {
        skippedQuestions = [
          ...skippedQuestions,
          GynecologicalQuestionId.Childbirth,
        ]
      }

      if (sexualContacts.completed && sexualContacts.answer?.constantPartner === SexualPartnerValue.NoContacts) {
        skippedQuestions = [
          ...skippedQuestions,
          GynecologicalQuestionId.ContraceptionMethod,
        ]
      }
    }

    return state.questions.filter(({ questionId }) => !skippedQuestions.includes(questionId))
  },
  [GETTERS_TYPES.COMPLETION_PERCENTAGE](_, getters) {
    const questions = getters[GETTERS_TYPES.COMPOSED_QUESTIONS] as IState['questions']

    if (!questions.length) {
      return 0
    }

    return floor(
      (questions.reduce((acc, { completed }) => completed ? ++acc : acc, 0) / questions.length) * 100,
    )
  },
  [GETTERS_TYPES.AVAILABLE_QUESTION_GROUPS](state, getters): QuestionGroup[] {
    const getById: (v: QuestionId) => QuestionnaireQuestion = getters[GETTERS_TYPES.GET_QUESTION_BY_ID]
    const firstTherapeuticQuestion = getById(THERAPEUTIC_ORDERED_QUESTIONS_IDS[0])
    const firstGynecologicalQuestion = getById(GYNECOLOGICAL_ORDERED_QUESTIONS_IDS[0])
    const firstDentalQuestion = getById(DENTAL_QUESTION_ORDERED_QUESTIONS_IDS[0])
    const firstSurgicalQuestion = getById(SURGICAL_ORDERED_QUESTIONS_IDS[0])
    const implantsQuestion = getById(DentalAndSurgicalCommonQuestionId.Implants)
    const res: QuestionGroup[] = []

    const isGroupAvailable = (group: QuestionGroup) => state.appointmentsQuestionGroups.includes(group)

    if (firstTherapeuticQuestion?.completed || isGroupAvailable(QuestionGroup.Therapy)) {
      res.push(QuestionGroup.Therapy)
    }

    if (firstGynecologicalQuestion?.completed || isGroupAvailable(QuestionGroup.Gynecology)) {
      res.push(QuestionGroup.Gynecology)
    }

    if (firstDentalQuestion?.completed || isGroupAvailable(QuestionGroup.Dentistry)) {
      res.push(QuestionGroup.Dentistry)
    }

    if (firstSurgicalQuestion?.completed || isGroupAvailable(QuestionGroup.Surgery)) {
      res.push(QuestionGroup.Surgery)
    }

    if (implantsQuestion?.completed && !res.includes(QuestionGroup.Surgery) &&
      !res.includes(QuestionGroup.Dentistry)) {
      res.push(QuestionGroup.Surgery)
      res.push(QuestionGroup.Dentistry)
    }

    return res
  },
}

export const mutations: MutationTree<ModuleState> = {
  [MUTATIONS_TYPES.SET_IS_FILLING_STATE](state, payload: IState['isFillingState']) {
    state.isFillingState = payload
  },
  [MUTATIONS_TYPES.SET_QUESTION](state, payload: QuestionnaireQuestion) {
    const target = state.questions.find(({ questionId }) => questionId === payload.questionId)

    if (target) {
      target.answer = payload.answer
      target.completed = payload.completed ?? false
    }
  },
  [MUTATIONS_TYPES.SET_QUESTIONNAIRE](state, payload: IState['questions']) {
    state.questions = payload
  },
  [MUTATIONS_TYPES.SET_LOADING](state, payload: IState['isLoading']) {
    state.isLoading = payload
  },
  [MUTATIONS_TYPES.SET_QUESTION_GROUPS](state, payload: IState['appointmentsQuestionGroups']) {
    state.appointmentsQuestionGroups = payload
  },
  [MUTATIONS_TYPES.SET_IS_CHANGES_APPLIED](state, payload: IState['isChangesApplied']) {
    state.isChangesApplied = payload
  },
}

export const actions: ActionTree<ModuleState, any> = {
  async [ACTIONS_TYPES.FETCH_QUESTIONNAIRE]({ commit, state }) {
    try {
      commit(MUTATIONS_TYPES.SET_LOADING, true)
      const { data } = await QuestionnaireApi.getQuestionGroups()
      commit(MUTATIONS_TYPES.SET_QUESTION_GROUPS, data.questionGroups)
      commit(MUTATIONS_TYPES.SET_IS_CHANGES_APPLIED, false)

      const res = await getDecryptedQuestionnaire()
      const formattedRes = state.questions.map((item) => {
        const target = res.find(({ questionId }) => questionId === item.questionId)

        return {
          ...item,
          answer: target?.answer ?? null,
          completed: Boolean(target?.answer),
        }
      }) as IState['questions']

      commit(MUTATIONS_TYPES.SET_QUESTIONNAIRE, formattedRes)
    } catch (e) {
      commit(MUTATIONS_TYPES.SET_QUESTIONNAIRE, generateQuestions())

      const acceptableStatuses = [HttpStatusCode.NotFound, HttpStatusCode.Forbidden]

      if (acceptableStatuses.includes((e as AxiosError).response?.status as HttpStatusCode)) {
        return
      }

      this.$captureException(e, {
        method: 'fetchQuestionnaire',
        message: 'medcard-questionnaire',
        component: 'vuex',
      })
      throw e
    } finally {
      commit(MUTATIONS_TYPES.SET_LOADING, false)
    }
  },
  async [ACTIONS_TYPES.SAVE_QUESTION]({ commit }, payload: QuestionnaireAnswerDto) {
    await QuestionnaireApi.sendAnswer({
      ...payload,
      patientAnonymousToken: Cookies.get(CookieKey.AnonymousQuestionnaireUid) || undefined,
    })
    commit(MUTATIONS_TYPES.SET_QUESTION, {
      ...payload,
      completed: true,
    } as QuestionnaireQuestion)
    commit(MUTATIONS_TYPES.SET_IS_CHANGES_APPLIED, true)
  },
  async [ACTIONS_TYPES.DELETE_QUESTIONNAIRE]({ commit }) {
    try {
      commit(MUTATIONS_TYPES.SET_LOADING, true)
      commit(MUTATIONS_TYPES.SET_IS_CHANGES_APPLIED, false)
      await QuestionnaireApi.deleteQuestionnaire()
    } catch (e) {
      this.$captureException(e, {
        method: 'deleteQuestionnaire',
        message: 'medcard-questionnaire',
        component: 'vuex',
      })
      throw e
    } finally {
      commit(MUTATIONS_TYPES.SET_LOADING, false)
      commit(MUTATIONS_TYPES.SET_QUESTIONNAIRE, generateQuestions())
    }
  },
  [ACTIONS_TYPES.CLEAR_QUESTIONNAIRE]({ commit }) {
    commit(MUTATIONS_TYPES.SET_IS_CHANGES_APPLIED, false)
    commit(MUTATIONS_TYPES.SET_QUESTIONNAIRE, generateQuestions())
    commit(MUTATIONS_TYPES.SET_QUESTION_GROUPS, [])
    commit(MUTATIONS_TYPES.SET_IS_FILLING_STATE, false)
    commit(MUTATIONS_TYPES.SET_LOADING, false)
  },
}

