import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { newDateFromStr } from '~/core/utils/newDateFromStr'

export const formatDate = (date: string | Date, formatStr: string, fromISO = false) => {
  if (typeof date === 'string') {
    return format(
      fromISO ? new Date(date) : newDateFromStr(date),
      formatStr,
      { locale: ru },
    )
  }

  return format(
    date,
    formatStr,
    { locale: ru },
  )
}
