import { masterClient } from '~/core/utils/clients/masterClient'
import { NavigationRoute } from '~/core/types/navigation'

const RESOURCE = 'notifications'

// NOTE: есть и другие типы уведомлений, можно узнать на бэке
export enum NotificationType {
  MedtochkaAppointmentTransferred = 'medtochka_appointment_transferred'
}

export type NotificationItemDto = {
  id: number
  title: string
  body: string
  routingData: NavigationRoute
  dtReaden: DateISO | null
  dtCreatedUtc: DateTimeISO
  type: NotificationType | string
}

export type ReadNotificationPayload = {
  notificationId?: number
  idempotencyKey?: string
}

export default {
  getAll(): Promise<{ data: NotificationItemDto[] }> {
    return masterClient.get(`${RESOURCE}/`, { camelize: true })
  },
  markAllAsRead(): Promise<void> {
    return masterClient.patch(`${RESOURCE}/`, {}, { camelize: true })
  },
  markAsRead(payload: ReadNotificationPayload) {
    return masterClient.patch(`${RESOURCE}/read/`, payload, {
      camelize: true,
    })
  },
}
