
import { FileType } from '~/core/enums'
import PDFViewer from '~/core/components/PDFViewer/PDFViewer.vue'
import { computed, defineComponent, inject, onBeforeUnmount, onMounted, PropType, ref } from '~/bridge'
import { GALLERY_EMIT_ERROR_INJECTION, GALLERY_TOOLBAR_HEIGHT } from './constants'
import { GalleryFileSource, RegisteredGalleryItemPayload } from './types'
import FullscreenGalleryItemImg from './FullscreenGalleryItemImg.vue'

export default defineComponent({
  name: 'FullscreenGalleryItem',
  components: { PDFViewer, FullscreenGalleryItemImg },
  props: {
    src: {
      type: [File, Function] as PropType<GalleryFileSource>,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  emits: {
    loaded: (_: RegisteredGalleryItemPayload) => true,
  },
  setup(props, { emit, expose }) {
    const emitError = inject(GALLERY_EMIT_ERROR_INJECTION)!
    const innerSrc = ref('')
    const innerFileInstance = ref<File | null>(null)
    const item = ref<{ zoom:(zoomFactor: number) => void, isZoomApplied: () => boolean } | null>(null)

    const isLoading = computed(() => !innerFileInstance.value || !innerSrc.value)
    const isPDFFile = computed(() =>
      innerFileInstance.value &&
      innerFileInstance.value.type === FileType.Pdf)

    onMounted(async () => {
      try {
        innerFileInstance.value = props.src instanceof Function ? await props.src() : props.src
        innerSrc.value = URL.createObjectURL(innerFileInstance.value)

        emit('loaded', {
          file: innerFileInstance.value,
          zoom: (zoomFactor: number) => item.value?.zoom(zoomFactor),
          isZoomApplied: () => item.value?.isZoomApplied() ?? false,
        })
      } catch (e) {
        innerSrc.value = ''
        innerFileInstance.value = null
        emitError?.(e)
      }
    })

    onBeforeUnmount(() => {
      URL.revokeObjectURL(innerSrc.value)
    })

    expose({
      zoom: (zoomFactor: number) => item.value?.zoom(zoomFactor),
      isZoomApplied: () => item.value?.isZoomApplied(),
    })

    return {
      item,
      innerSrc,
      isPDFFile,
      isLoading,
      GALLERY_TOOLBAR_HEIGHT,
    }
  },
})
