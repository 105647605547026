import { COMPONENTS_BIND, QUESTION_TEXT } from '~/core/components/Questionnaire/constants'
import { QuestionId, QuestionnaireQuestion } from '~/core/api/questionnaire.api.types'

type GenerateFormsListReturn = {
  questionId: QuestionId
  title: string
  component: string
  completed: boolean
}

export const generateFormsList = (questions: QuestionnaireQuestion[]): GenerateFormsListReturn[] =>
  questions.map(({ questionId, completed }) => ({
    questionId,
    completed,
    component: COMPONENTS_BIND[questionId],
    title: QUESTION_TEXT[questionId],
  }))
