
import BaseImg from '~/core/components/Base/BaseImg.vue'
import { computed, defineComponent, ref, watch } from '~/bridge'

const MEDIA_PREVIEW_SIZES = {
  IMAGE: { SMALL: 40, DEFAULT: 80, LARGE: 112 },
  DELETE_ICON: { SMALL: 16, DEFAULT: 24 },
} as const

export default defineComponent({
  name: 'MediaPreview',
  components: { BaseImg },
  props: {
    ymHide: { type: Boolean, default: false },
    src: { type: [String, File], required: true },
    deletable: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    bordered: { type: Boolean, default: false },
    hint: { type: String, default: '' },
    hintColor: { type: String, default: 'ui-kit-text-info' },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: 'ui-kit-icon-secondary' },
    imgProps: { type: Object, default: () => ({}) },
    fromMedcard: { type: Boolean, default: false },
  },
  emits: ['click:delete', 'click:icon', 'click'],
  setup(props, { emit, slots }) {
    const innerSrc = ref('')

    const isOverlayVisible = computed(() => props.loading || Boolean(props.icon) || Boolean(slots.icon))

    const imageSize = computed(() => {
      const { DEFAULT, SMALL, LARGE } = MEDIA_PREVIEW_SIZES.IMAGE

      if (props.fromMedcard) {
        return LARGE
      }

      return props.small ? SMALL : DEFAULT
    })

    const deleteSize = computed(() => {
      const { DEFAULT, SMALL } = MEDIA_PREVIEW_SIZES.DELETE_ICON

      return props.small ? SMALL : DEFAULT
    })

    watch(() => props.src, (val) => {
      innerSrc.value = val instanceof File ? URL.createObjectURL(val) : val
    }, { immediate: true })

    function handleLoad() {
      if (props.src instanceof File) {
        URL.revokeObjectURL(innerSrc.value)
      }
    }

    function emitClickDelete() {
      emit('click:delete')
    }

    function emitClickIcon() {
      emit('click:icon')
    }

    function emitClick() {
      emit('click')
    }

    return {
      innerSrc,
      handleLoad,
      isOverlayVisible,
      imageSize,
      deleteSize,
      emitClickDelete,
      emitClickIcon,
      emitClick,
    }
  },
})
