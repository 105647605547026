import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2bbd6842 = () => interopDefault(import('../pages/achievements/index.vue' /* webpackChunkName: "pages/achievements/index" */))
const _6177596f = () => interopDefault(import('../pages/appointments/index.vue' /* webpackChunkName: "pages/appointments/index" */))
const _55002498 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _3a1aaf46 = () => interopDefault(import('../pages/clinics/index.vue' /* webpackChunkName: "pages/clinics/index" */))
const _b496dbb6 = () => interopDefault(import('../pages/favourites/index.vue' /* webpackChunkName: "pages/favourites/index" */))
const _05d99057 = () => interopDefault(import('../pages/medcard/index.vue' /* webpackChunkName: "pages/medcard/index" */))
const _8c75b93a = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _6dcd3a38 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _2595b764 = () => interopDefault(import('../pages/rates/index.vue' /* webpackChunkName: "pages/rates/index" */))
const _1e70b0b2 = () => interopDefault(import('../pages/rules/index.vue' /* webpackChunkName: "pages/rules/index" */))
const _f8f96468 = () => interopDefault(import('../pages/unconfirmed-documents/index.vue' /* webpackChunkName: "pages/unconfirmed-documents/index" */))
const _2ead7764 = () => interopDefault(import('../pages/appointments/repeat.vue' /* webpackChunkName: "pages/appointments/repeat" */))
const _c2f1639c = () => interopDefault(import('../pages/dental/articles.vue' /* webpackChunkName: "pages/dental/articles" */))
const _3b17ecba = () => interopDefault(import('../pages/dental/articles/adentia.vue' /* webpackChunkName: "pages/dental/articles/adentia" */))
const _7a7f9c44 = () => interopDefault(import('../pages/dental/articles/anomalies.vue' /* webpackChunkName: "pages/dental/articles/anomalies" */))
const _38341c37 = () => interopDefault(import('../pages/dental/articles/calculus.vue' /* webpackChunkName: "pages/dental/articles/calculus" */))
const _8a0ab7a0 = () => interopDefault(import('../pages/dental/articles/caries.vue' /* webpackChunkName: "pages/dental/articles/caries" */))
const _6c1a324a = () => interopDefault(import('../pages/dental/articles/gingivitis.vue' /* webpackChunkName: "pages/dental/articles/gingivitis" */))
const _0f2b9e01 = () => interopDefault(import('../pages/dental/articles/parodontitis.vue' /* webpackChunkName: "pages/dental/articles/parodontitis" */))
const _0f68b2e8 = () => interopDefault(import('../pages/dental/articles/periodontitis.vue' /* webpackChunkName: "pages/dental/articles/periodontitis" */))
const _79bab44b = () => interopDefault(import('../pages/dental/articles/pulpitis.vue' /* webpackChunkName: "pages/dental/articles/pulpitis" */))
const _27853eec = () => interopDefault(import('../pages/medcard/folders.vue' /* webpackChunkName: "pages/medcard/folders" */))
const _50a12e38 = () => interopDefault(import('../pages/medcard/share.vue' /* webpackChunkName: "pages/medcard/share" */))
const _30bbe36c = () => interopDefault(import('../pages/medcard/treatment-plan/index.vue' /* webpackChunkName: "pages/medcard/treatment-plan/index" */))
const _397c77c5 = () => interopDefault(import('../pages/profile/family/index.vue' /* webpackChunkName: "pages/profile/family/index" */))
const _304bda81 = () => interopDefault(import('../pages/profile/settings.vue' /* webpackChunkName: "pages/profile/settings" */))
const _6ace5faa = () => interopDefault(import('../pages/profile/family/merge.vue' /* webpackChunkName: "pages/profile/family/merge" */))
const _0663b3ca = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _56dd6857 = () => interopDefault(import('../pages/medcard/treatment-plan/_id/index.vue' /* webpackChunkName: "pages/medcard/treatment-plan/_id/index" */))
const _311824da = () => interopDefault(import('../pages/appointments/_id/index.vue' /* webpackChunkName: "pages/appointments/_id/index" */))
const _4b96b07c = () => interopDefault(import('../pages/medcard/_id/index.vue' /* webpackChunkName: "pages/medcard/_id/index" */))
const _48515f17 = () => interopDefault(import('../pages/nps/_id/index.vue' /* webpackChunkName: "pages/nps/_id/index" */))
const _6daeba92 = () => interopDefault(import('../pages/unconfirmed-documents/_id/index.vue' /* webpackChunkName: "pages/unconfirmed-documents/_id/index" */))
const _f985ac5c = () => interopDefault(import('../pages/appointments/_id/prepare/index.vue' /* webpackChunkName: "pages/appointments/_id/prepare/index" */))
const _0d21e643 = () => interopDefault(import('../pages/appointments/_id/repeat.vue' /* webpackChunkName: "pages/appointments/_id/repeat" */))
const _58463c2c = () => interopDefault(import('../pages/appointments/_id/telemed.vue' /* webpackChunkName: "pages/appointments/_id/telemed" */))
const _cfc7b6da = () => interopDefault(import('../pages/appointments/_id/transfer.vue' /* webpackChunkName: "pages/appointments/_id/transfer" */))
const _b142ce66 = () => interopDefault(import('../pages/appointments/_id/prepare/questions.vue' /* webpackChunkName: "pages/appointments/_id/prepare/questions" */))
const _cec4cc7a = () => interopDefault(import('../pages/rates/_type/_id/index.vue' /* webpackChunkName: "pages/rates/_type/_id/index" */))
const _2afa64ba = () => interopDefault(import('../pages/rates/_type/_id/chat/index.vue' /* webpackChunkName: "pages/rates/_type/_id/chat/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/achievements",
    component: _2bbd6842,
    name: "achievements"
  }, {
    path: "/appointments",
    component: _6177596f,
    name: "appointments"
  }, {
    path: "/authorization",
    component: _55002498,
    name: "authorization"
  }, {
    path: "/clinics",
    component: _3a1aaf46,
    name: "clinics"
  }, {
    path: "/favourites",
    component: _b496dbb6,
    name: "favourites"
  }, {
    path: "/medcard",
    component: _05d99057,
    name: "medcard"
  }, {
    path: "/notifications",
    component: _8c75b93a,
    name: "notifications"
  }, {
    path: "/profile",
    component: _6dcd3a38,
    name: "profile"
  }, {
    path: "/rates",
    component: _2595b764,
    name: "rates"
  }, {
    path: "/rules",
    component: _1e70b0b2,
    name: "rules"
  }, {
    path: "/unconfirmed-documents",
    component: _f8f96468,
    name: "unconfirmed-documents"
  }, {
    path: "/appointments/repeat",
    component: _2ead7764,
    name: "appointments-repeat"
  }, {
    path: "/dental/articles",
    component: _c2f1639c,
    name: "dental-articles",
    children: [{
      path: "adentia",
      component: _3b17ecba,
      name: "dental-articles-adentia"
    }, {
      path: "anomalies",
      component: _7a7f9c44,
      name: "dental-articles-anomalies"
    }, {
      path: "calculus",
      component: _38341c37,
      name: "dental-articles-calculus"
    }, {
      path: "caries",
      component: _8a0ab7a0,
      name: "dental-articles-caries"
    }, {
      path: "gingivitis",
      component: _6c1a324a,
      name: "dental-articles-gingivitis"
    }, {
      path: "parodontitis",
      component: _0f2b9e01,
      name: "dental-articles-parodontitis"
    }, {
      path: "periodontitis",
      component: _0f68b2e8,
      name: "dental-articles-periodontitis"
    }, {
      path: "pulpitis",
      component: _79bab44b,
      name: "dental-articles-pulpitis"
    }]
  }, {
    path: "/medcard/folders",
    component: _27853eec,
    name: "medcard-folders"
  }, {
    path: "/medcard/share",
    component: _50a12e38,
    name: "medcard-share"
  }, {
    path: "/medcard/treatment-plan",
    component: _30bbe36c,
    name: "medcard-treatment-plan"
  }, {
    path: "/profile/family",
    component: _397c77c5,
    name: "profile-family"
  }, {
    path: "/profile/settings",
    component: _304bda81,
    name: "profile-settings"
  }, {
    path: "/profile/family/merge",
    component: _6ace5faa,
    name: "profile-family-merge"
  }, {
    path: "/",
    component: _0663b3ca,
    name: "index"
  }, {
    path: "/medcard/treatment-plan/:id",
    component: _56dd6857,
    name: "medcard-treatment-plan-id"
  }, {
    path: "/appointments/:id",
    component: _311824da,
    name: "appointments-id"
  }, {
    path: "/medcard/:id",
    component: _4b96b07c,
    name: "medcard-id"
  }, {
    path: "/nps/:id",
    component: _48515f17,
    name: "nps-id"
  }, {
    path: "/unconfirmed-documents/:id",
    component: _6daeba92,
    name: "unconfirmed-documents-id"
  }, {
    path: "/appointments/:id/prepare",
    component: _f985ac5c,
    name: "appointments-id-prepare"
  }, {
    path: "/appointments/:id/repeat",
    component: _0d21e643,
    name: "appointments-id-repeat"
  }, {
    path: "/appointments/:id/telemed",
    component: _58463c2c,
    name: "appointments-id-telemed"
  }, {
    path: "/appointments/:id/transfer",
    component: _cfc7b6da,
    name: "appointments-id-transfer"
  }, {
    path: "/appointments/:id/prepare/questions",
    component: _b142ce66,
    name: "appointments-id-prepare-questions"
  }, {
    path: "/rates/:type/:id",
    component: _cec4cc7a,
    name: "rates-type-id"
  }, {
    path: "/rates/:type/:id?/chat",
    component: _2afa64ba,
    name: "rates-type-id-chat"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
