
import { IS_STAGE } from '~/core/constants'
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'EnvironmentBanner',
  setup() {
    return {
      IS_STAGE,
    }
  },
})
