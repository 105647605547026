
import { useWindowSize } from '@vueuse/core'
import MobileLayout from '~/core/layouts/Mobile.vue'
import { useAppLink, useSmartAppBannerSetup } from '~/core/composables'
import { defineComponent, onBeforeMount, onBeforeUnmount, useNuxtApp, useRoute, watch } from '~/bridge'

export default defineComponent({
  name: 'Chat',
  components: {
    MobileLayout,
  },
  setup() {
    useSmartAppBannerSetup()
    const { $device, $vuetify, $store } = useNuxtApp()
    const $route = useRoute()
    const { appLink } = useAppLink($device, $route)
    const { height: windowHeight } = useWindowSize()

    watch(windowHeight, setRealVH)

    watch(() => $store.state.overlaysCount, (val) => {
      document.documentElement.style.overflow = val > 0 ? 'hidden' : ''

      if ($device.isDesktop) {
        document.documentElement.style.paddingRight = val > 0 ? `${$vuetify.breakpoint.scrollBarWidth}px` : '0'
      }
    })

    onBeforeMount(() => {
      if ($device.isMobile) {
        document.documentElement.classList.add('is-mobile')
        document.documentElement.style.overflow = 'hidden'
      }

      setRealVH()
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setRealVH)
    })

    function setRealVH() {
      // https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
      document.documentElement.style.setProperty('--vh', `${windowHeight.value / 100}px`)
    }

    return {
      appLink,
    }
  },
})
