import { render, staticRenderFns } from "./MedcardShare.vue?vue&type=template&id=6747d7ce"
import script from "./MedcardShare.vue?vue&type=script&lang=ts"
export * from "./MedcardShare.vue?vue&type=script&lang=ts"
import style0 from "./MedcardShare.vue?vue&type=style&index=0&id=6747d7ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseNumberMarker: require('/builds/private/medtochka-frontend/core/components/Base/BaseNumberMarker.vue').default,BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default,BaseFullscreenDialog: require('/builds/private/medtochka-frontend/core/components/Base/BaseFullscreenDialog.vue').default})
