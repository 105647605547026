
import Vue from 'vue'
import DatePickerWrapper from '~/core/components/DatePickerWrapper/DatePickerWrapper.vue'
import { computed, defineComponent, ref, watch } from '~/bridge'

export default defineComponent({
  name: 'DatePickerDialog',
  components: { DatePickerWrapper },
  props: {
    dialogProps: { type: Object, default: () => ({}) },
    datePickerProps: { type: Object, default: () => ({}) },
    dialogListeners: { type: Object, default: () => ({}) },
    value: { type: String, default: null },
    cancelText: { type: String, default: 'отменить' },
    submitText: { type: String, default: 'применить' },
  },
  emits: ['input', 'dialog:change'],
  setup(props, { emit }) {
    const dialog = ref<Vue & { save:(v: unknown) => void } | null>(null)
    const lazyDialogValue = ref(false)
    const lazyCalendarValue = ref<string | null>(null)
    const lazyDatePickerActivePicker = ref<string | null>(null)

    const innerDialogProps = computed(() => ({ width: 304, ...props.dialogProps }))
    const isSubmitDisabled = computed(() => !lazyCalendarValue.value)

    watch(
      () => props.value,
      (val: string | undefined) => {
        lazyCalendarValue.value = val ?? null
      },
      { immediate: true },
    )

    watch(
      () => props.dialogProps.value,
      (val: boolean | undefined) => {
        lazyDialogValue.value = val ?? false
      },
      { immediate: true },
    )

    watch(
      lazyDialogValue,
      (val: boolean) => {
        const activePicker = props.datePickerProps.activePicker as string

        if (val && ['DATE', 'MONTH', 'YEAR'].includes(activePicker)) {
          setTimeout(() => (lazyDatePickerActivePicker.value = activePicker))
        }

        emitDialogChange(val)
      },
    )

    function emitInput() {
      dialog.value?.save(lazyCalendarValue.value)
      lazyDialogValue.value = false

      emit('input', lazyCalendarValue.value)
    }

    function emitDialogChange(val: boolean) {
      emit('dialog:change', val)
    }

    return {
      dialog,
      lazyDialogValue,
      lazyCalendarValue,
      lazyDatePickerActivePicker,
      innerDialogProps,
      isSubmitDisabled,
      emitInput,
    }
  },
})
