
import BaseTextField from '~/core/components/Base/BaseTextField.vue'
import PatronymicTextField from '~/core/components/PatronymicTextField/PatronymicTextField.vue'
import { computed, defineComponent } from '~/bridge'
import { nameRules, surnameRules } from '~/features/Profile/functions'

export default defineComponent({
  name: 'FIOForm',
  components: {
    PatronymicTextField,
    BaseTextField,
  },
  props: {
    patronymic: { type: String, default: '' },
    forename: { type: String, default: '' },
    surname: { type: String, default: '' },
    isEmptyPatronymic: { type: Boolean, default: false },
  },
  emits: ['update:patronymic', 'update:forename', 'update:surname'],
  setup(props, { emit }) {
    const syncedPatronymic = computed({
      get() {
        return props.patronymic
      },
      set(val: string) {
        emit('update:patronymic', val)
      },
    })

    const syncedForename = computed({
      get() {
        return props.forename
      },
      set(val: string) {
        emit('update:forename', val)
      },
    })

    const syncedSurname = computed({
      get() {
        return props.surname
      },
      set(val: string) {
        emit('update:surname', val)
      },
    })

    return {
      surnameRules,
      nameRules,
      syncedPatronymic,
      syncedForename,
      syncedSurname,
    }
  },
})
