import { defineStore } from 'pinia'
import { PD_URL, PROFILE_GETTERS } from '~/core/constants'
import { computed, useNuxtApp } from '~/bridge'
import useCountriesStore from '~/stores/useCountriesStore'

export default defineStore('cis', () => {
  const { $featureFlag, $store } = useNuxtApp()

  const isYDoc = computed(() => $featureFlag('sff_cis') && process.env.domain === 'CIS')
  const pdSiteName = computed(() => isYDoc.value ? 'YDoc' : 'ПроДокторов')

  const isUserLogged = computed(() => Boolean($store.state.profile.phone))

  const pdUrl = computed(() => {
    if (!$featureFlag('sff_cis')) {
      return PD_URL
    }

    return isUserLogged.value ? $store.getters[PROFILE_GETTERS.PD_URL] : useCountriesStore().pdUrl
  })

  return {
    isYDoc,
    pdSiteName,
    pdUrl,
  }
})
