import { debounce } from 'lodash-es'
import townApi, { TownSelectItemDto } from '~/core/components/TownSelect/api'
import { DIALOG_MESSAGE as CORE_DIALOG } from '~/core/constants'
import { Ref, ref, useNuxtApp, watch } from '~/bridge'

type UseTownSearchPayload = {
  searchQuery: Ref<string>,
  onError?: (error: unknown) => void
}

type UseTownSearchReturn = {
  towns: Ref<TownSelectItemDto[]>
  loading: Ref<boolean>
  forceSearch: (query?: string) => void
}

type UseTownSearch = (payload: UseTownSearchPayload) => UseTownSearchReturn

export const useTownSearch: UseTownSearch = ({ searchQuery, onError }) => {
  const { $dialog } = useNuxtApp()

  const loading = ref(false)
  const towns = ref<TownSelectItemDto[]>([])
  const currentRequestTime = ref(0)

  watch(searchQuery, val => handleSearchInput(val))

  async function onSearchInput(query?: string) {
    if (Number(query?.length) > 100) {
      return
    }

    const innerTime = Date.now()

    currentRequestTime.value = innerTime
    loading.value = true

    try {
      const { data } = await townApi.get(query)

      if (innerTime !== currentRequestTime.value) {
        return
      }

      towns.value = data
    } catch (e) {
      onError ? onError(e) : $dialog.open({ ...CORE_DIALOG.GLOBAL_ERROR })
    } finally {
      loading.value = false
    }
  }

  const handleSearchInput = debounce((query: string) => onSearchInput(query), 200)

  return {
    towns,
    loading,
    forceSearch: onSearchInput,
  }
}
