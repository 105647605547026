
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue'
import { useToggleable } from '~/core/composables'
import { MedcardShare } from '~/core/components/MedcardShare'
import { defineComponent, ref, toRefs, watch } from '~/bridge'

export default defineComponent({
  name: 'MedcardSharePopup',
  components: {
    MedcardShare,
    BaseFullscreenDialog,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  emits: ['input'],
  setup(props, { emit, expose }) {
    const medcardShare = ref<{ resetState:() => void } | null>(null)

    const refProps = toRefs(props)

    const { isActive, show, hide } = useToggleable(refProps.value, emit, 'input')

    watch(isActive, (val) => {
      if (val) {
        medcardShare.value?.resetState()
      }
    })

    function handleScanned() {
      hide()
    }

    expose({
      show,
    })

    return {
      isActive,
      medcardShare,
      handleScanned,
    }
  },
})
