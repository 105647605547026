import { maxBy, sortBy } from 'lodash-es'
import { compressImage } from '~/core/functions'
import { getImageByFile } from '~/core/functions/files'

type Breakpoint = {
  maxSize: number
  quality: number
}

const DEFAULT_BREAKPOINTS: ReadonlyArray<Breakpoint> = [
  { maxSize: 500, quality: 0.9 },
  { maxSize: 1000, quality: 0.8 },
  { maxSize: 2000, quality: 0.7 },
] as const

/**
 * Сжимает изображение, применяя различное качество в зависимости от размера изображения.
 * Размеры изображения также будут ограничены максимальным значением maxSize из массива breakpoints.
 */
export const compressImageWithBreakpoints = async (
  file: File,
  breakpoints: ReadonlyArray<Breakpoint> = DEFAULT_BREAKPOINTS,
) => {
  const img = await getImageByFile(file)
  const imgMaxSize = Math.max(img.naturalWidth, img.naturalHeight)
  const breakpointByMaxSize = maxBy(breakpoints, 'maxSize')
  const breakpoint = sortBy(breakpoints, 'maxSize').find(({ maxSize }) => imgMaxSize < maxSize)

  return compressImage(file, {
    maxSize: breakpointByMaxSize?.maxSize,
    quality: breakpoint?.quality ?? breakpointByMaxSize?.quality,
  })
}
