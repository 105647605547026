import type { PDFDocumentProxy } from 'pdfjs-dist'
import { computed, ref, Ref, watch } from '~/bridge'

const INITIAL_WIDTH_MULTIPLIER = 0.95
const DEFAULT_PDF_WIDTH = 932
const MAX_SIZE_SCALE = 4

type UsePdfSizeOptions = {
  width: Ref<number>,
  height: Ref<number>
}

type UsePdfSizeReturn = {
  width: Ref<number>
  height: Ref<number>
  scale: Ref<number>
  setPDFSizes: () => void
  setScale: (zoomFactor: number) => void
  isZoomApplied: () => boolean
}

type UsePdfSize = (pdf: Ref<PDFDocumentProxy | null>, options: UsePdfSizeOptions) => UsePdfSizeReturn

export const usePDFSize: UsePdfSize = (pdf, options) => {
  const defaultWidth = computed(() => {
    return Math.floor(
      Math.min(
        options.width.value * INITIAL_WIDTH_MULTIPLIER,
        DEFAULT_PDF_WIDTH,
      ),
    )
  })
  const maxWidth = computed(() => defaultWidth.value * MAX_SIZE_SCALE)

  const width = ref(defaultWidth.value)
  const height = ref(DEFAULT_PDF_WIDTH)
  const scale = ref(1)

  watch(defaultWidth, (val) => {
    width.value = val
    scale.value = 1
    setPDFSizes()
  })

  const isZoomApplied = () => width.value !== defaultWidth.value

  async function setScale(zoomFactor: number) {
    const isResizeRequired = (width.value < maxWidth.value && zoomFactor > 1) ||
      (width.value > defaultWidth.value && zoomFactor < 1)

    if (!pdf.value || zoomFactor === 1 || !isResizeRequired) {
      return
    }

    const page = await pdf.value.getPage(1)
    width.value *= zoomFactor

    if (width.value > maxWidth.value) {
      width.value = maxWidth.value
    } else if (width.value < defaultWidth.value) {
      width.value = defaultWidth.value
    }

    const unscaledViewport = page.getViewport({ scale: 1 })
    scale.value = width.value / unscaledViewport.width

    const viewport = page.getViewport({
      scale: scale.value,
    })

    height.value = viewport.height
  }

  async function setPDFSizes() {
    if (!pdf.value) {
      return
    }

    const page = await pdf.value.getPage(1)
    const unscaledViewport = page.getViewport({ scale: 1 })

    scale.value = width.value / unscaledViewport.width

    const viewport = page.getViewport({
      scale: scale.value,
    })

    height.value = Math.floor(viewport.height)
  }

  return {
    width,
    height,
    scale,
    setPDFSizes,
    setScale,
    isZoomApplied,
  }
}
