/**
 * Практичная реализация регулярного выражения проверки валидного e-mail по стандарту RFC 5322
 * без имплементации валидации IP-адресов, доменно-специфичных адресов, скобок, ASCII-символов.
 * @see https://www.regular-expressions.info/email.html
 * @see https://datatracker.ietf.org/doc/html/rfc5322
 */
export const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i

export const blockedDomainRegExpOld = /@(outlook|hotmail|live)\./

export const blockedDomainRegExp = /@((outlook|hotmail|live|yahoo)\.|(gmail.com|icloud.com|me.com|my.com|googlemail.com|web.de|protonmail.com)$)/
