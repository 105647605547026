
import { AxiosError } from 'axios'
import Vue from 'vue'
import { DIALOG_MESSAGE as CORE_DIALOG, SUPPORT, TIMER_TIMEOUT } from '~/core/constants'
import { useTimer } from '~/core/composables/useTimer'
import EnterMail from '~/core/components/ConfirmMail/enterMail.vue'
import EnterCode from '~/core/components/ConfirmMail/enterCode.vue'
import SupportContactsBottomSheet from '~/core/components/SupportContactsBottomSheet/SupportContactsBottomSheet.vue'
import { blockedDomainRegExp, blockedDomainRegExpOld } from '~/core/constants/regex'
import { defineComponent, onBeforeMount, onBeforeUnmount, onMounted, PropType, ref, useNuxtApp, watch } from '~/bridge'
import { VForm } from '~/types'
import { MailStatus } from '~/features/Profile/enums'

type ErrorField = 'secret_code' | 'email'

export default defineComponent({
  name: 'ConfirmMailForm',
  components: {
    SupportContactsBottomSheet,
    EnterCode,
    EnterMail,
  },
  props: {
    onlyConfirmationEmail: { type: String, default: '' },
    onlyConfirmation: { type: Boolean, default: false },
    hideOnlyConfirmationSupport: { type: Boolean, default: false },
    mailLoading: { type: Boolean, default: false },
    codeLoading: { type: Boolean, default: false },
    emailLabel: { type: String, default: 'Электронная почта' },
    // reject должен возвращать ошибки бекенда
    requestCallback: {
      type: Function as PropType<(payload: { email: string }) => Promise<void>>,
      required: true,
    },
    // reject должен возвращать ошибки бекенда
    confirmCallback: {
      type: Function as PropType<(payload: { email: string, secretCode: string }) => Promise<void>>,
      required: true,
    },
    resendTimeout: { type: Number, default: TIMER_TIMEOUT },
    dailyLimit: { type: Boolean, default: false },
  },
  emits: ['email:confirmed', 'update:resend-timeout', 'click:reset'],
  setup(props, { emit }) {
    const enterMail = ref<Vue | null>(null)
    const enterCode = ref<Vue | null>(null)

    const step = ref(MailStatus.Mail)
    const currentMail = ref<string>('')
    const errorMail = ref('')
    const errorCode = ref('')
    const isMailLoading = ref(false)
    const isEnterCodeLoading = ref(false)
    const isInnerDailyLimit = ref(false)
    const isSupportSheetActive = ref(false)

    const { $dialog, $featureFlag } = useNuxtApp()
    const { codeTimer, formattedTimer, startCodeTimer, clearTimer } = useTimer()

    const rules = [
      $featureFlag('sff_block_email_domains')
        ? (v: string) => !blockedDomainRegExp.test(v.trim()) || 'Почта не должна быть на иностранном сервисе'
        : (v: string) => !blockedDomainRegExpOld.test(v.trim()) || 'Не поддерживаем outlook, hotmail, live',
    ]

    watch(codeTimer, (val) => {
      if (val !== null) {
        emit('update:resend-timeout', val)
      }
    })

    onBeforeMount(() => {
      step.value = props.onlyConfirmation ? MailStatus.Sent : MailStatus.Mail

      if (props.onlyConfirmation) {
        currentMail.value = props.onlyConfirmationEmail
        startCodeTimer(props.resendTimeout)
      }
    })

    onMounted(() => {
      resetValidations()
    })

    onBeforeUnmount(() => {
      resetData()
      clearTimer()
    })

    function resetData() {
      step.value = MailStatus.Mail
      currentMail.value = ''
      errorMail.value = ''
      errorCode.value = ''
    }

    function isCurrentStep(checkableStep: string) {
      return step.value === checkableStep
    }

    function handleChangeEmail() {
      resetError()
      clearTimer()
      step.value = MailStatus.Mail
      resetValidations()
    }

    function handleClickNoAccessMail() {
      if (!$featureFlag('sff_patient_medcard_reset')) {
        isSupportSheetActive.value = true

        return
      }

      emit('click:reset')
    }

    function resetMailError() {
      errorMail.value = ''
    }

    function resetCodeError() {
      if (errorCode.value === MailStatus.DailyLimit) {
        isInnerDailyLimit.value = errorCode.value === MailStatus.DailyLimit

        return
      }

      if ([MailStatus.Expired, MailStatus.AttemptsOut].includes(errorCode.value as MailStatus)) {
        return
      }

      errorCode.value = ''
    }

    function handleSendingError(
      { response }: AxiosError<Record<ErrorField, { code: string }>>,
      errorField: ErrorField,
    ) {
      const code = response?.data?.[errorField]?.code

      if (code) {
        errorMail.value = code
        errorCode.value = code

        return
      }

      $dialog.open({ ...CORE_DIALOG.GLOBAL_ERROR })
    }

    async function handleSendMail() {
      try {
        isMailLoading.value = true
        await props.requestCallback({
          email: currentMail.value,
        })
        step.value = MailStatus.Sent
        resetError()
        startCodeTimer()
      } catch (error) {
        handleSendingError(error, 'email')
      } finally {
        isMailLoading.value = false
      }
    }

    async function handleConfirmMail(secretCode: string) {
      try {
        isEnterCodeLoading.value = true
        await props.confirmCallback({
          email: currentMail.value,
          secretCode,
        })
        emit('email:confirmed')
      } catch (error) {
        handleSendingError(error, 'secret_code')
      } finally {
        isEnterCodeLoading.value = false
      }
    }

    function resetError() {
      isInnerDailyLimit.value = false
      errorMail.value = ''
      errorCode.value = ''
    }

    function resetValidations() {
      (enterMail.value?.$refs.form as VForm)?.resetValidation();
      (enterCode.value?.$refs.form as VForm)?.resetValidation()
    }

    return {
      SUPPORT,
      rules,
      isInnerDailyLimit,
      MailStatus,
      enterMail,
      enterCode,
      step,
      currentMail,
      errorMail,
      errorCode,
      isMailLoading,
      isEnterCodeLoading,
      isSupportSheetActive,
      formattedTimer,
      isCurrentStep,
      handleChangeEmail,
      handleClickNoAccessMail,
      resetMailError,
      resetCodeError,
      handleSendMail,
      handleConfirmMail,
    }
  },
})
