import { infoClient } from '~/core/utils/clients/infoClient'

export type SpecialityDto = {
  id: number
  name: string
  translit: string
}

export default {
  getAll(): Promise<{ data: SpecialityDto[] }> {
    return infoClient.get('/specialities/')
  },
}
