import { PDFDocumentProxy } from 'pdfjs-dist'
import { ref, Ref } from '~/bridge'

export const usePDFJS = () => {
  const pdf: Ref<PDFDocumentProxy | null> = ref(null)

  async function load(src: string) {
    const pdfjsLib = await import('pdfjs-dist/legacy/build/pdf.js')
    pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js?v1'
    const loadingTask = pdfjsLib.getDocument(src)
    pdf.value = await loadingTask.promise
  }

  function destroy() {
    pdf.value?.cleanup()
    pdf.value?.destroy()
    pdf.value = null
  }

  return {
    pdf,
    destroy,
    load,
  }
}
