import { render, staticRenderFns } from "./BaseImg.vue?vue&type=template&id=030a817f&scoped=true"
import script from "./BaseImg.vue?vue&type=script&lang=ts"
export * from "./BaseImg.vue?vue&type=script&lang=ts"
import style0 from "./BaseImg.vue?vue&type=style&index=0&id=030a817f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030a817f",
  null
  
)

export default component.exports