import UAParser from 'ua-parser-js'

const MIN_REQUIRED_CHROME_VERSION = 72
const MIN_REQUIRED_SAFARI_VERSION = 14
const MIN_REQUIRED_IOS_VERSION = 14

const BROWSERS = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  Safari: 'Safari',
  Electron: 'Electron',
  MIUI: 'miui',
}

const ENGINES = {
  Blink: 'Blink',
  WebKit: 'WebKit',
  Gecko: 'Gecko',
}

const OPTIMAL_BROWSERS = ['chrome', 'chromium', 'electron', 'firefox', 'safari', 'webkit', 'opera']

let OS = ''
let OSVersion = ''
let browserName = ''
let browserVersion = ''
let engineName = ''
let engineVersion = ''
let parsed = false

const parseUserAgent = () => {
  const { userAgent, maxTouchPoints, platform } = navigator
  const parser = new UAParser(userAgent)

  OSVersion = parser.getOS().version ?? ''
  browserName = parser.getBrowser().name ?? ''
  browserVersion = parser.getBrowser().version ?? ''
  engineName = parser.getEngine().name ?? ''
  engineVersion = parser.getEngine().version ?? ''

  if (userAgent.match(/Android/i)) {
    OS = 'android'
  } else if (userAgent.match(/iP(ad|hone|od)/i) || (maxTouchPoints && maxTouchPoints > 2 && /MacIntel/.test(platform))) {
    OS = 'ios'
  } else if (userAgent.match(/Mac(intosh| OS X)/i)) {
    OS = 'macos'
  } else if (userAgent.match(/Windows/i)) {
    OS = 'windows'
  }

  parsed = true
}

const isMobileBrowser = () => OS === 'android' || OS === 'ios'

const isChromiumBased = () => engineName === ENGINES.Blink
const isFirefox = () => engineName === ENGINES.Gecko
const isWebKitBased = () => engineName === ENGINES.WebKit
const isSafari = () => browserName?.includes(BROWSERS.Safari)
const isMi = () => browserName?.toLowerCase().includes(BROWSERS.MIUI)

const getVersion = () => browserVersion || ''
const getSafariVersion = () => isSafari() ? Number.parseInt(getVersion(), 10) : -1
const getIOSVersion = () => isWebKitBased() ? Number.parseInt(OSVersion, 10) : -1

const isEngineVersionGreaterThan = (version: number) => {
  if (engineVersion) {
    return parseInt(engineVersion, 10) > parseInt(String(version), 10)
  }
}

const isSupportedAndroidBrowser = () => (isChromiumBased() || isFirefox()) && !isMi()
const isSupportedIOSBrowser = () => (
  getSafariVersion() >= MIN_REQUIRED_IOS_VERSION ||
  getIOSVersion() >= MIN_REQUIRED_IOS_VERSION
)
const isSupportedMobileBrowser = () => (
  (OS === 'android' && isSupportedAndroidBrowser()) ||
  (OS === 'ios' && isSupportedIOSBrowser())
)

const isSupported = () => {
  if (isSafari() && getSafariVersion() < MIN_REQUIRED_SAFARI_VERSION) {
    return false
  }

  return (
    (isChromiumBased() && isEngineVersionGreaterThan(MIN_REQUIRED_CHROME_VERSION - 1)) ||
    isFirefox() ||
    isWebKitBased()
  )
}

export const isSupportedBrowser = () => {
  !parsed && parseUserAgent()

  return isMobileBrowser() ? isSupportedMobileBrowser() : isSupported()
}

export const isOptimalBrowser = () => {
  !parsed && parseUserAgent()

  return Boolean(
    OPTIMAL_BROWSERS.find(name => browserName?.toLowerCase().includes(name)),
  )
}

export const getDeviceInfo = () => {
  !parsed && parseUserAgent()

  return {
    os: {
      name: OS,
      version: OSVersion,
    },
    browser: {
      name: browserName,
      version: browserVersion,
    },
  }
}

// NOTE: Утильная функция, использующаяся ТОЛЬКО в тесте.
// Нужна чтобы затереть значение глобальной переменной parsed, чтобы user agent спарсился заново
export const clearParsed = () => {
  parsed = false
}
