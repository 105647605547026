
import { useElementHover, useElementSize, useEventListener, useMouseInElement } from '@vueuse/core'
import { isNumber } from 'lodash-es'
import { FileType } from '~/core/enums'
import { computed, defineComponent, nextTick, onBeforeMount, ref, useNuxtApp, watch } from '~/bridge'
import EditorTemplate from './EditorTemplate.vue'
import ActionBtn from './ActionBtn.vue'

const ADDITIONAL_LINE_WIDTH = 20

type Point = { x: number, y: number }
type Line = { start: Point, end: Point, width: number }

export default defineComponent({
  name: 'ImageDrawer',
  components: {
    EditorTemplate,
    ActionBtn,
  },
  props: {
    src: { type: String, required: true },
    lineColor: { type: String, default: '#FFFFFF' },
    /**
     * Ширина линии рисования 1 - 100
     * */
    lineWidth: { type: Number, default: 50, validator: (v: number) => isNumber(v) && v >= 1 && v <= 100 },
    outputFileName: { type: String, default: 'Изображение' },
  },
  emits: ['update:line-width', 'save'],
  setup(props, { expose, emit }) {
    const img = ref<HTMLImageElement | null>(null)
    const rootElement = ref<HTMLElement | null>(null)
    const canvas = ref<HTMLCanvasElement | null>(null)
    const widthSlider = ref<HTMLElement | null>(null)

    const innerLineWidth = ref(50)
    const isPressed = ref(false)
    const isSliderActive = ref(false)
    const history = ref<Array<Line[]>>([[]])

    const { $device } = useNuxtApp()
    const { width, height } = useElementSize(rootElement)

    const { elementX, elementY, isOutside } = useMouseInElement(canvas, {
      handleOutside: false,
    })

    const isSliderHovered = useElementHover(widthSlider)

    const contentStyle = ref({
      top: '0',
      left: '0',
      width: 0,
      height: 0,
    })

    const ctx = computed(() => canvas.value?.getContext('2d'))

    const formattedLineWidth = computed(() => Math.min(width.value, height.value) *
      0.00075 * (innerLineWidth.value + ADDITIONAL_LINE_WIDTH))

    const cursorStyle = computed(() => ({
      display: isOutside.value || isSliderHovered.value ? 'none' : 'block',
      background: props.lineColor,
      top: `${elementY.value - (formattedLineWidth.value / 2)}px`,
      left: `${elementX.value - (formattedLineWidth.value / 2)}px`,
      width: `${formattedLineWidth.value}px`,
      height: `${formattedLineWidth.value}px`,
    }))

    watch(() => props.lineWidth, (val) => {
      innerLineWidth.value = val
    }, { immediate: true })

    watch(innerLineWidth, (val) => {
      if (val !== props.lineWidth) {
        emit('update:line-width', val)
      }
    })

    watch(isPressed, (val) => {
      if (!isOutside.value && !val) {
        history.value.push([])
      }
    })

    watch([elementX, elementY], ([x, y], [prevX, prevY]) => {
      if (!isOutside.value && isPressed.value) {
        const newLine: Line = {
          start: {
            x: prevX,
            y: prevY,
          },
          end: {
            x,
            y,
          },
          width: formattedLineWidth.value,
        }

        history.value[history.value.length - 1].push(newLine)

        drawLines(ctx.value!, [newLine])
      }
    })

    onBeforeMount(() => {
      if ($device.isMobileOrTablet) {
        useEventListener('touchstart', handleStartDrawing)

        useEventListener('touchend', () => {
          isPressed.value = false
        })

        return
      }

      useEventListener('mousedown', handleStartDrawing)

      useEventListener('mouseup', () => {
        isPressed.value = false
      })
    })

    async function handleImageLoad() {
      // Ожидание рендра, поскольку event может сработать до mounted
      await nextTick()
      resizeContent()

      watch([width, height], () => {
        resizeContent()
      })

      watch([() => contentStyle.value.width, () => contentStyle.value.height],
        async ([width, height], [prevWidth, prevHeight]) => {
          const widthDeltaMultiplier = width / prevWidth
          const heightDeltaMultiplier = height / prevHeight

          history.value = history.value.map(lines => lines.map(({ start, end, width }) => ({
            start: {
              x: start.x * widthDeltaMultiplier,
              y: start.y * heightDeltaMultiplier,
            },
            end: {
              x: end.x * widthDeltaMultiplier,
              y: end.y * heightDeltaMultiplier,
            },
            width: width * widthDeltaMultiplier,
          })))

          await nextTick()
          drawLines(ctx.value!, history.value.flat())
        })
    }

    function resizeContent() {
      if (!img.value) {
        return
      }

      let targetWidth = 0
      let targetHeight = 0
      let targetBound: 'width' | 'height' = 'width'

      if (img.value.naturalWidth < img.value.naturalHeight) {
        targetBound = 'height'
      }

      if (targetBound === 'width') {
        targetWidth = width.value
        targetHeight = (width.value / img.value.naturalWidth) * img.value.naturalHeight

        if (targetHeight > height.value) {
          targetWidth = targetWidth * (height.value / targetHeight)
          targetHeight = height.value
        }
      }

      if (targetBound === 'height') {
        targetWidth = (height.value / img.value.naturalHeight) * img.value.naturalWidth
        targetHeight = height.value

        if (targetWidth > width.value) {
          targetHeight = targetHeight * (width.value / targetWidth)
          targetWidth = width.value
        }
      }

      contentStyle.value = {
        top: `${(height.value - targetHeight) / 2}px`,
        left: `${(width.value - targetWidth) / 2}px`,
        width: targetWidth,
        height: targetHeight,
      }
    }

    function handleStartDrawing(ev: MouseEvent | TouchEvent) {
      isPressed.value = ev.target === canvas.value

      if (!isPressed.value) {
        return
      }

      isSliderActive.value = false

      const newLine: Line = {
        start: {
          x: elementX.value,
          y: elementY.value,
        },
        end: {
          x: elementX.value,
          y: elementY.value,
        },
        width: formattedLineWidth.value,
      }

      history.value[history.value.length - 1].push(newLine)
      drawLines(ctx.value!, [newLine])
    }

    function drawLines(ctx: CanvasRenderingContext2D, lines: Line[]) {
      lines.forEach(({ start, end, width }) => {
        ctx.beginPath()

        ctx.lineWidth = width
        ctx.lineCap = 'round'
        ctx.strokeStyle = props.lineColor

        ctx.moveTo(start.x, start.y)
        ctx.lineTo(end.x, end.y)
        ctx.stroke()
      })
    }

    function clearCanvas() {
      if (!ctx.value || !canvas.value) {
        return
      }

      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height)
    }

    function undo() {
      if (!history.value[history.value.length - 1].length) {
        history.value.pop()
      }

      history.value.splice(history.value.length - 1, 1, [])

      clearCanvas()
      drawLines(ctx.value!, history.value.flat())
    }

    async function save(fileName = 'Изображение') {
      if (!img.value) {
        return
      }

      const saverCanvas = document.createElement('canvas')

      saverCanvas.width = img.value.naturalWidth
      saverCanvas.height = img.value.naturalHeight

      const saverCanvasCtx = saverCanvas.getContext('2d')!

      const widthDeltaMultiplier = img.value.naturalWidth / contentStyle.value.width
      const heightDeltaMultiplier = img.value.naturalHeight / contentStyle.value.height

      const realSizeImageLines = history.value.flat().map(({ start, end, width }) => ({
        start: {
          x: start.x * widthDeltaMultiplier,
          y: start.y * heightDeltaMultiplier,
        },
        end: {
          x: end.x * widthDeltaMultiplier,
          y: end.y * heightDeltaMultiplier,
        },
        width: width * widthDeltaMultiplier,
      }))

      saverCanvasCtx.drawImage(img.value, 0, 0)

      drawLines(saverCanvasCtx, realSizeImageLines)

      const promise = new Promise<File>((resolve) => {
        saverCanvas.toBlob((blob) => {
          resolve(new File([blob!], fileName, { type: FileType.Jpeg }))
        })
      })

      const file = await promise

      emit('save', file)

      return file
    }

    expose({
      undo,
      save,
    })

    return {
      img,
      contentStyle,
      cursorStyle,
      rootElement,
      widthSlider,
      isSliderActive,
      undo,
      save,
      handleImageLoad,
      innerLineWidth,
      width,
      height,
      canvas,
      history, // Для тестирования
    }
  },
})
