
import { useVModel } from '@vueuse/core'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { isEqual, isNull } from 'lodash-es'
import { BottomControlsButtons } from '~/core/components'
import BaseTextField from '~/core/components/Base/BaseTextField.vue'
import { rulesGenerators } from '~/core/utils/rulesGenerators'
import { SNACKBAR_MESSAGE as CORE_SNACKBAR } from '~/core/constants/snackbar'
import { DOCUMENTS_ACTIONS, DOCUMENTS_GETTERS } from '~/core/constants/store/medcard/documents'
import { computed, defineComponent, PropType, ref, useNuxtApp, watch } from '~/bridge'
import { VForm } from '~/types'
import NewDocumentToFolderPopup from '~/features/Medcard/components/folders/NewDocumentToFolderPopup.vue'
import { MedcardDocumentType } from '~/features/Medcard/enums'
import { DIALOG_MESSAGE as MEDCARD_DIALOG } from '~/features/Medcard/constants/dialog'
import { MedcardDocumentGroupListItemDto } from '~/features/Medcard/api/medcard.api'
import getDocumentGroupDate from '~/features/Medcard/functions/getDocumentGroupDate'
import { SNACKBAR_MESSAGE as MEDCARD_SNACKBAR } from '~/features/Medcard/constants/snackbar'
import { FoldersApi, MedcardFolderDto } from '~/features/Medcard/api/folders.api'

export default defineComponent({
  name: 'NewFolderPopup',
  components: {
    NewDocumentToFolderPopup,
    BaseTextField,
    BottomControlsButtons,
  },
  props: {
    value: { type: Boolean, default: true },
    foldersNames: { type: Array as PropType<string[]>, default: () => [] },
    folderToEdit: { type: Object as PropType<(MedcardFolderDto & { documentsText: string }) | null>, default: null },
  },
  emits: ['create', 'update:value', 'folder:created', 'folder:deleted'],
  setup(props, { emit }) {
    const form = ref<VForm | null>(null)

    const isFormCorrect = ref(false)
    const folderName = ref('')
    const initialFolderName = ref('')
    const isNewDocumentToFolderPopupActive = ref(false)
    const documentsToAdd = ref<MedcardDocumentGroupListItemDto[]>([])
    const documentsToAddIds = ref<number[]>([])
    const initialDocumentsToAddIds = ref<number[]>([])
    const isExtraActionsBottomSheetActive = ref(false)
    const documentToRemoveIndex = ref<number | null>(null)
    const isProcessing = ref(false)
    const isFetchDocuments = ref(false)

    const isActive = useVModel(props, 'value', emit)
    const { $dialog, $store, $captureException } = useNuxtApp()

    const flatMedcardDocuments = computed<MedcardDocumentGroupListItemDto[]>(() =>
      $store.getters[DOCUMENTS_GETTERS.GET_MEDCARD_FLAT_DOCUMENTS])
    const medcardDocumentsCount = computed(() => $store.getters[DOCUMENTS_GETTERS.GET_MEDCARD_DOCUMETS_COUNT])

    const folderNameRules = computed(() => [
      rulesGenerators.required(),
      (v: string) => {
        const sameFolder = props.foldersNames.find(name => name.toLowerCase() === v.toLowerCase())

        if (sameFolder && props.folderToEdit && sameFolder === props.folderToEdit.name) {
          return true
        }

        return !sameFolder || 'Папка с таким названием уже есть'
      },
    ])

    const hasAnyChanges = computed(() => (
      initialFolderName.value !== folderName.value ||
      !isEqual(initialDocumentsToAddIds.value.sort(), [...documentsToAddIds.value].sort())
    ))

    const isSaveButtonDisabled = computed(() => {
      if (props.folderToEdit) {
        return !hasAnyChanges.value
      }

      return !isFormCorrect.value
    })

    watch(isActive, (val) => {
      if (!val) {
        return
      }

      folderName.value = props.folderToEdit?.name || ''
      initialFolderName.value = props.folderToEdit?.name || ''
      form.value?.resetValidation()

      documentsToAddIds.value = props.folderToEdit ? [...props.folderToEdit.documentGroups] : []
      initialDocumentsToAddIds.value = props.folderToEdit ? [...props.folderToEdit.documentGroups] : []
      documentsToAdd.value = props.folderToEdit ? getDocumentsByIds(props.folderToEdit.documentGroups) : []
    }, { immediate: true })

    function getIcon(type: MedcardDocumentType) {
      switch (type) {
        case MedcardDocumentType.Analysis:
          return 'ui-icon-laba'
        case MedcardDocumentType.Conclusion:
          return 'ui-icon-data-graph'
        case MedcardDocumentType.Survey:
          return 'ui-icon-doctor'
        default:
          return 'ui-icon-note'
      }
    }

    async function handleClickSubmit() {
      if (isSaveButtonDisabled.value || isProcessing.value) {
        return
      }

      isProcessing.value = true

      try {
        const payload = {
          name: folderName.value,
          documentGroupIds: documentsToAddIds.value,
        }

        if (!props.folderToEdit) {
          await FoldersApi.createMedcardFolder(payload)
        } else {
          await FoldersApi.updateMedcardFolder(props.folderToEdit.id, payload)
        }

        $store.commit(
          'main-snackbar/open',
          props.folderToEdit ? MEDCARD_SNACKBAR.CHANGES_SAVED : MEDCARD_SNACKBAR.FOLDER_CREATED,
        )
        emit('folder:created')
        isActive.value = false
      } catch (e) {
        $store.commit('main-snackbar/open', CORE_SNACKBAR.TRY_AGAIN_ERROR)
        $captureException(e, {
          component: 'NewFolderPopup',
          method: 'handleClickSubmit',
        })
      } finally {
        isProcessing.value = false
      }
    }

    async function handleRemoveFromFolder() {
      isExtraActionsBottomSheetActive.value = false
      const isResolved = await $dialog.open(MEDCARD_DIALOG.REMOVE_DOCUMENT_FROM_FOLDER)

      if (isResolved || isNull(documentToRemoveIndex.value)) {
        return
      }

      documentsToAddIds.value.splice(documentToRemoveIndex.value, 1)
      documentsToAdd.value.splice(documentToRemoveIndex.value, 1)
    }

    async function handleClickPrependIcon() {
      if (!hasAnyChanges.value) {
        isActive.value = false

        return
      }

      const isResolved = await $dialog.open(MEDCARD_DIALOG.CANCEL_CHANGES)

      if (!isResolved) {
        return
      }

      isActive.value = false
    }

    function getDocumentsByIds(ids: number[]) {
      return flatMedcardDocuments.value
        .filter(item => ids.includes(item.id))
        .map(item => ({
          ...item,
          dtVisit: format(parseISO(getDocumentGroupDate(item)), 'd MMMM yyyy', { locale: ru }),
        }))
    }

    function handleAddDocumentsToFolder(ids: number[]) {
      documentsToAddIds.value = ids
      documentsToAdd.value = getDocumentsByIds(ids)
    }

    function handleDocumentItemActionClick(index: number) {
      isExtraActionsBottomSheetActive.value = true
      documentToRemoveIndex.value = index
    }

    async function handleClickAddDocumentToFolder() {
      try {
        isFetchDocuments.value = true
        await $store.dispatch(DOCUMENTS_ACTIONS.FETCH_MEDCARD_GROUPS, { withError: true })

        if (isActive.value) {
          isNewDocumentToFolderPopupActive.value = true
        }
      } catch (e) {
        $store.commit('main-snackbar/open', CORE_SNACKBAR.TRY_AGAIN_ERROR)
        $captureException(e, {
          component: 'NewFolderPopup',
          method: 'handleClickAddDocumentToFolder',
        })
      }

      isFetchDocuments.value = false
    }

    async function handleClickAppendIcon() {
      const isResolved = await $dialog.open(MEDCARD_DIALOG.DELETE_FOLDER)

      if (isResolved || !props.folderToEdit) {
        return
      }

      try {
        await FoldersApi.deleteFolder(props.folderToEdit.id)
        emit('folder:deleted')
        isActive.value = false
      } catch (e) {
        $store.commit('main-snackbar/open', CORE_SNACKBAR.TRY_AGAIN_ERROR)
        $captureException(e, {
          component: 'NewFolderPopup',
          method: 'handleClickAppendIcon',
        })
      }
    }

    return {
      isActive,
      form,
      isFormCorrect,
      folderName,
      isNewDocumentToFolderPopupActive,
      documentsToAdd,
      documentsToAddIds,
      isExtraActionsBottomSheetActive,
      flatMedcardDocuments,
      medcardDocumentsCount,
      isSaveButtonDisabled,
      isProcessing,
      isFetchDocuments,
      rules: folderNameRules,
      handleClickSubmit,
      getIcon,
      handleRemoveFromFolder,
      handleClickPrependIcon,
      handleAddDocumentsToFolder,
      handleDocumentItemActionClick,
      handleClickAddDocumentToFolder,
      handleClickAppendIcon,
    }
  },
})

