import { Ref, ref, SetupContext, watch } from '~/bridge'

export const useToggleable = <T extends string>(
  valueProp: Ref,
  emit: SetupContext<T[]>['emit'],
  modelEvent: T,
) => {
  const isActive = ref(false)

  watch(valueProp, (val: boolean) => {
    isActive.value = val
  }, { immediate: true })

  watch(isActive, (val: boolean) => {
    val !== valueProp.value && emitInput(val)
  })

  function show() {
    isActive.value = true
  }

  function hide() {
    isActive.value = false
    emitInput(false)
  }

  function emitInput(val: boolean) {
    emit(modelEvent, val)
  }

  return {
    isActive,
    show,
    hide,
    emitInput,
  }
}
