import type { ActionTree, MutationTree } from 'vuex'
import { removeNamespace } from '~/core/functions'
import { PUBLISH_ACTIONS, PUBLISH_MUTATIONS } from '~/core/constants/store/rate/publish'
import { RateTextDto } from '~/features/Rates/api/chat.api.types'

interface IState {
  text: RateTextDto[]
}

const initialState = (): IState => ({
  text: [],
})

export const state = initialState
export type TRootState = ReturnType<typeof state>

const MUTATIONS_TYPES = removeNamespace('rate/publish/', PUBLISH_MUTATIONS)

const ACTIONS_TYPES = removeNamespace('rate/publish/', PUBLISH_ACTIONS)

export const mutations: MutationTree<TRootState> = {
  [MUTATIONS_TYPES.SET_TEXT](state, payload: RateTextDto[]) {
    state.text = payload
  },
  [MUTATIONS_TYPES.CLEAR](state) {
    Object.assign(state, initialState())
  },
}

export const actions: ActionTree<TRootState, TRootState> = {
  [ACTIONS_TYPES.INIT]({ commit }, payload: IState) {
    commit(MUTATIONS_TYPES.SET_TEXT, payload.text)
  },
}
