import type { ActionTree, MutationTree } from 'vuex'

const DEFAULT_TIMEOUT = 4000
const LARGE_TIMEOUT = 7000

export type MainSnackbarState = {
  text: string,
  cancelText: string,
  timeout: number,
  cancelable: boolean,
  cancelHandler: Function | undefined,
  dontCloseOnRouteChange: boolean
}

export type RootState = MainSnackbarState & { isActive: boolean }

/**
 * Todo:
 * здесь должна быть функция-фабрика, которая возвращает default state,
 * но мы тогда теряем intelliSense у редактора при обращении к state
 */
export const state = () => ({
  isActive: false,
  text: '',
  cancelText: '',
  timeout: DEFAULT_TIMEOUT,
  cancelable: false,
  cancelHandler: undefined,
  dontCloseOnRouteChange: false,
} as RootState)

export const actions: ActionTree<RootState, any> = {
  handleOpen({ commit }, payload) {
    const timeout = payload.cancelText ? LARGE_TIMEOUT : DEFAULT_TIMEOUT

    commit('open', { timeout, ...payload })
  },
}
export const mutations: MutationTree<RootState> = {
  open(state, payload) {
    state.isActive = true
    state.text = payload.text
    state.cancelText = payload.cancelText
    state.timeout = payload.timeout
    state.cancelable = payload.cancelable
    state.cancelHandler = payload.cancelHandler
    state.dontCloseOnRouteChange = payload.dontCloseOnRouteChange
  },
  allowClose(state) {
    state.dontCloseOnRouteChange = false
  },
  close(state) {
    /**
     * Todo:
     * Здесь можно указать Object.assign(state, getDefaultState()),
     * но тогда теряется intelliSence
     */
    state.isActive = false
    state.text = ''
    state.cancelText = ''
    state.cancelable = false
    state.cancelHandler = undefined
    state.dontCloseOnRouteChange = false
  },
}
