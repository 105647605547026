
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'BaseCircularPreloader',
  props: {
    bgColor: { type: String, default: '' },
    color: { type: String, default: 'primary' },
  },
})
