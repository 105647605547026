
import { debounce, isString } from 'lodash-es'
import BaseFullscreenDialog from '~/core/components/Base/BaseFullscreenDialog.vue'
import SearchBar from '~/core/components/SearchBar/SearchBar.vue'
import EmptyPlug from '~/core/components/EmptyPlug/EmptyPlug.vue'
import { CONTAINER_WIDTH } from '~/core/constants'
import { isClearTextFieldClick } from '~/core/functions'
import { computed, defineComponent, PropType, ref, useNuxtApp, watch } from '~/bridge'
import { Validatable } from '~/types'

export default defineComponent({
  name: 'FullscreenSelect',
  components: {
    BaseFullscreenDialog,
    EmptyPlug,
    SearchBar,
  },
  inheritAttrs: false,
  props: {
    isActive: { type: Boolean, default: false },
    value: {
      type: [String, Number, Object] as PropType<string | number | Record<string, unknown> | null>,
      default: null,
    },
    items: { type: Array as PropType<Record<string, unknown>[]>, default: () => [] },
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' },
    label: { type: String, default: '' },
    dialogLabel: { type: String, default: '' },
    searchLabel: { type: String, default: 'Найти' },
    selectClass: { type: String, default: '' },
    returnObject: { type: Boolean, default: false },
    searchLoading: { type: Boolean, default: false },
    searchQuery: { type: String, default: '' },
    /**
     * Отключает автоматическую фильтрацию списка items.
     * Подходит для поиска посредством вызова api.
    * */
    disableAutoFiltering: { type: Boolean, default: false },
    debouncedSearch: { type: Boolean, default: false },
    searchDebounceTimeout: { type: Number, default: 300 },
    hideOnSelect: { type: Boolean, default: true },
    hideActivator: { type: Boolean, default: false },
  },
  emits: ['input', 'update:searchQuery', 'update:isActive'],
  setup(props, { emit, expose }) {
    const { $vuetify } = useNuxtApp()

    const lazyIsActive = ref(false)
    const lazySearchQuery = ref('')
    const lazyValue = ref<unknown>(null)
    const isInputFocused = ref(false)
    const isInputErrored = ref(false)
    const textField = ref<Validatable | null>(null)

    const arrowIconColor = computed(() => {
      if (isInputErrored.value) {
        return 'error'
      }

      if (isInputFocused.value) {
        return 'primary'
      }

      return undefined
    })
    const displayedText = computed(() => {
      return props.returnObject && typeof lazyValue.value === 'object' && lazyValue.value
        ? (lazyValue.value as Record<string, unknown>)[props.itemText] || ''
        : props.items.find(item => item[props.itemValue] === lazyValue.value)?.[props.itemText] || ''
    })

    const innerSearchQuery = computed({
      get() {
        return lazySearchQuery.value
      },
      set(val: string) {
        lazySearchQuery.value = val

        if (props.debouncedSearch) {
          debouncedEmitUpdateSearch.value(val)

          return
        }

        emit('update:searchQuery', val)
      },
    })

    const paddingBreakpoint = computed(() => CONTAINER_WIDTH + 30)

    const searchBarStyle = computed(() => {
      if ($vuetify.breakpoint.width > paddingBreakpoint.value) {
        return {
          marginLeft: `-${$vuetify.breakpoint.scrollBarWidth / 2}px`,
        }
      }

      return { }
    })

    const filteredItems = computed(() => props.disableAutoFiltering
      ? props.items
      : props.items
        .filter((item) => {
          const text = item[props.itemText]

          if (!lazySearchQuery.value || !isString(text)) {
            return true
          }

          return text.toLowerCase().includes(lazySearchQuery.value.toLowerCase())
        })
        .sort((itemA, itemB) => {
          const textA = itemA[props.itemText]
          const textB = itemB[props.itemText]

          if (!isString(textA) || !isString(textB) || !lazySearchQuery.value) {
            return 0
          }

          const lowerSearch = lazySearchQuery.value.toLowerCase()
          const lowerA = textA.toLowerCase()
          const lowerB = textB.toLowerCase()
          const isAStartsWithSearch = lowerA.startsWith(lowerSearch)
          const isBStartsWithSearch = lowerB.startsWith(lowerSearch)

          if (lowerA === lowerB) {
            return 0
          }

          if (isAStartsWithSearch && !isBStartsWithSearch) {
            return -1
          }

          if (!isAStartsWithSearch && isBStartsWithSearch) {
            return 1
          }

          return lowerA > lowerB ? 1 : -1
        }),
    )

    const selectedItemValue = computed(() => lazyValue.value && typeof lazyValue.value === 'object'
      ? (lazyValue.value as Record<string, unknown>)[props.itemValue]
      : lazyValue.value,
    )

    watch(() => props.isActive, (val) => {
      lazyIsActive.value = val
    }, { immediate: true })

    watch(() => props.searchDebounceTimeout, (val) => {
      debouncedEmitUpdateSearch.value.flush()

      debouncedEmitUpdateSearch.value = debounce(emit.bind(null, 'update:searchQuery'), val)
    })

    watch(() => props.searchQuery, (val) => {
      lazySearchQuery.value = val ?? ''
    }, { immediate: true })

    watch(() => props.value, (val) => {
      lazyValue.value = val
    }, { immediate: true })

    watch(lazyValue, (val) => {
      if (val !== props.value) {
        emit('input', val)
      }
    })

    watch([isInputFocused, lazyIsActive], () => {
      if (!isInputFocused.value && !lazyIsActive.value) {
        isInputErrored.value = !(textField.value?.validate(true) ?? true)
      } else {
        isInputErrored.value = false
      }
    })

    watch(lazyIsActive, (val) => {
      emit('update:isActive', val)

      if (val && !props.disableAutoFiltering) {
        innerSearchQuery.value = ''
      }

      textField.value?.resetValidation()
    })

    const debouncedEmitUpdateSearch = ref(debounce(emit.bind(null, 'update:searchQuery'), props.searchDebounceTimeout))

    function handleSelect(index: number) {
      const selectedItem = filteredItems.value[index]
      lazyValue.value = props.returnObject ? selectedItem : selectedItem?.[props.itemValue]

      if (props.hideOnSelect) {
        lazyIsActive.value = false
      }
    }

    function isItemSelected(index: number) {
      return filteredItems.value[index]?.[props.itemValue] === selectedItemValue.value
    }

    function handleActivatorClick(ev: MouseEvent) {
      if (!isClearTextFieldClick(ev)) {
        return
      }

      lazyIsActive.value = true
    }

    expose({
      validate: (...args) => {
        isInputErrored.value = !(textField.value?.validate(...args) ?? true)
      },
      resetValidation: () => {
        isInputErrored.value = false
        textField.value?.resetValidation()
      },
      reset: () => textField.value?.reset(),
    } as Validatable)

    return {
      textField,
      isInputErrored,
      arrowIconColor,
      displayedText,
      isInputFocused,
      lazyIsActive,
      innerSearchQuery,
      lazyValue,
      paddingBreakpoint,
      searchBarStyle,
      filteredItems,
      handleSelect,
      isItemSelected,
      handleActivatorClick,
    }
  },
})
