
import { useFileDialog } from '@vueuse/core'
import { FileType } from '~/core/enums'
import { WEB_ALLOWED_IMAGE_TYPE_LIST } from '~/core/constants'
import { convertHeicToJpeg, compressImage, compressImageWithBreakpoints } from '~/core/functions'
import { defineComponent, ref, useNuxtApp, watch, watchEffect } from '~/bridge'

export default defineComponent({
  name: 'BottomSheetUpload',
  props: {
    galleryAccept: { type: String, default: '*' },
    value: { type: Boolean, default: false },
    /**
     * Позволяет конвертировать все загружаемые heic и heif в jpeg
     * */
    convertHeics: { type: Boolean, default: false },
    /**
     * Позволяет уменьшать размер всех загружаемых изображений
     * */
    compressImages: { type: Boolean, default: false },
  },
  emits: ['upload', 'input'],
  setup(props, { emit, expose }) {
    const lazyValue = ref(false)
    const isProcessing = ref(false)
    const { $captureException, $featureFlag } = useNuxtApp()

    const {
      files: cameraFiles,
      open: cameraOpen,
      reset: cameraReset,
    } = useFileDialog({ accept: 'image/*', capture: 'user' })

    const {
      files: galleryFiles,
      open: galleryOpen,
      reset: galleryReset,
    } = useFileDialog({ accept: props.galleryAccept })

    watch(
      () => props.value,
      (val: boolean) => val ? show() : hide(),
      { immediate: true },
    )

    watchEffect(() => {
      if (cameraFiles && cameraFiles.value?.length) {
        emitUpload(cameraFiles.value)
        cameraReset()
      }
    })

    watchEffect(() => {
      if (galleryFiles && galleryFiles.value?.length) {
        emitUpload(galleryFiles.value)
        galleryReset()
      }
    })

    function show() {
      lazyValue.value = true
    }

    function hide() {
      lazyValue.value = false
    }

    function processImages(files: File[]) {
      return Promise.all(files.map(async (file) => {
        if (props.convertHeics && (file.type === FileType.Heic || file.type === FileType.Heif)) {
          return await convertHeicToJpeg(file)
        }

        if (props.compressImages && WEB_ALLOWED_IMAGE_TYPE_LIST.includes(file.type as FileType)) {
          if ($featureFlag('sff_compress_photos')) {
            return await compressImageWithBreakpoints(file)
          }

          return await compressImage(file)
        }

        return file
      }))
    }

    async function emitUpload(val: FileList) {
      let files = Array.from(val)

      try {
        isProcessing.value = true
        files = await processImages(files)
      } catch (e) {
        $captureException(e, {
          method: 'emitUpload',
          component: 'BottomSheetUpload',
        })
      } finally {
        emit('upload', files)
        hide()
        emit('input', false)
        isProcessing.value = false
      }
    }

    function emitInput(val: boolean) {
      emit('input', val)
    }

    expose({
      show,
      hide,
    })

    return {
      isProcessing,
      lazyValue,
      cameraOpen,
      galleryOpen,
      show,
      emitInput,
    }
  },
})
