import { getOnlyNumber } from '~/core/utils/getOnlyNumber'

export const getFormattedPhone = (phone: string, {
  mask = '### ###-##-##',
  phoneCode = '7',
} = {}) => {
  let digits = getOnlyNumber(phone)

  if (digits.startsWith(phoneCode)) {
    digits = digits.slice(phoneCode.length)
  }

  if (mask.replace(/[^#]/g, '').length !== digits.length) {
    return phone
  }

  let index = 0

  const filledMask = mask.split('').map(
    character => character === '#' ? digits[index++] : character,
  ).join('')

  return `+${phoneCode} ${filledMask}`
}
