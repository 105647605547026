
import type { FunctionalComponentOptions } from 'vue'
import { VTextField } from 'vuetify/lib/components'

export default {
  name: 'BaseTextField',
  functional: true,
  props: {
    // TODO: УДАЛИТЬ ПОСЛЕ ОБНОВЛЕНИЯ vuetify
    // Данная фича добавлена во vuetify 2.6.0+ https://github.com/vuetifyjs/vuetify/releases/tag/v2.6.0
    hideSpinButtons: {
      // Убирает стрелочки у input[type="number"]
      type: Boolean,
      default: false,
    },
  },
  render(h, { data, children, props }) {
    return h(
      VTextField,
      {
        ...data,
        props: {
          outlined: true,
        },
        class: [
          'base-text-field',
          {
            'base-text-field_hidden-spin-buttons': props.hideSpinButtons,
          },
          data.class,
        ],
      },
      children,
    )
  },
} as FunctionalComponentOptions
