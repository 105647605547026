
import { addYears, compareDesc, format, isValid, parse, subYears } from 'date-fns'
import { computed, defineComponent, nextTick, PropType, ref, watch } from '~/bridge'
import { VTextField } from '~/types'

export default defineComponent({
  name: 'DateTextField',
  props: {
    value: {
      type: String,
      default: '',
    },
    /**
     * В формате может отсутствовать день, если необходимо вводить только месяц
     * */
    format: {
      type: String,
      default: 'yyyy-MM-dd',
    },
    min: {
      type: Date,
      default: () => subYears(new Date(), 100),
    },
    max: {
      type: Date,
      default: () => addYears(new Date(), 100),
    },
    /**
     * Правила прогоняются для даты в исходном формате, переданном через prop format
     * */
    rules: { type: Array as PropType<Array<(v: DateISO) => string | boolean>>, default: () => [] },
    label: {
      type: String,
      default: 'Дата',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit, expose }) {
    const textField = ref<VTextField & { valid: boolean } | null>(null)
    const lazyValue = ref<string | null>('')
    const inputRules = [
      (v: string | null) => v?.length !== fullDateLength.value || validateDate(v ?? '') || 'Дата указана неверно',
      (v: string | null) => v?.length === 0 || v?.length === fullDateLength.value || 'Дата указана не полностью',
      (v: string | null) => !props.required || validateDate(v ?? '') || 'Обязательное поле',
      ...props.rules?.map((rule: Function) => () => rule(formattedDate.value)),
    ]

    const onlyMonth = computed(() => !props.format?.toLowerCase().includes('dd'))
    const fullDateLength = computed(() => onlyMonth.value ? 7 : 10)
    const lazyValueFormat = computed(() => onlyMonth.value ? 'MM.yyyy' : 'dd.MM.yyyy')

    const placeholderText = computed(() => {
      const placeholder = onlyMonth.value ? 'мм.гггг' : 'дд.мм.гггг'

      return lazyValue.value + placeholder.slice(lazyValue.value?.length ?? 0)
    })

    const formattedDate = computed(() => {
      if (lazyValue.value?.length !== fullDateLength.value) {
        return ''
      }

      const date = parse(lazyValue.value, lazyValueFormat.value, new Date())

      if (!isValid(date)) {
        return ''
      }

      return format(date, props.format)
    })

    watch(() => [props.disabled, props.required], () => {
      textField.value?.validate(true)
    })

    watch(() => props.value, (val) => {
      if (!val) {
        // NOTE: Когда удаляется символ из валидной даты выбрасывается input с пустым value,
        // условие нужно чтобы дата не стерлась полностью
        if (!textField.value?.$data.isFocused) {
          setDate({ day: '', month: '', year: '' })
        }

        return
      }

      const date = parse(val, props.format, new Date())

      if (!isValid(date)) {
        return
      }

      setDate({
        day: !onlyMonth.value ? date.getDate().toString().padStart(2, '0') : '',
        month: (date.getMonth() + 1).toString().padStart(2, '0'),
        year: date.getFullYear().toString(),
      })
    }, { immediate: true })

    function validateDate(value: string) {
      const date = parse(value, lazyValueFormat.value, new Date())

      return isValid(date) && compareDesc(props.min, date) === 1 && compareDesc(date, props.max) === 1
    }

    function setDate(date: { day?: string, month: string, year: string }) {
      let result = date.year ? '.' + date.year : ''

      if (date.month) {
        result = (onlyMonth.value ? '' : '.') + date.month + result
      } else {
        result = ''
      }

      if (!onlyMonth.value) {
        result = date.day ? date.day + result : ''
      }

      lazyValue.value = result
    }

    function emitInput() {
      if (formattedDate.value !== props.value) {
        emit('input', formattedDate.value)
      }
    }

    async function handleInput(val: string | null) {
      if (val === null) {
        return
      }

      val = val.replaceAll(/[^0-9]/g, '')
      await nextTick()

      const dateParts = onlyMonth.value
        ? {
            day: '',
            month: val.slice(0, 2),
            year: val.slice(2, 6),
          }
        : {
            day: val.slice(0, 2),
            month: val.slice(2, 4),
            year: val.slice(4, 8),
          }

      dateParts.day = /^0[1-9]?|^[12][0-9]?|^3[01]?/.exec(dateParts.day)?.[0] ?? ''
      dateParts.month = dateParts.day.length === 2 || onlyMonth.value
        ? /^0[1-9]?|^1[012]?/.exec(dateParts.month)?.[0] ?? ''
        : ''
      dateParts.year = dateParts.month.length === 2
        ? /(^(19|2\d)\d{0,2})|^[12]/.exec(dateParts.year)?.[0] ?? ''
        : ''

      setDate(dateParts)
      emitInput()
    }

    expose({
      reset: () => {
        lazyValue.value = ''
        emitInput()
      },
      validate: () => {
        textField.value?.validate(true)
      },
      valid: computed(() => textField.value?.valid),
    })

    return {
      textField,
      lazyValue,
      placeholderText,
      inputRules,
      handleInput,
    }
  },
})
