
import type { FunctionalComponentOptions } from 'vue'
import { VSheet } from 'vuetify/lib'

export default {
  functional: true,
  render(h, { data, children }) {
    return h(
      VSheet,
      {
        ...data,
        props: {
          height: data.attrs?.height ?? 56,
        },
        class: [
          'py-4 px-2',
          {
            ...data.class,
          },
        ],
      },
      children,
    )
  },
} as FunctionalComponentOptions
