import Cookies from 'js-cookie'
import { masterClient } from '~/core/utils/clients/masterClient'
import { INFO_URL } from '~/core/constants'
import { CookieKey } from '~/core/enums'
import { getFamilyProfileUuidHeader } from '~/core/functions/client/getFamilyProfileUuidHeader'
import { medcardClient } from '~/core/utils/clients/medcardClient'
import { profileClient } from '~/core/utils/clients/profileClient'
import { infoClient } from '~/core/utils/clients/infoClient'
import { MedcardStatus } from '~/features/Profile/enums'
import { MedcardDocumentType } from '~/features/Medcard/enums'
import { MedcardFolderDto } from '~/features/Medcard/api/folders.api'

const RESOURCE = '/medcard'

export type MedcardInfoDto = {
  medcardStatus: MedcardStatus.NeedConfirmation
  hasDocumentFromMis: boolean
} | {
  medcardStatus: MedcardStatus.Protected
  documentsCount: number | null
} | {
  medcardStatus: MedcardStatus.Expired
}

export type MedcardDocumentGroupListItemDto = {
  id: number
  documentInfo: string
  documentType: MedcardDocumentType
  dtGenerated: DateTimeISO | null
  dtVisit: DateTimeISO | null
  isNew: boolean
}

export type MedcardDocumentDto = {
  id: number
  iv: string
  file: string
  preview: string
  sortIndex: number
  editable: boolean
}

export type MedcardDocumentGroupDto =
  Pick<MedcardDocumentGroupListItemDto, 'id' | 'documentInfo' | 'documentType' | 'dtGenerated' | 'dtVisit'>
  & {
    specialityName: string
    editable: boolean
    documents: MedcardDocumentDto[]
    specialityIdProdoctorov: number
    isPartiallyEditable: boolean
    recommendationsDetail: {
      id: number
      iv: string
      referralsForConsultationsSpecialitySet: number[]
      referralsForAnalysis: string
      doctorRecommendations: string
      doctorFio: string
    } | null
    folders: Array<Pick<MedcardFolderDto, 'id' | 'name'>>
    comment: string
    iv: string
  }

export type MedcardApiSubmitDocumentGroupPayload = {
  groupId: number
  dtVisit?: DateISO
  documentInfo?: string
  specialityIdProdoctorov?: number | null
  appointmentId?: number
  prepareAppointmentId?: number | null
  folderIds: number[]
  comment?: string
  iv?: string
}

export type MedcardApiEditDocumentGroupPayload = {
  groupId: number
  dtVisit?: DateISO
  documentInfo?: string
  specialityIdProdoctorov?: number | null
  documentIds?: number[]
  folderIds?: number[]
  comment?: string
  iv?: string
}

export type MtlinkSessionItemDto = {
  id: number
  dtExpirationUtc: DateTimeISO
  dtCreatedUtc: DateTimeISO
  forTelemed: boolean
}

export type UnconfirmedDocumentDto = {
  id: number
  specialityName: string
  lpuName: string
  lpuAddress: string
  dtCreated: DateTimeISO
  expireInDays: number
}

export type ExternalDocumentGroup = {
  documentType: MedcardDocumentType
  documentInfo: string
  dtGenerated: DateTimeISO | null
  dtVisit: DateTimeISO | null
  familyProfileUuid: string
}

export type MedcardStateDto = {
  totalDocumentGroupsCount: number
  familyProfilesStatistics: Array<{
    familyProfileUuid: string
    documentGroupsCount: number
  }>,
  externalDocumentGroup: ExternalDocumentGroup | null
}

export const MedcardApi = {
  getState() {
    return infoClient.get<MedcardStateDto>(`${RESOURCE}/state/`, { camelize: true })
  },
  /** @deprecated */
  getMedcardInfo() {
    return medcardClient.get<MedcardInfoDto>(`${RESOURCE}/info/`, { camelize: true })
  },
  getAll() {
    return medcardClient.get<MedcardDocumentGroupListItemDto[]>(
      `${RESOURCE}/document_groups/`,
      { camelize: true },
    )
  },
  get(id: number | string) {
    return medcardClient.get<MedcardDocumentGroupDto>(
      `${RESOURCE}/document_groups/${id}/`,
      { camelize: true },
    )
  },
  delete(id: number | string) {
    return masterClient.delete(`${RESOURCE}/document_groups/${id}/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
      },
      camelize: true,
    })
  },
  getKey(id: number | string) {
    return masterClient.get<{ key: string }>(`${RESOURCE}/document_groups/${id}/key/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
      },
      camelize: true,
    })
  },
  getDocument(urlPath: string) {
    return medcardClient.get<ArrayBuffer>(`${INFO_URL}${urlPath}`, {
      responseType: 'arraybuffer',
      camelize: true,
    })
  },
  /** @deprecated */
  getDocumentsCount() {
    return medcardClient.get(`${RESOURCE}/family_profile_documents_count/`, { camelize: true })
  },
  /** @deprecated */
  getMedcardIsFull() {
    return medcardClient.get<boolean>(`${RESOURCE}/is_full/`, { camelize: true })
  },
  getMedcardToken() {
    return masterClient.post<{ masterMedcardToken: string }>(
      `${RESOURCE}/token/`,
      {},
      { camelize: true },
    )
  },
  createMedcardItemAttachmentGroup(documentType: MedcardDocumentType) {
    return medcardClient.post<{ id: number, documentType: MedcardDocumentType }>(
      `${RESOURCE}/document_groups/drafts/`,
      { documentType },
      { camelize: true },
    )
  },
  addEncryptedMedcardItemAttachmentGroupItem(groupId: number, file: string, iv: string, index: number) {
    return masterClient.post<{ id: number }>(
      `${RESOURCE}/document_groups/${groupId}/encoded_documents/`,
      { file, iv, sortIndex: index },
      {
        headers: {
          'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
        },
        camelize: true,
      },
    )
  },
  getEncryptionKey(id: number) {
    return masterClient.post<{ key: string }>(`${RESOURCE}/document_groups/${id}/key/create/`, {}, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
      },
      camelize: true,
    })
  },
  deleteMedcardItemAttachmentGroupItem({ groupId, fileId }: { groupId: number, fileId: number }) {
    return medcardClient.delete<{ id: number }>(
      `${RESOURCE}/document_groups/${groupId}/documents/${fileId}/`,
      { camelize: true },
    )
  },
  submitDocumentGroup(payload: MedcardApiSubmitDocumentGroupPayload) {
    const { groupId, ...restPayload } = payload

    return medcardClient.patch(
      `${RESOURCE}/document_groups/drafts/${groupId}/`,
      restPayload,
      { camelize: true },
    )
  },
  editDocumentGroup(payload: MedcardApiEditDocumentGroupPayload) {
    const { groupId, ...restPayload } = payload

    return medcardClient.post(
      `${RESOURCE}/document_groups/${groupId}/change/`,
      restPayload,
      { camelize: true },
    )
  },
  getMtlinkSessions() {
    return masterClient.get<MtlinkSessionItemDto[]>('/mt_link/sessions/', {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
        ...getFamilyProfileUuidHeader(),
      },
      camelize: true,
    })
  },
  getUnconfirmedDocuments() {
    return infoClient.get<UnconfirmedDocumentDto[]>(
      `${RESOURCE}/unconfirmed_document_groups/`,
      { camelize: true },
    )
  },
  getUnconfirmedDocumentName(id: number) {
    return profileClient.get<{ patientForename: string }>(
      `${RESOURCE}/unconfirmed_document_groups/${id}/name/`,
      { camelize: true },
    )
  },
  confirmUnconfirmedDocument(id: number, patientBirthday: string) {
    return masterClient.post<{ documentGroupId: number, familyProfileUuid: string }>(
      `${RESOURCE}/unconfirmed_document_groups/${id}/confirm/`,
      { patientBirthday },
      {
        headers: {
          'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
        },
        camelize: true,
      },
    )
  },
  reset(email: string) {
    return masterClient.delete(`${RESOURCE}/reset/`, {
      params: { email },
      camelize: true,
    })
  },
  declineUnconfirmedDocument(id: number) {
    return masterClient.post(
      `${RESOURCE}/unconfirmed_document_groups/${id}/decline/`,
      {},
      { camelize: true },
    )
  },
}
