import { render, staticRenderFns } from "./CustomInfoAlert.vue?vue&type=template&id=2aa27584&scoped=true"
import script from "./CustomInfoAlert.vue?vue&type=script&lang=ts"
export * from "./CustomInfoAlert.vue?vue&type=script&lang=ts"
import style0 from "./CustomInfoAlert.vue?vue&type=style&index=0&id=2aa27584&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa27584",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default})
