import { masterClient } from '~/core/utils/clients/masterClient'

const RESOURCE = '/feature_flags/'

export default {
  get() {
    // NOTE: Здесь не нужен camelize
    return masterClient.get<Record<string, boolean>>(RESOURCE, { camelize: false })
  },
}
