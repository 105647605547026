import getPdClient from '~/core/utils/clients/getPdClient'

const RESOURCE = '/medtochka'

type UserTypeDto = {
  userType: string | null
}

export default function UserTypeApi(pdUrl?: string) {
  const pdClient = getPdClient(pdUrl)

  return {
    get() {
      return pdClient.get<UserTypeDto>(
      `${RESOURCE}/users/user_type/`,
      { withCredentials: true, camelize: true },
      )
    },
    logOut() {
      return pdClient.post(`${RESOURCE}/sso/logout/`, null, { withCredentials: true, camelize: true })
    },
  }
}
