
import { NuxtError } from '@nuxt/types'
import { storeToRefs } from 'pinia'
import { globalErrors, serverErrors } from '~/core/constants/errors'
import { CORE_MUTATIONS, DIALOG_MESSAGE as CORE_DIALOG } from '~/core/constants'
import { HttpStatusCode } from '~/core/enums'
import { UserTypeApi } from '~/core/api'
import { ymGoal } from '~/core/utils/yandexReachGoal'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  PropType,
  useNuxtApp,
  useRoute,
  useRouter,
} from '~/bridge'
import useCisStore from '~/stores/useCisStore'

type TError = Omit<NuxtError, 'statusCode'> &
  { statusCode: HttpStatusCode | typeof serverErrors.userDoctor.response.status | undefined }

export default defineComponent({
  name: 'Error',
  props: {
    error: { type: Object as PropType<TError>, default: () => ({}) },
  },
  setup(props) {
    const { $store, $dialog } = useNuxtApp()
    const $route = useRoute()
    const $router = useRouter()
    const { pdUrl } = storeToRefs(useCisStore())

    const errorObj = computed(() => {
      const { statusCode: code } = props.error

      if (code) {
        return globalErrors[code.toString().startsWith('5') ? HttpStatusCode.InternalServerError : code]
      }

      return globalErrors[HttpStatusCode.InternalServerError]
    })

    const shouldShowActionButton = computed(() =>
      Boolean(errorObj.value.buttonText) && !($route.name === 'index' && errorObj.value.buttonText === 'На главную'),
    )

    onBeforeMount(() => {
      $store.commit(CORE_MUTATIONS.SET_IS_LAYOUT_ERROR, true)

      if (errorObj.value.code === HttpStatusCode.NotFound) {
        ymGoal('click404')
      }
    })

    onBeforeUnmount(() => {
      $store.commit(CORE_MUTATIONS.SET_IS_LAYOUT_ERROR, false)
    })

    async function handleButtonClick() {
      switch (errorObj.value.code) {
        case HttpStatusCode.Unauthorized:
          ymGoal('unauthorized')
          $router.push({ name: 'authorization' })

          return
        case serverErrors.userDoctor.response.status: {
          ymGoal('clickNotPatient')
          const isResolved = await $dialog.open({ ...CORE_DIALOG.LPU_LOGGED_ERROR })

          if (isResolved) {
            await logOutFromPD()
          }

          return
        }
        case HttpStatusCode.InternalServerError:
          ymGoal('internalServerError')
      }

      $router.push({ name: 'index' })
    }

    async function logOutFromPD() {
      try {
        await UserTypeApi(pdUrl.value).logOut()
        window.location.reload()
      } catch (error) {
        $dialog.open({ ...CORE_DIALOG.LOGOUT_ERROR })
      }
    }

    return {
      errorObj,
      shouldShowActionButton,
      handleButtonClick,
    }
  },
})
