import { Context } from '@nuxt/types'
import { isString, once } from 'lodash-es'
import Cookies from 'js-cookie'
import isNumeric from 'validator/lib/isNumeric'
import { AxiosResponse } from 'axios'
import { serverErrors } from '~/core/constants/errors'
import { CookieKey, HttpStatusCode } from '~/core/enums'
import { UserType } from '~/core/enums/userType'
import { CORE_ACTIONS, CORE_MUTATIONS, FOREIGN_MT_URL, MEDCARD_MUTATIONS, PROFILE_MUTATIONS } from '~/core/constants'
import { AnalyticsApi, AuthApi, UserTypeApi } from '~/core/api'
import { isAppSupported, isDeeplinkOnlyPage, sendOpenMtAnalytics } from '~/core/functions'
import { useAppLink } from '~/core/composables'
import { ReservedQueryKey } from '~/core/enums/routing'
import { ymUserParams } from '~/core/utils/yandexReachGoal'
import { MeDto } from '~/core/api/auth.api'
import { AgreementStatus, MedcardStatus } from '~/features/Profile/enums'
import { NotificationsApi } from '~/features/Notifications/api'
import { CLINIC_HASH_SESSION_STORAGE_KEY, CLINIC_SOURCE_SESSION_STORAGE_KEY } from '~/features/Clinics/constants'
import { ReadNotificationPayload } from '~/features/Notifications/api/notifications.api'
import { REDIRECT_TO_MEDCARD_WITH_DOCUMENT_MIS } from '~/features/Appointments/constants/sessionStorage'
import { AuthApi as AuthorizationAuthApi } from '~/features/Authorization/api'
import { Country } from '~/features/Authorization/api/auth.api'

let setYmUserParams = once(ymUserParams)

const resetYmUserParamsFunction = () => {
  setYmUserParams = once(ymUserParams)
}

const checkUserType = once(async (pdUrl?: string) => {
  try {
    const { data } = await UserTypeApi(pdUrl).get()

    if (data.userType && data.userType === UserType.Doctor) {
      throw serverErrors.userDoctor
    }

    if (data.userType && data.userType === UserType.Lpu) {
      throw serverErrors.userLpu
    }
  } catch (e) {
    if (e.response?.status === serverErrors.userDoctor.response.status) {
      throw e
    }
  }
})

const readNotification = async (ctx: Context) => {
  try {
    const {
      [ReservedQueryKey.NotificationId]: id,
      [ReservedQueryKey.IdempotencyKey]: key,
    } = ctx.query

    if (!id && !key) {
      return
    }

    const payload: ReadNotificationPayload = {
      notificationId: isString(id) ? Number(id) : undefined,
      idempotencyKey: !id && isString(key) ? key : undefined,
    }

    await NotificationsApi.markAsRead(payload)
  } catch (e) {
    ctx.$captureException(e, {
      method: 'readNotification',
      component: 'pluginAuth',
    })
  }
}

const sendReferralId = async (id: unknown, captureException: Context['$captureException']) => {
  if (!isString(id) || !isNumeric(id)) {
    return
  }

  try {
    await AnalyticsApi.sendReferralId(Number(id))
  } catch (e) {
    if (e.response?.status !== HttpStatusCode.NotFound) {
      captureException(e, {
        method: 'sendReferralId',
        component: 'pluginAuth',
      })
    }
  }
}

const isTokenWasCleared = (from: Context['from'], to: Context['route']) => {
  // Условие выполняется после удаления токена авторизации (token) из query
  return from.path === to.path && from.query.token && !to.query.token
}

const clearQuery = (ctx: Context) => {
  const queryToClear: string[] = []

  Object.values(ReservedQueryKey).forEach((key) => {
    if (ctx.query[key]) {
      queryToClear.push(key)
    }
  })

  if (queryToClear.length) {
    return ctx.redirect({
      path: ctx.route.path,
      query: {
        ...ctx.query,
        ...queryToClear.reduce((res, param) => ({ ...res, [param]: undefined }), {}),
      },
    })
  }
}

export default async function(context: Context) {
  const {
    from,
    route,
    store,
    error,
    redirect,
    $captureException,
    $device,
    $sentry,
    $featureFlag,
  } = context

  if (route.name?.includes('dental-articles')) {
    return
  }

  if (!$featureFlag('sff_cis') && process.env.domain === 'CIS') {
    redirect(FOREIGN_MT_URL)
  }

  if (isTokenWasCleared(from, route)) {
    return
  }

  try {
    if (isDeeplinkOnlyPage(route, $device, $sentry)) {
      return
    }

    if (route.query[ReservedQueryKey.OpenApp] && $device.isMobileOrTablet && isAppSupported({ $device, $sentry })) {
      const { deepLink } = useAppLink($device, route)
      location.href = deepLink.value
    }

    let me: AxiosResponse<MeDto> | undefined
    let pdUrl: string | undefined
    let requestError: unknown

    try {
      me = await AuthApi.get(route.query[ReservedQueryKey.Token] as string)

      if ($featureFlag('sff_cis') && me.data.country) {
        pdUrl = `https://${me.data.country.pdDomain}`
      }
    } catch (error) {
      requestError = error

      if ($featureFlag('sff_cis')) {
        const { data } = await AuthorizationAuthApi.getCountries()

        let selectedCountry: Country | undefined

        if (route.query[ReservedQueryKey.PreselectedCountry]) {
          selectedCountry = data.find(item => item.iso === route.query[ReservedQueryKey.PreselectedCountry])
        } else if (process.env.domain === 'RU') {
          selectedCountry = data.find(item => item.iso === 'RU')
        } else {
          selectedCountry = data[0]
        }

        if (selectedCountry) {
          pdUrl = `https://${selectedCountry.pdDomain}`
        }

        store.commit(CORE_MUTATIONS.SET_COUNTRIES, data)
      }
    }

    await checkUserType(pdUrl)

    if (!me) {
      throw requestError
    }

    const {
      phone = '',
      townName = '',
      townKey = '',
      townId = -1,
      townConfirmed = false,
      rulesAgreementStatus = AgreementStatus.Default,
      medcardStatus = MedcardStatus.Protected,
      unreadNotificationsCount = 0,
      medcardActiveSessionsCount = 0,
      id = undefined,
      country = null,
    } = me.data

    store.commit(PROFILE_MUTATIONS.SET_PHONE, phone)
    store.commit(PROFILE_MUTATIONS.SET_TOWN, { town: townName, townKey, townId })
    store.commit(PROFILE_MUTATIONS.SET_TOWN_CONFIRMED, townConfirmed)
    store.commit(PROFILE_MUTATIONS.SET_AGREEMENT_STATUS, rulesAgreementStatus)
    store.commit(PROFILE_MUTATIONS.SET_UNREAD_NOTIFICATIONS_COUNT, unreadNotificationsCount)
    store.commit(PROFILE_MUTATIONS.SET_MEDCARD_ACTIVE_SESSIONS_COUNT, medcardActiveSessionsCount)
    store.commit(MEDCARD_MUTATIONS.SET_MEDCARD_STATUS, medcardStatus)

    if ($featureFlag('sff_cis')) {
      store.commit(PROFILE_MUTATIONS.SET_COUNTRY, country)
    }

    store.dispatch(CORE_ACTIONS.FETCH_SPECIALITIES)

    setYmUserParams({
      UserID: id,
      ReferralDoctorID: route.query[ReservedQueryKey.ReferralDoctorId] as string | number | undefined,
    })

    if (route.name === 'authorization') {
      return redirect('/')
    }

    if (route.query[ReservedQueryKey.MedcardToken]) {
      Cookies.set(CookieKey.MedcardToken, route.query[ReservedQueryKey.MedcardToken], { expires: 14 })
    }

    readNotification(context)

    if (route.query[ReservedQueryKey.ReferralDoctorId]) {
      sendReferralId(route.query[ReservedQueryKey.ReferralDoctorId], $captureException)
    }

    if (route.query[ReservedQueryKey.LpuHash]) {
      sessionStorage.setItem(CLINIC_HASH_SESSION_STORAGE_KEY, route.query[ReservedQueryKey.LpuHash].toString())
    }

    if (route.query[ReservedQueryKey.LpuSource]) {
      sessionStorage.setItem(CLINIC_SOURCE_SESSION_STORAGE_KEY, route.query[ReservedQueryKey.LpuSource].toString())
    }

    await sendOpenMtAnalytics($captureException)

    clearQuery(context)

    if ($featureFlag('sff_medcard_show_document_from_mis')) {
      // NOTE: редирект с главной на медкарту нужно делать только когда пользователь сразу зашел
      // на главную (или после авторизации). И не делать когда он перешел на главную с других страниц
      if ((from.name === 'index' || from.name === 'authorization') &&
        sessionStorage.getItem(REDIRECT_TO_MEDCARD_WITH_DOCUMENT_MIS) !== 'false') {
        sessionStorage.setItem(REDIRECT_TO_MEDCARD_WITH_DOCUMENT_MIS, 'true')
      } else if (sessionStorage.getItem(REDIRECT_TO_MEDCARD_WITH_DOCUMENT_MIS) !== 'true') {
        sessionStorage.setItem(REDIRECT_TO_MEDCARD_WITH_DOCUMENT_MIS, 'false')
      }
    }
  } catch ({ response, message }) {
    resetYmUserParamsFunction()

    if (route.name === 'authorization' && response?.status === 401) {
      Cookies.remove(CookieKey.MedcardToken)

      return
    }

    if (response?.status === 401) {
      Cookies.remove(CookieKey.MedcardToken)

      let query = '?next=' + route.fullPath
        .replaceAll(`${ReservedQueryKey.PreselectedCountry}=${route.query[ReservedQueryKey.PreselectedCountry]}`, '')
        // NOTE: Escape символа "&", чтобы остальные query параметры распознались не отдельно, а как часть next
        .replaceAll('&', '%26')

      if (route.query[ReservedQueryKey.PreselectedCountry]) {
        query += `&${ReservedQueryKey.PreselectedCountry}=${route.query[ReservedQueryKey.PreselectedCountry]}`
      }

      // NOTE: Escape символа "&", чтобы остальные query параметры распознались не отдельно, а как часть next
      return redirect(`/authorization/${query}`)
    }

    error({ statusCode: response?.status, message })
  }
}

