
import { computed, defineComponent, useNuxtApp } from '~/bridge'

export default defineComponent({
  name: 'MainSnackbar',
  setup() {
    const { $store } = useNuxtApp()

    const isActive = computed(() => $store.state['main-snackbar'].isActive)
    const text = computed(() => $store.state['main-snackbar'].text)
    const cancelText = computed(() => $store.state['main-snackbar'].cancelText)
    const timeout = computed(() => $store.state['main-snackbar'].timeout)

    function close() {
      $store.commit('main-snackbar/close')
    }

    function cancelHandler() {
      if (isActive.value && !cancelText.value) {
        throw new Error('Не передан cancelable.')
      }

      $store.state['main-snackbar'].cancelHandler()
      $store.commit('main-snackbar/close')
    }

    return {
      isActive,
      text,
      cancelText,
      timeout,
      close,
      cancelHandler,
    }
  },
})
