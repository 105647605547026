import { render, staticRenderFns } from "./NewDocumentToFolderPopup.vue?vue&type=template&id=20a901f8"
import script from "./NewDocumentToFolderPopup.vue?vue&type=script&lang=ts"
export * from "./NewDocumentToFolderPopup.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseContainer: require('/builds/private/medtochka-frontend/core/components/Base/BaseContainer.vue').default,BaseCheckbox: require('/builds/private/medtochka-frontend/core/components/Base/BaseCheckbox.vue').default,BaseBtn: require('/builds/private/medtochka-frontend/core/components/Base/BaseBtn.vue').default,BaseFullscreenDialog: require('/builds/private/medtochka-frontend/core/components/Base/BaseFullscreenDialog.vue').default})
