
import { promiseTimeout, useIntersectionObserver } from '@vueuse/core'
import { debounce } from 'lodash-es'
import { PDFDocumentProxy, PDFPageProxy, RenderTask } from 'pdfjs-dist'
import {
  nextTick,
  onBeforeUnmount,
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
} from '~/bridge'

export default defineComponent({
  name: 'PDFViewerPage',
  props: {
    pdf: {
      type: Object as PropType<PDFDocumentProxy>,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    zoomScale: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const isIntersecting = ref(false)
    const container = ref<HTMLDivElement | null>(null)
    const canvas = ref<null | HTMLCanvasElement>(null)
    const isRendered = ref(false)
    const page = ref<null | PDFPageProxy>(null)
    const currentRenderTask = ref<null | RenderTask>(null)
    const stopIntersection = ref<null | Function>(null)

    onBeforeUnmount(() => {
      page.value?.cleanup()
      stopIntersection.value?.()
    })

    onMounted(() => {
      setTimeout(() => {
        const { stop } = useIntersectionObserver(
          container,
          onIntersect,
          {
            threshold: 0,
          },
        )

        stopIntersection.value = stop
      }, 500)
    })

    watch(isIntersecting, async (val) => {
      if (val) {
        // При быстром скролле не нужно рендерить страницы непоказанные пользователю
        await promiseTimeout(250)

        if (!isIntersecting.value) {
          return
        }

        render()
      } else {
        isRendered.value = false
        page.value?.cleanup(true)
      }
    })

    watch(() => props.zoomScale, async () => {
      isRendered.value = false
      await nextTick()

      if (!isIntersecting.value) {
        return
      }

      // При частом зуме не нужно сразу рендерить
      debouncedRender(true)
    })

    const debouncedRender = debounce(render, 300)

    async function render(force = false) {
      if (isRendered.value && !force) {
        return
      }

      currentRenderTask.value?.cancel()
      page.value?.cleanup(true)

      page.value = await props.pdf.getPage(props.page)

      const viewport = page.value.getViewport({
        scale: props.zoomScale,
      })

      const outputScale = window.devicePixelRatio || 1

      if (!canvas.value) {
        return
      }

      const context = canvas.value.getContext('2d')!

      canvas.value.width = Math.floor(viewport.width * outputScale)
      canvas.value.height = Math.floor(viewport.height * outputScale)

      const transform = outputScale !== 1
        ? [outputScale, 0, 0, outputScale, 0, 0]
        : undefined
      currentRenderTask.value = page.value.render({
        canvasContext: context,
        transform,
        viewport,
        background: '#FFFFFF',
      })

      await currentRenderTask.value

      isRendered.value = true
    }

    function onIntersect(entries: IntersectionObserverEntry[]) {
      isIntersecting.value = entries[0].isIntersecting
    }

    return {
      isRendered,
      canvas,
      isIntersecting,
      container,
    }
  },
})
