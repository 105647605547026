
import BaseSheetItem from '~/core/components/Base/BaseSheetItem.vue'
import { VCheckbox } from '~/types'
import { defineComponent, ref } from '~/bridge'

export default defineComponent({
  name: 'BaseCheckbox',
  components: { BaseSheetItem },
  inheritAttrs: false,
  model: { prop: 'inputValue', event: 'change' },
  props: {
    inputValue: { type: [Boolean, Array], default: null },
  },
  emits: ['change'],
  setup(props, { expose }) {
    const checkbox = ref<VCheckbox | null>(null)

    /** @public */
    function reset() {
      checkbox.value?.reset()
    }

    /** @public */
    function resetValidation() {
      checkbox.value?.resetValidation()
    }

    /** @public */
    function validate(force: boolean) {
      return checkbox.value?.validate(force)
    }

    expose({
      reset,
      resetValidation,
      validate,
    })

    return {
      checkbox,
    }
  },
})
