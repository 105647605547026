
import { onKeyStroke } from '@vueuse/core'
import { throttle } from 'lodash-es'
import { saveAs } from 'file-saver'
import { printFile } from '~/core/functions'
import { uuidv4 } from '~/core/utils/uuidv4'
import { formatDate } from '~/core/utils/formatDate'
import { computed, defineComponent, nextTick, PropType, ref, useNuxtApp, watch } from '~/bridge'
import FullscreenGalleryToolbarBtn from './FullscreenGalleryToolbarBtn.vue'
import { GALLERY_TOOLBAR_HEIGHT } from './constants'
import { useMultipleTouchActive } from './composables'
import { GalleryContentExposed, GalleryFile, RegisteredGalleryItemPayload } from './types'
import FullscreenGalleryItem from './FullscreenGalleryItem.vue'

export default defineComponent({
  name: 'FullscreenGalleryContent',
  components: { FullscreenGalleryToolbarBtn, FullscreenGalleryItem },
  props: {
    files: {
      type: Array as PropType<GalleryFile[]>,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
    printable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    comparable: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  emits: ['hide', 'delete', 'document:open', 'update:index', 'compare'],
  setup(props, { emit, expose }) {
    let isDocumentsUpdate = false
    const oldFiles = ref<GalleryFile[]>([])
    const registeredGalleryItems = ref<Array<RegisteredGalleryItemPayload & { index: number }>>([])
    const fileIndex = ref(props.index)
    const { $device } = useNuxtApp()
    const isMultipleTouch = useMultipleTouchActive()
    const isFocused = ref(false)
    const handleClickOutside = () => {
      isFocused.value = false
    }

    const innerFilesSources = computed(() => oldFiles.value.map(({ source }) => ({
      getSource: source,
      uuid: uuidv4(),
    })))

    const currentGalleryItemIndex = computed(() =>
      registeredGalleryItems.value.findIndex(({ index }) => index === fileIndex.value),
    )

    const currentGalleryItem = computed(() => registeredGalleryItems.value[currentGalleryItemIndex.value] ?? null)
    const isCurrentGalleryItemLoaded = computed(() => Boolean(currentGalleryItem.value))
    const isLeftArrowShow = computed(() => props.index > 0)
    const isRightArrowShow = computed(() => props.index < props.files.length - 1)

    const fileName = computed(() => props.files[fileIndex.value]?.name ?? '')
    const fileDate = computed(() =>
      props.files[fileIndex.value]?.date
        ? formatDate(props.files[fileIndex.value].date!, 'dd.MM.yyyy')
        : '',
    )

    watch(() => props.index, (val) => {
      if (!isDocumentsUpdate && oldFiles.value.length === props.files?.length) {
        fileIndex.value = val
      }
    })

    watch(fileIndex, (val) => {
      emit('update:index', val)
    })

    watch(() => props.files, (val) => {
      if (innerFilesSources.value.length > 0 &&
          oldFiles.value[fileIndex.value].id === val[props.index].id) {
        isDocumentsUpdate = true

        return
      }

      updateDocuments()
    }, { immediate: true, deep: true })

    async function updateDocuments() {
      oldFiles.value = [...props.files]
      registeredGalleryItems.value = []

      fileIndex.value = props.index
      isDocumentsUpdate = false
      await nextTick()
    }

    function handleTouch(direction: 'left' | 'right') {
      if (isMultipleTouch.value ||
          (isCurrentGalleryItemLoaded.value && currentGalleryItem.value.isZoomApplied())
      ) {
        return
      }

      if (direction === 'left') {
        prevFile()

        return
      }

      nextFile()
    }

    function zoom(zoomFactor: number) {
      if (!isCurrentGalleryItemLoaded.value) {
        return
      }

      currentGalleryItem.value.zoom(zoomFactor)
    }

    function downloadCurrentFile() {
      if (!isCurrentGalleryItemLoaded.value) {
        return
      }

      const currentFile = currentGalleryItem.value.file

      saveAs(currentFile, currentFile.name)
    }

    function printCurrentFile() {
      if (!isCurrentGalleryItemLoaded.value) {
        return
      }

      const currentFile = currentGalleryItem.value.file

      printFile(currentFile)
    }

    const isArrowsShown = computed(() => !$device.isMobile)

    function registerGalleryItem(payload: RegisteredGalleryItemPayload, index: number) {
      emit('document:open', index)
      registeredGalleryItems.value.push({ ...payload, index })
    }

    const changeIndex = throttle((val: number) => {
      fileIndex.value += val
    }, 100)

    async function nextFile() {
      if (isDocumentsUpdate) {
        await updateDocuments()
      }

      if (fileIndex.value === innerFilesSources.value.length - 1) {
        return
      }

      changeIndex(1)
    }

    async function prevFile() {
      if (isDocumentsUpdate) {
        await updateDocuments()
      }

      if (fileIndex.value === 0) {
        return
      }

      changeIndex(-1)
    }

    onKeyStroke('ArrowRight', (e) => {
      if (!isFocused.value) {
        return
      }

      e.preventDefault()
      nextFile()
    }, { eventName: 'keydown' })

    onKeyStroke('ArrowLeft', (e) => {
      if (!isFocused.value) {
        return
      }

      e.preventDefault()
      prevFile()
    }, { eventName: 'keydown' })

    function handleDeleteFile() {
      if (innerFilesSources.value.length === 1) {
        emit('hide')
      }

      const deleteIndex = fileIndex.value

      if (fileIndex.value === innerFilesSources.value.length - 1) {
        fileIndex.value--
      } else {
        fileIndex.value++
      }

      innerFilesSources.value.splice(deleteIndex, 1)
      emit('delete', deleteIndex)
    }

    function handleCompare() {
      emit('compare')
    }

    function focus() {
      isFocused.value = true
    }

    function blur() {
      isFocused.value = false
    }

    expose({
      focus,
      blur,
    } as GalleryContentExposed)

    return {
      fileName,
      fileDate,
      handleCompare,
      handleDeleteFile,
      handleTouch,
      zoom,
      downloadCurrentFile,
      printCurrentFile,
      registerGalleryItem,
      isCurrentGalleryItemLoaded,
      isArrowsShown,
      fileIndex,
      innerFilesSources,
      GALLERY_TOOLBAR_HEIGHT,
      isFocused,
      isLeftArrowShow,
      isRightArrowShow,
      handleClickOutside,
      prevFile,
      nextFile,
    }
  },
})
