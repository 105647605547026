import { SNACKBAR_MESSAGE } from '~/core/constants/snackbar'
import { useNuxtApp } from '~/bridge'
import { MainSnackbarState } from '~/store/main-snackbar'

export type UseSnackbarShowPayload = Partial<MainSnackbarState>

export type UseSnackbar = () => {
  show: (payload: UseSnackbarShowPayload) => void
  showReloadError: () => void
  showRetryError: () => void
  showReloadOrRetryError: () => void
  hide: () => void
  allowClose: () => void
}

export const useSnackbar: UseSnackbar = () => {
  const { $store } = useNuxtApp()

  return {
    show: payload => $store.dispatch('main-snackbar/handleOpen', payload),
    showReloadError: () => $store.dispatch('main-snackbar/handleOpen', SNACKBAR_MESSAGE.ERROR_WITH_RELOAD),
    showRetryError: () => $store.dispatch('main-snackbar/handleOpen', SNACKBAR_MESSAGE.TRY_AGAIN_ERROR),
    showReloadOrRetryError: () => $store.dispatch('main-snackbar/handleOpen', SNACKBAR_MESSAGE.TRY_AGAIN_OR_RELOAD_ERROR),
    hide: () => $store.commit('main-snackbar/close'),
    allowClose: () => $store.commit('main-snackbar/allowClose'),
  }
}
