import { defineStore } from 'pinia'
import { cloneDeep } from 'lodash-es'
import { FOREIGN_MT_URL, PD_URL } from '~/core/constants'
import { ReservedQueryKey } from '~/core/enums/routing'
import { computed, nextTick, ref, useNuxtApp, useRoute } from '~/bridge'
import { Country } from '~/features/Authorization/api/auth.api'
import { AuthApi } from '~/features/Authorization/api'

export default defineStore('countries', () => {
  const phoneInput = ref('')
  const selectedCountry = ref<Country | null>(null)
  const countriesItems = ref<Country[]>([])
  const isCountrySelectMenuActive = ref(false)
  const pdUrl = computed(
    () => selectedCountry.value?.pdDomain ? `https://${selectedCountry.value.pdDomain}` : PD_URL,
  )

  const $route = useRoute()
  const { $captureException, error, $store } = useNuxtApp()

  async function fetchCountries() {
    try {
      let data: Country[]

      if ($store.state.countries) {
        data = cloneDeep($store.state.countries)
      } else {
        ({ data } = await AuthApi.getCountries())
      }

      // NOTE: По умолчанию страны отсортированы по алфавиту.
      //       Для RU сортируем также по алфавиту, но Россия - на первом месте
      if (process.env.domain === 'RU') {
        countriesItems.value = data.sort((itemA, itemB) => {
          if (itemB.iso === 'RU') {
            return 1
          }

          if (itemA.iso === 'RU') {
            return -1
          }

          if (itemA.name.toLowerCase() < itemB.name.toLowerCase()) {
            return -1
          }

          if (itemA.name.toLowerCase() > itemB.name.toLowerCase()) {
            return 1
          }

          return 0
        })
      } else {
        countriesItems.value = data
      }

      const ruCountry = countriesItems.value.find(({ iso }) => iso === 'RU')

      if (ruCountry) {
        ruCountry.maskForInput = '&## ###-##-##'
      }

      if (!$route.query[ReservedQueryKey.PreselectedCountry]) {
        selectedCountry.value = countriesItems.value[0]
      } else {
        selectedCountry.value = countriesItems.value.find(item =>
          item.iso === $route.query[ReservedQueryKey.PreselectedCountry],
        ) ?? countriesItems.value[0]
      }
    } catch (e) {
      $captureException(e, {
        component: 'useCountriesStore',
        method: 'fetchCountries',
      })
      error(e)
    }
  }

  async function handleSelectCountry(item: Country) {
    isCountrySelectMenuActive.value = false

    if (
      (process.env.domain === 'RU' && item.iso !== 'RU') ||
      (process.env.domain === 'CIS' && item.iso === 'RU')
    ) {
      const nextQuery = $route.query.next
        ? `&next=${($route.query.next as string).replaceAll('&', '%26')}`
        : ''
      window.location.assign(FOREIGN_MT_URL + `/authorization/?${ReservedQueryKey.PreselectedCountry}=${item.iso}${nextQuery}`)

      return
    }

    if (!selectedCountry.value || selectedCountry.value.iso !== item.iso) {
      selectedCountry.value = item

      // TODO: можно заменить на composable из VueUse
      // NOTE: Смена значения query без перезагрузки страницы.
      //       Код страны - это всегда 2 заглавные латинские буквы
      const newURL = new URL(window.location.href)
      newURL.search = newURL.search.replace(
        /preselected_country=[A-Z]{2}/gm,
        `preselected_country=${item.iso}`,
      )
      window.history.pushState({ path: newURL.href }, '', newURL.href)

      // NOTE: Ждём пока изменится маска новой выбранной страны
      await nextTick()
      phoneInput.value = ''
    }
  }

  return {
    phoneInput,
    selectedCountry,
    countriesItems,
    isCountrySelectMenuActive,
    pdUrl,
    fetchCountries,
    handleSelectCountry,
  }
})
