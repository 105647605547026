export const DIALOG_MESSAGE = {
  CANCEL_CHANGES: {
    title: 'Закрыть и сбросить изменения?',
    closeText: 'Не надо',
    confirmText: 'Да, закрыть',
  },
  CLOSE_UPLOAD_DIALOG_DANGER: {
    title: 'Изменения не сохранятся',
    text: 'Вы выходите без сохранения внесённых изменений.',
    closeText: 'отменить',
    confirmText: 'продолжить',
  },
  DELETE_FOLDER: {
    title: 'Удалить папку?',
    text: 'Документы из неё всё равно останутся в медкарте.',
    closeText: 'Да, удалить',
    confirmText: 'Нет, оставить',
    persistent: true,
  },
  DELETE_UNCONFIRMED_DOCUMENT: {
    title: 'Удалить неподтверждённый документ?',
    text: 'Вернуть его можно будет только обратившись в клинику.',
    closeText: 'Да, удалить',
    confirmText: 'Нет, оставить',
    persistent: true,
  },
  DOCUMENT_DELETE_DANGER: {
    title: 'Удалить документ из медкарты?',
    text: 'Восстановить его не получится. Он удалится из всех папок.',
    closeText: 'Да, удалить',
    confirmText: 'Нет, оставить',
    persistent: true,
  },
  MEDCARD_CLOSE_DANGER: {
    title: 'Закрыть доступ?',
    text: 'После этого врач сможет открыть медкарту, только если вы снова отсканируете QR-код на его компьютере или поделитесь ссылкой.',
    confirmText: 'да, закрыть',
    closeText: 'отменить',
  },
  MULTIPLE_FILES_UPLOAD_ERROR: {
    title: 'Не все файлы загрузились',
    text: 'Добавить документ в медкарту без них?',
    closeText: 'закрыть',
    confirmText: 'добавить без них',
  },
  REMOVE_DOCUMENT_FROM_FOLDER: {
    title: 'Убрать документ из папки?',
    text: 'В медкарте он всё равно останется.',
    closeText: 'Да, убрать',
    confirmText: 'Нет, оставить',
    persistent: true,
  },
} as const
