
import { defineComponent, onBeforeUnmount, PropType, ref } from '~/bridge'
import EditorTemplate from './EditorTemplate.vue'
import ImageDrawer from './ImageDrawer.vue'
import ImageCropper from './ImageCropper.vue'
import ActionBtn from './ActionBtn.vue'

export default defineComponent({
  name: 'FullscreenImageEditor',
  components: {
    EditorTemplate,
    ActionBtn,
    ImageCropper,
    ImageDrawer,
  },
  props: {
    mode: { type: String as PropType<'only_crop' | 'full'>, default: 'full' },
  },
  emits: ['save', 'close'],
  setup(props, { emit, expose }) {
    const isActive = ref(false)
    const url = ref<string | null>(null)
    const originalFile = ref<File | string | null>(null)
    const editedFile = ref<File | string | null>(null)
    const currentAction = ref<'crop' | 'draw' | 'preview'>('preview')

    onBeforeUnmount(() => {
      revokeObjectURL()
    })

    function revokeObjectURL() {
      if (url.value) {
        URL.revokeObjectURL(url.value)
      }
    }

    function handleImageEdited(image: File) {
      URL.revokeObjectURL(url.value!)
      editedFile.value = image

      if (props.mode === 'only_crop') {
        handleSaveImage()

        return
      }

      url.value = URL.createObjectURL(image)
      currentAction.value = 'preview'
    }

    async function show(src: string | File | (() => Promise<File>)) {
      currentAction.value = props.mode === 'only_crop' ? 'crop' : 'preview'

      editedFile.value = null
      isActive.value = true
      const loadedSrc = src instanceof File || typeof src === 'string' ? src : await src()

      if (typeof loadedSrc === 'string') {
        const response = await fetch(loadedSrc)
        const blob = await response.blob()

        url.value = URL.createObjectURL(blob)

        originalFile.value = new File([blob], 'Изображение', {
          type: blob.type,
        })

        return
      }

      url.value = URL.createObjectURL(loadedSrc)
      originalFile.value = loadedSrc
    }

    function hide() {
      isActive.value = false
      revokeObjectURL()
      url.value = null
      originalFile.value = null
    }

    function handleClose() {
      emit('close', originalFile.value)
      hide()
    }

    function handleSaveImage() {
      emit('save', editedFile.value ?? originalFile.value)
      hide()
    }

    expose({
      show,
      hide,
    })

    return {
      isActive,
      url,
      currentAction,
      handleImageEdited,
      handleClose,
      handleSaveImage,
    }
  },
})
