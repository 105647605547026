import { createClientWithTokenAuth } from '~/core/functions/client/createClientWithTokenAuth'
import { INFO_URL } from '~/core/constants'
import { infoTokenManager } from '~/core/utils/tokenManagers/infoTokenManager'

const client = createClientWithTokenAuth({
  baseURL: INFO_URL,
  tokenManager: infoTokenManager,
})

export { client as infoClient }
