
import { AppBar } from '~/core/components/desktop'
import Copyright from '~/core/components/Copyright/Copyright.vue'
import { MainDialog, MainSnackbar } from '~/core/components/dialogs'
import EnvironmentBanner from '~/core/components/EnvironmentBanner/EnvironmentBanner.vue'
import { computed, defineComponent, useRoute } from '~/bridge'

export default defineComponent({
  name: 'DesktopLayout',
  components: {
    EnvironmentBanner,
    AppBar,
    Copyright,
    MainDialog,
    MainSnackbar,
  },
  setup() {
    const route = useRoute()
    // высота такая же, как у AppBar, чтобы форма авторизации была ровно посередине
    const footerHeight = 64

    const isAppBarVisible = computed(() => route.query.partners === undefined)

    return {
      isAppBarVisible,
      footerHeight,
    }
  },
})
