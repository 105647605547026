
import { isNumber } from 'lodash-es'
import isNumeric from 'validator/lib/isNumeric'
import { NO_AVATAR } from '~/core/constants'
import { computed, defineComponent } from '~/bridge'

/**
 * Компонент использующий нативный HTML <img>.
 * Нужен только для того чтобы скрывать содержимое в ЯндексМетрике.
 * */
const __default__ = defineComponent({
  name: 'BaseImg',
  inheritAttrs: false,
  props: {
    classes: { type: [String, Array, Object], default: '' },
    src: { type: String, required: true },
    ymHide: { type: Boolean, default: true },
    contain: { type: Boolean, default: false }, // Устанавливает свойство object-fit
    cover: { type: Boolean, default: false }, // Устанавливает свойство object-fit
    alt: { type: String, default: '' },
    width: { type: [Number, String], default: 'auto' },
    height: { type: [Number, String], default: 'auto' },
    maxWidth: { type: [Number, String], default: 'auto' },
    maxHeight: { type: [Number, String], default: 'auto' },
    minWidth: { type: [Number, String], default: 'auto' },
    minHeight: { type: [Number, String], default: 'auto' },
    aspectRatio: { type: [Number, String], default: 'auto' },
  },
  emits: ['click'],
  setup(props, { emit, listeners }) {
    const restListeners = computed(() => {
      const { click: _, ...rest } = listeners

      return rest
    })

    const formattedSizeStyles = computed(() => {
      const formatSize = (val: string | number) => isNumber(val) || isNumeric(val) ? `${val}px` : val
      const fields = ['width', 'height', 'maxWidth', 'maxHeight', 'minWidth', 'minHeight'] as const

      return fields.reduce(
        (acc, item) => Object.assign(acc, { [item]: formatSize(props[item]) }),
        {} as Record<typeof fields[number], string>,
      )
    })

    const imgStyles = computed(() => {
      let objectFit = 'fill'

      if (props.contain) {
        objectFit = 'contain'
      } else if (props.cover) {
        objectFit = 'cover'
      }

      return {
        ...formattedSizeStyles.value,
        objectFit,
      }
    })

    const imgClasses = computed(() => {
      const res = [
        { 'ym-hide-content': props.ymHide },
      ]

      if (Array.isArray(props.classes)) {
        return [...res, ...props.classes]
      }

      return [...res, props.classes]
    })

    function emitClick() {
      emit('click')
    }

    return {
      NO_AVATAR,
      restListeners,
      formattedSizeStyles,
      imgStyles,
      imgClasses,
      emitClick,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "bfc1efbe": (_vm.aspectRatio)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__