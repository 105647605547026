import { masterClient } from '~/core/utils/clients/masterClient'
import { RateReviewAttachmentDto } from '~/features/Rates/api/chat.api.types'
import { CantModifyReasonType } from '~/features/Rates/enums'

export default {
  saveMessage(discussionID: number, text: string) {
    return masterClient.patch(
      `/rates/discussions/${discussionID}/message/`,
      { text },
      { camelize: true },
    )
  },
  saveSupplementMessage(discussionID: number, text: string) {
    return masterClient.patch(
      `/rates/v2/supplement/discussions/${discussionID}/message/`,
      { text },
      { camelize: true },
    )
  },
  saveAttachment(discussionID: number, attachment: RateReviewAttachmentDto) {
    return masterClient.post<{ id: number }>(
      `/rates/discussions/${discussionID}/attachments/`,
      {
        fileString: attachment.file,
        originalName: attachment.originalName,
      },
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true,
      },
    )
  },
  saveSupplementAttachment(discussionID: number, attachment: RateReviewAttachmentDto) {
    return masterClient.post<{ id: number }>(
      `/rates/v2/supplement/discussions/${discussionID}/attachments/`,
      {
        fileString: attachment.file,
        originalName: attachment.originalName,
      },
      {
        // Здесь не нужен retry
        'axios-retry': { retries: 0 },
        camelize: true,
      },
    )
  },
  deleteAttachment(discussionID: number, attachmentID: number) {
    return masterClient.delete(`/rates/discussions/${discussionID}/attachments/${attachmentID}/`, { camelize: true })
  },
  deleteSupplementAttachment(discussionID: number, attachmentID: number) {
    return masterClient.delete(`/rates/v2/supplement/discussions/${discussionID}/attachments/${attachmentID}/`, { camelize: true })
  },
  sendMessage(discussionID: number) {
    return masterClient.patch(`/rates/discussions/${discussionID}/`, {}, { camelize: true })
  },
  sendSupplementMessage(discussionID: number) {
    return masterClient.patch(`/rates/v2/supplement/discussions/${discussionID}/`, {}, { camelize: true })
  },
  sendDiscussionAnswer(discussionID: number, answer: CantModifyReasonType) {
    return masterClient.post(`/rates/discussions/${discussionID}/answer/`, { answer }, { camelize: true })
  },
  sendSupplementDiscussionAnswer(discussionID: number, answer: CantModifyReasonType) {
    return masterClient.post(`/rates/v2/supplement/discussions/${discussionID}/answer/`, { answer }, { camelize: true })
  },
}
