
import VueCropper, { VueCropperMethods } from 'vue-cropperjs'
import { useCropperMethods } from '~/core/composables'
import OverlayLoading from '~/core/components/OverlayLoading/OverlayLoading.vue'
import { computed, defineComponent, ref, useNuxtApp } from '~/bridge'
import EditorTemplate from './EditorTemplate.vue'
import ActionBtn from './ActionBtn.vue'
import { IMAGE_EDITOR_TOOLBAR_HEIGHT } from './constants'

const MIN_CROP_BOX_SIZE_MULTIPLIER = 0.3
const VUE_CROPPER_PADDING_OFFSET = 10

export default defineComponent({
  name: 'ImageCropper',
  components: {
    EditorTemplate,
    ActionBtn,
    OverlayLoading,
    VueCropper,
  },
  props: {
    src: { type: String, required: true },
    saveBtnText: { type: String, default: 'сохранить' },
  },
  emits: ['cropped'],
  setup(props, { emit }) {
    const cropper = ref<VueCropperMethods | null>(null)
    const isReady = ref(false)
    const isCropping = ref(false)

    const { $vuetify, $device } = useNuxtApp()

    const {
      getCroppedFile,
      reset,
      rotateWithReset,
      zoom,
    } = useCropperMethods(cropper)

    const containerStyle = computed(() => {
      return {
        minHeight: '100%',
        width: '100%',
        maxWidth: '100%',
        position: 'relative',
        zIndex: '0',
        maxHeight: `${$vuetify.breakpoint.height - $vuetify.application.top - IMAGE_EDITOR_TOOLBAR_HEIGHT - VUE_CROPPER_PADDING_OFFSET}px`,
      }
    })

    const cropBoxSize = computed(() => {
      const minDimension = Math.min($vuetify.breakpoint.height, $vuetify.breakpoint.width)

      return minDimension * MIN_CROP_BOX_SIZE_MULTIPLIER
    })

    const cropperToolbarStyle = computed(() => {
      return {
        position: 'relative',
        zIndex: '1',
      }
    })

    const cropperActions = computed<Array<{ icon: string, text: string, handler: Function }>>(() => {
      const constantActions = [
        {
          icon: 'ui-icon-undo',
          text: 'Сбросить',
          handler: () => reset(),
        },
        {
          icon: 'ui-icon-clockwise',
          text: 'Поворот',
          handler: () => rotateWithReset(-90),
        },
      ]

      if ($device.isDesktop) {
        return [
          {
            icon: 'ui-icon-magnifying-glass-plus',
            text: 'Увеличить',
            handler: () => zoom(0.1),
          },
          {
            icon: 'ui-icon-magnifying-glass-minus',
            text: 'Уменьшить',
            handler: () => zoom(-0.1),
          },
          ...constantActions,
        ]
      }

      return constantActions
    })

    async function cropImage() {
      isCropping.value = true
      emit('cropped', await getCroppedFile())
      isCropping.value = false
    }

    function handleReady() {
      isReady.value = true
    }

    return {
      cropBoxSize,
      cropper,
      isReady,
      isCropping,
      containerStyle,
      cropperToolbarStyle,
      cropImage,
      cropperActions,
      handleReady,
    }
  },
})
