import { DentalQuestionId, TherapeuticQuestionId } from '~/core/components/Questionnaire/enums'
import { defineComponent } from '~/bridge'

type YesNoFormIds = TherapeuticQuestionId.CovidContacts |
  TherapeuticQuestionId.VriSymptoms |
  DentalQuestionId.InflammatoryDiseases

export const generateYesNoForm = (id: YesNoFormIds) => {
  const QuestionnaireFormYesNo = () => import('~/core/components/Questionnaire/components/common/QuestionnaireFormYesNo.vue')

  return defineComponent({
    functional: true,
    render: (h) => {
      return h(QuestionnaireFormYesNo, {
        props: { id },
      })
    },
  })
}
