import { MaybeRef } from '@vueuse/core'
import { DEFAULT_MIN_IMAGE_HEIGHT, DEFAULT_MIN_IMAGE_WIDTH, DIALOG_MESSAGE as CORE_DIALOG } from '~/core/constants'
import { FileType } from '~/core/enums'
import { isHeic, isImage, validateImageFile } from '~/core/functions'
import { computed, nextTick, unref, useNuxtApp } from '~/bridge'

type FileValidationRule = (v: File) => true | { text?: string, title?: string } |
  Promise<true | { text?: string, title?: string }>

type AcceptedTypesKeys = 'pdf' | 'jpgAndJpeg' | 'heicAndHeif' | 'png'

type UseFilesValidationPayload = {
  maxCount?: MaybeRef<number>
  maxSize?: MaybeRef<number>
  /**
   * Значение default отвечает за проверку по умолчанию на 300x300 пикселей.
   * */
  imageBounds?: {
    maxWidth: MaybeRef<number>
    maxHeight: MaybeRef<number>
  } | 'default'
  acceptedTypes?: AcceptedTypesKeys[],
  customRules?: FileValidationRule[]
}

type UseFilesValidationReturn = {
  validate: (files: File[], currentCount: number) => Promise<File[]>
}

type UseFilesValidation = (payload: UseFilesValidationPayload) => UseFilesValidationReturn

const ONE_MEGABYTE_MULTIPLIER = 1024 * 1024

const ACCEPTED_TYPES_MAP: Record<AcceptedTypesKeys, FileType[]> = {
  pdf: [FileType.Pdf],
  jpgAndJpeg: [FileType.Jpeg, FileType.Jpg],
  heicAndHeif: [FileType.Heic, FileType.Heif],
  png: [FileType.Png],
}

/**
 * composable позволяющий валидировать массив файлов.
 *
 * При ошибке открывается диалоговое окно.
 * */
export const useFilesValidation: UseFilesValidation = ({
  acceptedTypes,
  maxSize,
  maxCount,
  imageBounds,
  customRules,
}) => {
  const { $dialog } = useNuxtApp()

  const fileTypesErrorList = computed(() => {
    if (!acceptedTypes) {
      return ''
    }

    let errorText = ''

    if (acceptedTypes!.includes('jpgAndJpeg')) {
      errorText += 'JPEG|'
    }

    if (acceptedTypes!.includes('png')) {
      errorText += 'PNG|'
    }

    if (acceptedTypes!.includes('heicAndHeif')) {
      errorText += 'HEIC|'
    }

    if (acceptedTypes!.includes('pdf')) {
      errorText += 'PDF|'
    }

    errorText = errorText.slice(0, errorText.length - 1)

    const typesArray = errorText.split('|')

    if (typesArray.length > 1) {
      errorText = `${typesArray.slice(0, typesArray.length - 1).join(', ')} или ${typesArray.slice(-1)}.`
    } else {
      errorText.replace('|', '')
    }

    return errorText
  })

  const multipleError = computed(() => {
    let multipleErrorText = 'Проверьте размер и формат.\nДолжно быть так:'

    if (maxSize) {
      multipleErrorText += `\n• до ${maxSize} МБ`
    }

    if (lazyImageBounds.value) {
      multipleErrorText += `\n• от ${lazyImageBounds.value.width}x${lazyImageBounds.value.height} пикселей`
    }

    if (fileTypesErrorList.value) {
      multipleErrorText += `\n• ${fileTypesErrorList.value}`
    }

    return {
      title: 'Не все файлы загружены',
      text: multipleErrorText,
    }
  })

  const lazyImageBounds = computed(() => {
    if (!imageBounds) {
      return
    }

    return {
      width: imageBounds === 'default' ? DEFAULT_MIN_IMAGE_HEIGHT : unref(imageBounds.maxWidth),
      height: imageBounds === 'default' ? DEFAULT_MIN_IMAGE_WIDTH : unref(imageBounds.maxHeight),
    }
  })

  const allRules = computed<FileValidationRule[]>(() => {
    let innerRules: FileValidationRule[] = [
      (file) => {
        if (acceptedTypes) {
          return getFileTypeError(file, acceptedTypes, fileTypesErrorList.value) || true
        }

        return true
      },
      (file) => {
        if (maxSize) {
          return getFileSizeError(file, unref(maxSize)) || true
        }

        return true
      },
      async (file) => {
        if (imageBounds) {
          return await getImageBoundsError(file, lazyImageBounds.value!) || true
        }

        return true
      },
    ]

    if (customRules) {
      innerRules = [...innerRules, ...customRules]
    }

    return innerRules
  })

  async function validate(files: File[], currentCount = 0) {
    const validatedFiles = []
    let errors: Array<{ title?: string, text?: string }> = []

    for (const file of files) {
      let errorOccurred = false

      for (const rule of allRules.value) {
        try {
          const res = await rule(file)

          if (res !== true) {
            errors.push(res)
            errorOccurred = true
          }
        } catch (e) {
          errors.push(CORE_DIALOG.BASE_ERROR)
          errorOccurred = true
        }
      }

      if (!errorOccurred) {
        validatedFiles.push(file)
      }
    }

    if (errors.length > 1) {
      errors = [multipleError.value]
    }

    let reducedValidatedFiles = validatedFiles

    if (maxCount) {
      reducedValidatedFiles = validatedFiles.slice(0, unref(maxCount) - currentCount)

      if (validatedFiles.slice(reducedValidatedFiles.length, validatedFiles.length).length) {
        errors.push({
          text: `Можно добавить максимум ${unref(maxCount)} файлов.`,
        })
      }
    }

    for (const error of errors) {
      await $dialog.open(error)
      $dialog.close()
      await nextTick()
    }

    return reducedValidatedFiles
  }

  return {
    validate,
  }
}

const getFileTypeError = (file: File,
  acceptedTypesKeys: NonNullable<UseFilesValidationPayload['acceptedTypes']>,
  typesErrorText: string,
) => {
  const targetFileType = file.type as FileType

  const acceptedTypesValues = acceptedTypesKeys
    .reduce<FileType[]>((res, type) => [...res, ...ACCEPTED_TYPES_MAP[type]], [])

  if (acceptedTypesValues.includes(targetFileType)) {
    return null
  }

  return {
    title: 'Неподходящий формат',
    text: `Подойдёт ${typesErrorText}`,
  }
}

const getImageBoundsError = async (file: File, { width, height }: { width: number, height: number }) => {
  if (isImage(file) && !isHeic(file) && !await validateImageFile(file, { width, height })) {
    return {
      title: 'Слишком маленькое фото',
      text: `Загрузите фото от ${width}x${height} пикселей.`,
    }
  }

  return null
}

const getFileSizeError = (file: File, maxSize: number) => {
  if (file.size > (maxSize * ONE_MEGABYTE_MULTIPLIER)) {
    return {
      title: 'Слишком большой файл',
      text: `Загрузите файл до ${maxSize} МБ.`,
    }
  }

  return null
}
