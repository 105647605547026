
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'EmptyPlug',
  props: {
    src: { type: String, default: '' },
    text: { type: String, default: '' },
  },
})
