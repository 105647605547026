
import { debounce } from 'lodash-es'
import { Html5Qrcode } from 'html5-qrcode'
import { getScreenOrientation } from '~/core/functions'
import { useToggleable } from '~/core/composables'
import { defineComponent, nextTick, onBeforeUnmount, ref, toRefs, useNuxtApp, watch } from '~/bridge'

export default defineComponent({
  name: 'QrReaderPopup',
  props: {
    value: { type: Boolean, default: false },
  },
  emits: ['input', 'scanned', 'error:scan', 'error:camera'],
  setup(props, { emit, expose }) {
    const refProps = toRefs(props)

    const reader = ref<HTMLElement | null>(null)

    const html5QrCode = ref<null | Html5Qrcode>(null)
    const isLoading = ref(false)
    const labelStyle = ref({
      top: '0px',
    })
    const isResizeAvailable = ref(false)
    const scanRegionSize = ref(228) // Сделано как ref, чтобы тест подхватывал значение правильно

    const { $captureException } = useNuxtApp()
    const { isActive, hide, show } = useToggleable(refProps.value, emit, 'input')

    watch(isActive, async (val) => {
      if (val) {
        try {
          isLoading.value = true
          const cameras = await Html5Qrcode.getCameras()

          if (!cameras.length) {
            emit('error:camera', 'unavailable')

            return
          }

          await nextTick()
          await initHtml5QrCode()
          isLoading.value = false
          resetLabelStyle()
          isResizeAvailable.value = true
        } catch (e) {
          destroyHtml5QrCode()
          isResizeAvailable.value = false
          hide()
          emit('error:camera', e)
        }

        return
      }

      destroyHtml5QrCode()
      isResizeAvailable.value = false
    })

    onBeforeUnmount(() => {
      destroyHtml5QrCode()
    })

    async function initHtml5QrCode() {
      try {
        html5QrCode.value = new Html5Qrcode('reader')
        await html5QrCode.value.start(
          {},
          {
            fps: 24,
            qrbox: {
              width: scanRegionSize.value,
              height: scanRegionSize.value,
            },
            videoConstraints: {
              facingMode: {
                exact: 'environment',
              },
              height: {
                max: isPortraitOrientation() ? innerWidth : innerHeight,
                ideal: isPortraitOrientation() ? innerWidth : innerHeight,
                min: 300,
              },
              width: {
                min: 300,
                max: isPortraitOrientation() ? innerHeight : innerWidth,
                ideal: isPortraitOrientation() ? innerHeight : innerWidth,
              },
            },
          },
          (_, result) => {
            emit('scanned', {
              ...result,
              pause: pauseScanner,
              resume: () => html5QrCode.value!.resume(),
              loading: (val: boolean) => {
                isLoading.value = val
              },
            })
          },
          (_, err) => {
            emit('error:scan', {
              ...err,
              pause: pauseScanner,
              resume: () => html5QrCode.value!.resume(),
              loading: (val: boolean) => {
                isLoading.value = val
              },
            })
          })
      } catch (e) {
        $captureException(e, {
          method: 'initHtml5QrCode',
          component: 'QrReaderPopup',
        })

        throw e
      }
    }

    function resetLabelStyle() {
      labelStyle.value.top = `${(window.innerHeight / 2) + (scanRegionSize.value / 2) + 57}px`
    }

    function pauseScanner(shouldPauseVideo?: boolean | undefined) {
      html5QrCode.value!.pause(shouldPauseVideo)
      hideDefaultPausedLabel()
    }

    // Хак, прячущий желтую строку с информацией о паузе (вшутую в библиотеку)
    function hideDefaultPausedLabel() {
      const target = reader.value!.children[reader.value!.childElementCount - 1] as HTMLElement

      if (target?.textContent !== 'Scanner paused') {
        return
      }

      target.style.display = 'none'
    }

    function handleResize() {
      if (!isActive.value || !isResizeAvailable.value || isLoading.value) {
        return
      }

      debouncedRefresh()
    }

    const debouncedRefresh = debounce(async () => {
      await nextTick()
      await destroyHtml5QrCode()
      initHtml5QrCode()
      resetLabelStyle()
    }, 200)

    async function destroyHtml5QrCode() {
      try {
        await html5QrCode.value?.stop()
        html5QrCode.value?.clear()
      } catch (e) {
        $captureException(e, {
          component: 'QrReaderPopup',
          method: 'destroyHtml5QrCode',
        })
      } finally {
        html5QrCode.value = null
      }
    }

    function isPortraitOrientation() {
      return getScreenOrientation() === 'portrait'
    }

    expose({
      show,
      hide,
    })

    return {
      reader,
      html5QrCode,
      isLoading,
      labelStyle,
      isResizeAvailable, // для теста
      isActive,
      hide,
      initHtml5QrCode, // для теста
      handleResize,
      isPortraitOrientation,
    }
  },
})
