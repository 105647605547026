import { onBeforeMount, ref } from '~/bridge'

export function usePending(handler: () => Promise<void>) {
  const pending = ref(false)
  onBeforeMount(async () => {
    await refresh()
  })

  const refresh = async (togglePending = true) => {
    try {
      pending.value = togglePending
      await handler()
    } finally {
      pending.value = false
    }
  }

  return {
    pending,
    refresh,
  }
}
