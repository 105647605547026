
import { formatWeekday } from '~/core/functions'
import { formatDate } from '~/core/utils/formatDate'
import { firstUpper } from '~/core/utils/firstUpper'
import { defineComponent, ref } from '~/bridge'

export default defineComponent({
  name: 'DatePickerWrapper',
  setup() {
    const innerProps = ref({
      locale: 'ru-Ru',
      'weekday-format': formatWeekday,
      'first-day-of-week': 1,
    })

    function formattedDate(date: string) {
      const [year, month] = date.split('-')

      if (month) {
        return firstUpper(formatDate(`${year}-${month}-01`, 'LLLL yyyy'))
      }

      return formatDate(`${year}-01-01`, 'yyyy')
    }

    return {
      innerProps,
      formattedDate,
    }
  },
})
