
import { defineComponent } from '~/bridge'

export default defineComponent({
  name: 'Divider',
  props: {
    color: { type: String, default: '' },
    text: { type: String, default: '' },
    arrow: { type: Boolean },
  },
})
