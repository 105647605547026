var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-avatar',_vm._g({staticClass:"avatar-wrapper-background",class:{ 'border-base': _vm.bordered },attrs:{"width":_vm.width,"min-width":_vm.width,"max-width":_vm.width,"height":_vm.height,"min-height":_vm.height,"max-height":_vm.height}},_vm.$listeners),[_c('v-img',_vm._b({attrs:{"src":_vm.src}},'v-img',{
      lazySrc: _vm.NO_AVATAR,
      maxWidth: '100%',
      maxHeight: '100%',
      contain: true,
      ..._vm.imgProps,
    },false),[_vm._t("default")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }