
import { defineComponent } from '~/bridge'
import { IMAGE_EDITOR_TOOLBAR_HEIGHT } from './constants'

export default defineComponent({
  name: 'ActionsToolbar',
  setup() {
    return {
      IMAGE_EDITOR_TOOLBAR_HEIGHT,
    }
  },
})
