
import BaseTextField from '~/core/components/Base/BaseTextField.vue'
import { computed, defineComponent, nextTick, onBeforeMount, onMounted, PropType, ref, watch } from '~/bridge'
import { VTextField } from '~/types'
import { patronymicOptionalRules } from '~/features/Profile/functions'

export default defineComponent({
  name: 'PatronymicTextField',
  components: {
    BaseTextField,
  },
  inheritAttrs: false,
  props: {
    value: { type: [String, Number] as PropType<string | number | null>, default: null },
    isEmptyPatronymic: { type: Boolean, default: false },
  },
  emits: ['input', 'focus', 'blur'],
  setup(props, { emit, attrs, listeners }) {
    const textField = ref<VTextField | null>(null)

    const wasInput = ref(false)
    const isErrorState = ref(false)
    const innerValue = ref<string | number | null>(props.value ?? '')
    const lastValue = ref<string | number>('')
    const wasFocused = ref(false)

    const rules = computed(() => innerValue.value === null ? [] : patronymicOptionalRules)
    const isDetailsHidden = computed(() =>
      innerValue.value === null || !wasFocused.value || !wasInput.value || !isErrorState.value,
    )
    const switchBtnText = computed(() => innerValue.value === null ? 'Есть отчество' : 'Нет отчества')
    const label = computed(() => innerValue.value === null ? 'Нет отчества' : 'Отчество')

    const restAttrs = computed(() => {
      const { value: _, ...rest } = attrs

      return rest
    })

    const restListeners = computed(() => {
      const { input, blur, focus, ...rest } = listeners

      return rest
    })

    watch(() => props.value, (val) => {
      if (innerValue.value !== null) {
        innerValue.value = val
      }
    })

    watch(innerValue, async (val, prev) => {
      if (val !== props.value) {
        emit('input', innerValue.value === null ? '' : innerValue.value)
      }

      if (val === null) {
        textField.value!.resetValidation()
      }

      if (val === '' && prev === null) {
        isErrorState.value = true
        wasInput.value = true
        wasFocused.value = true
        textField.value!.validate(true)
        await nextTick()
        textField.value!.focus()
      }
    })

    onBeforeMount(() => {
      if (props.isEmptyPatronymic && !props.value) {
        innerValue.value = null
      }
    })

    onMounted(() => {
      isErrorState.value = !textField.value!.validate(false)
    })

    function handleFocus(ev: FocusEvent) {
      wasFocused.value = true
      emit('focus', ev)
    }

    function handleBlur(ev: FocusEvent) {
      emit('blur', ev)
      setTimeout(() => {
        wasInput.value = true
      })
    }

    function handleClickSwitch() {
      if (innerValue.value === null) {
        innerValue.value = lastValue.value
      } else {
        lastValue.value = innerValue.value
        innerValue.value = null
      }
    }

    return {
      textField,
      wasInput,
      isErrorState,
      innerValue,
      rules,
      isDetailsHidden,
      switchBtnText,
      label,
      restAttrs,
      restListeners,
      handleFocus,
      handleBlur,
      handleClickSwitch,
    }
  },
})
