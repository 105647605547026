var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    height: _vm.hint ? 'auto' : `${_vm.imageSize}px`,
    width: `${_vm.imageSize}px`,
  }),attrs:{"data-test":"media-preview-container"}},[_c('v-badge',{staticClass:"background-transparent",attrs:{"offset-x":16,"value":_vm.deletable,"overlap":"","avatar":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('BaseBtn',{attrs:{"data-test":"delete-media","elevation":"1","max-height":_vm.deleteSize,"max-width":_vm.deleteSize,"fab":"","x-small":""},on:{"click":_vm.emitClickDelete}},[_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"32","height":"32","color":"transparent"}},[_c('v-icon',{attrs:{"data-test":"delete-icon","size":_vm.fromMedcard ? 16 : 12,"color":_vm.fromMedcard ? 'ui-kit-icon-secondary' : 'ui-kit-text-secondary'}},[_vm._v("\n            ui-icon-trash\n          ")])],1)],1)]},proxy:true}])},[_vm._v(" "),_c('v-avatar',{class:{ 'border-base': _vm.bordered },attrs:{"rounded":"lg","width":_vm.imageSize,"height":_vm.imageSize},on:{"click":_vm.emitClick}},[_c('BaseImg',_vm._b({attrs:{"src":_vm.innerSrc,"width":_vm.imageSize,"height":_vm.imageSize,"ym-hide":_vm.ymHide},on:{"load":_vm.handleLoad}},'BaseImg',{
          contain: false,
          cover: true,
          ..._vm.imgProps,
        },false),[_c('v-overlay',{attrs:{"opacity":"0","value":_vm.isOverlayVisible,"absolute":""}},[_c('BaseBtn',{attrs:{"color":"ui-kit-bg-gray-0","small":"","rounded":"","depressed":"","fab":""}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"color":"primary","size":"21","width":"2","indeterminate":""}}):(_vm.icon || _vm.$slots.icon)?_vm._t("icon",function(){return [_c('v-icon',{attrs:{"color":_vm.iconColor},on:{"click":function($event){$event.stopPropagation();return _vm.emitClickIcon.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.icon)+"\n              ")])]}):_vm._e()],2)],1)],1)],1)],1),_vm._v(" "),(_vm.hint || _vm.$slots.hint)?_c('div',{staticClass:"text-center caption mt-1",class:`${_vm.hintColor}--text`,attrs:{"data-test":"media-preview-hint"}},[_vm._t("hint",function(){return [_vm._v("\n      "+_vm._s(_vm.hint)+"\n    ")]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }