import { DIALOG_MESSAGE as CAMERA_DIALOG_MESSAGE } from './camera'
import { DIALOG_MESSAGE as FILE_DIALOG_MESSAGE } from './file'

export const DIALOG_MESSAGE = {
  BASE_ERROR: {
    title: 'Произошла ошибка',
    text: 'Попробуйте ещё раз.',
    closeText: 'Понятно',
  },
  CODE_COOLDOWN: {
    text: 'Отправить новый код можно через сутки.',
  },
  DELETE_QUESTIONNAIRE_DANGER: {
    title: 'Удалить анкету?',
    text: 'Вы уверены, что хотите удалить анкету пациента? Будут удалены все ответы.',
    confirmText: 'удалить',
    closeText: 'отменить',
    isDanger: true,
  },
  DISCONNECTED_ERROR: {
    title: 'Пропал интернет',
    text: 'Подождите, пока появится сеть. Или попробуйте обновить страницу.',
    closeText: 'Закрыть',
    confirmText: 'Обновить',
    confirmHandler: () => window.location.reload(),
  },
  GLOBAL_ERROR: {
    title: 'Что-то пошло не так',
    text: 'Попробуйте немного подождать и обновить страницу.',
    closeText: 'Понятно',
  },
  LOGOUT_DANGER: {
    title: 'Выйти из профиля?',
    text: 'Вы выйдете из профиля на всех устройствах',
    closeText: 'отменить',
    confirmText: 'выйти',
    isDanger: true,
  },
  LOGOUT_ERROR: {
    title: 'Не получилось выйти из личного кабинета',
    text: 'Обновите страницу и попробуйте ещё раз.',
    closeText: 'Закрыть',
    confirmText: 'Обновить',
    confirmHandler: () => window.location.reload(),
  },
  LPU_LOGGED_ERROR: {
    text: 'Вы выйдите из личного кабинета, в котором авторизованы.',
    closeText: 'отменить',
    confirmText: 'продолжить',
  },
  MODAL_RELOAD_RETRY_ERROR: {
    text: 'Обновите страницу и попробуйте ещё раз.',
    closeText: 'Обновить',
    closeHandler: () => window.location.reload(),
    persistent: true,
  },
  RELOAD_RETRY_ERROR: {
    text: 'Обновите страницу и попробуйте ещё раз.',
    closeText: 'Закрыть',
    confirmText: 'Обновить',
    confirmHandler: () => window.location.reload(),
  },
  REDIRECT_TO_PD: {
    title: 'Вы переходите на ПроДокторов для записи на приём',
    image: '/graphics/pictures/redirect-to-pd.svg',
    closeText: 'Остаться',
    confirmText: 'Перейти',
  },
  REDIRECT_TO_YDOC: {
    title: 'Вы переходите на YDoc для записи на приём',
    image: '/graphics/pictures/redirect-to-pd.svg',
    closeText: 'Остаться',
    confirmText: 'Перейти',
  },
  ...CAMERA_DIALOG_MESSAGE,
  ...FILE_DIALOG_MESSAGE,
} as const

