
import { computed, defineComponent, useNuxtApp } from '~/bridge'

export default defineComponent({
  name: 'Copyright',
  setup() {
    const { $featureFlag } = useNuxtApp()

    const name = computed(
      () => $featureFlag('sff_new_name_medrocket') ? 'ООО «МЕДРОКЕТ»' : 'ООО «МедРейтинг»',
    )

    return {
      name,
    }
  },
})
