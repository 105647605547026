import Cookies from 'js-cookie'
import { infoClient } from '~/core/utils/clients/infoClient'
import { QuestionGroup } from '~/core/components/Questionnaire/enums'
import { CookieKey } from '~/core/enums'
import { getFamilyProfileUuidHeader } from '~/core/functions/client/getFamilyProfileUuidHeader'
import { QuestionnaireAnswerDto } from '~/core/api/questionnaire.api.types'
import { masterClient } from '~/core/utils/clients/masterClient'
import { medcardClient } from '~/core/utils/clients/medcardClient'

const RESOURCE = '/medforms'

export default {
  sendAnswer(payload: QuestionnaireAnswerDto & { patientAnonymousToken?: string }) {
    return masterClient.put(`${RESOURCE}/answers/`, payload, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
        ...getFamilyProfileUuidHeader(),
      },
      camelize: true,
    })
  },
  getDecryptKey() {
    return masterClient.get<{ key: string, iv: string }>(`${RESOURCE}/key/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
      },
      camelize: true,
    })
  },
  getEncryptedQuestionnaire() {
    return medcardClient.get<Array<{ questionId: string, answer: string, iv: string }>>(
      `${RESOURCE}/answers/`,
      { camelize: true },
    )
  },
  getQuestionGroups() {
    return infoClient.get<{ questionGroups: QuestionGroup[] }>(
      `${RESOURCE}/question_groups/`,
      { camelize: true },
    )
  },
  deleteQuestionnaire() {
    return masterClient.delete(`${RESOURCE}/answers/`, {
      headers: {
        'Medcard-Token': Cookies.get(CookieKey.MedcardToken) || '',
        ...getFamilyProfileUuidHeader(),
      },
      camelize: true,
    })
  },
}
